import React from "react"

const Header =()=>{
    return(
<>
  <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
  {/* /Added by HTTrack */}
  <meta charSet="utf-8" />
  <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
  <link rel="canonical" href="/" />
  <link rel="alternate" href="/" hrefLang="en" />
  <link rel="alternate" href="ar.html" hrefLang="ar" />
  <title>
    Dubai Mall, Shopping, Dining, What to do in Dubai, Shopping Festival,
    Entertainment, Restaurants, Cafes, Hotels, Holidays, Events and Offers
  </title>
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
  />
  <meta
    name="description"
    content="Dubai Mall, also known as the home of the Dubai shopping festival, is one of the world&#39;s largest shopping malls in UAE. Lots of things to do in Dubai Mall like shopping festivals, hotels, restaurants, entertainment, holidays, events and attractive offers."
  />
  <meta
    name="keywords"
    content="Dubai Mall, Dubai Shopping, Largest mall, What to do in Dubai, Biggest shopping malls, Dubai Shopping Festival, Things to do in Dubai, Dubai Shopping Malls, Shopping Malls In Dubai"
  />
  <meta name="author" content="Emaar Malls Group" />
  <meta
    name="signature"
    content="V2Vic2l0ZSBidWlsZCBieSBDaHJpcyBTY2hhcmRpam4gKGMpIDIwMTUtMjAxNiANCi10ZWNobm9sb2dpZXM6IGh0bWwsICBqcXVlcnksIGNzcywgc2FzcywgZ3VscCwgY29tcGFzcywgcGhwLCB0d2lnLCBoYW5kbGViYXIgJiBqcXVlcnk="
  />
  <meta
    property="og:title"
    content="Dubai Mall | Fashion | Beauty | Lifestyle | Entertainment"
  />
  <meta property="og:type" content="website" />
  <meta
    property="og:description"
    content="With the latest in fashion, beauty, lifestyle and entertainment, discover your latest obsession at Dubai Mall."
  />
  <meta
    property="og:image"
    content="https://thedubaimall.com/assets/images/facebookPic.jpg"
  />
  <meta property="og:image:width" content={640} />
  <meta property="og:image:height" content={640} />
  <meta property="og:url" content="https://thedubaimall.com/en" />
  <meta property="fb:app_id" content={1764184290356640} />
  <meta property="og:author" content="Dubai Mall" />
  <meta property="og:site_name" content="Dubai Mall" />
  <meta name="twitter:site" content="@TheDubaiMall" />
  <meta name="twitter:creator" content="@TheDubaiMall" />
  <meta name="twitter:card" content="summary" />
  <meta
    name="twitter:image"
    content="https://thedubaimall.com/assets/images/facebookPic.jpg"
  />
  <meta property="al:ios:app_store_id" content={430795858} />
  <meta property="al:ios:app_name" content="Dubai Mall" />
  <meta property="al:ios:url" content="thedubaimall://" />
  <meta
    name="apple-itunes-app"
    content="app-id=430795858, affiliate-data=myAffiliateData, app-argument=https://apps.apple.com/ae/app/emaar-e-service/id430795858?mt=8"
  />
  <meta property="al:android:package" content="com.thedubaimall" />
  <meta property="al:android:app_name" content="Dubai Mall" />
  <meta property="al:android:url" content="thedubaimall://" />
  <meta property="al:web:url" content="https://thedubaimall.com/en" />
  <header className="site-header">
    <div className="site-header__leader">
      <div className="container">
        <div className="row">
          <div className="col-xs-24">
           
            <div className="site-header__hamburger visible-xs">
              <a
                href="#"
                id="site-menu-button"
                data-toggle="toggle-menu"
                data-target=".site-header__hamburger"
              >
                <span className="icon-menu-rotate icon-menu" />
                <span className="icon-menu-rotate icon-menu-close" />
              </a>
            </div>
            <div className="site-header__logo">
              <a href="/" title="Westgate Shopping  Mall">
                <img
                  src="./assets/images/logo.png"
                  height={53}
                  alt="Westgate Shopping Mall"
                />
              </a>
            </div>
            <div className="site-header__search">
              <a
                href="#"
                className="site-header-giftcard"
                target="_blank"
              >
                <span className="txt-gift hidden-xs">Westgate Gift Voucher</span>
                <span className="icon-gift visible-xs" />
              </a>
              <a
                href="#"
                className="site-search-btn"
                id="site-search-button"
                data-toggle="toggle-search"
                data-target="#site-search"
              >
                <span data-view="close" className="hidden">
                  <span className="site-search-btn__label hidden-xs hidden-sm">
                    Close
                  </span>
                  <span className="site-search-btn__icon icon-cross" />
                </span>
                <span data-view="open" style={{ cursor: "pointer" }} onclick="">
                  <span className="site-search-btn__label hidden-xs">
                    Search
                  </span>
                  <span className="site-search-btn__icon icon-search" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav className="site-header__menu hidden-xs">
      <ul>
      <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/movies">Movies</a>
        </li>
        <li>
          <a href="/stores/category/shop">Shop</a>
        </li>
        <li>
          <a href="/stores/category/dine">Dine</a>
        </li>
        <li>
          <a href="/stores/category/fashion">Fashion</a>
        </li>
        <li>
          <a href="/entertainment">Entertainment</a>
        </li>
        {/*
        <li>
          <a
            href="en/plan-your-visit/opening-hours.html"
            data-megamenu="#mm-plan-your-visit"
          >
            Plan Your Visit <span className="icon icon-caret icon-caret-xs" />
          </a>
        </li> */}
        <li>
          <a href="/services">Services</a>
        </li>
        <li>
          <a href="/faqs">Faqs</a>
        </li>
        <li>
          <a href="/services">Careers</a>
        </li>
      </ul>
    </nav>
    <nav className="site-sections__menu hidden-xs">
        <ul>
            <li>
                <a href="/about">About Westgate</a>
            </li>
            <li>
                <a href="/branding">Branding & Activations</a>
            </li>
            <li>
                <a href="/campaigns">Campaigns & Offers</a>
            </li>
            
            
            <li>
                <a href="/events">Events</a>
            </li>
        </ul>
    </nav>
    <div className="site-mega">
      <div className="site-mega__block" id="mm-empty" />
      <div className="site-mega__block" id="mm-shop">
        <div className="container">
          <div className="row margin-xs">
            <div className="row-height">
              <div className="col-md-5 col-sm-8 col-height right-border">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  Featured Categories
                </h5>
                <ul>
                  {/*<li><a href="/en/store-directory?categoryId=9">Electronics</a></li>*/}
                  <li>
                    <a href="en/store/electronics-1.html">Electronics</a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=13">Fashion - Children</a></li>*/}
                  <li>
                    <a href="en/store/fashion-children.html">
                      Fashion - Children
                    </a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=4">Beauty & Fragrances</a></li>*/}
                  <li>
                    <a href="en/store/beauty-fragrances.html">
                      Beauty &amp; Fragrances
                    </a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=12">Fashion - Men</a></li>*/}
                  <li>
                    <a href="en/store/fashion-men.html">Fashion - Men</a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=11">Fashion - Women</a></li>*/}
                  <li>
                    <a href="en/store/fashion-women-1.html">Fashion - Women</a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=19">Home</a></li>*/}
                  <li>
                    <a href="en/store/home-1.html">Home</a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=185">New Stores</a></li>*/}
                  <li>
                    <a href="en/store/new-stores-1.html">New Stores</a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=23">Toys</a></li>*/}
                  <li>
                    <a href="en/store/toys.html">Toys</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-5 col-sm-8 col-height right-border">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  Brand
                </h5>
                <ul>
                  <li>
                    <a href="en/shop/bvlgari-1.html">Bvlgari</a>
                  </li>
                  <li>
                    <a href="en/shop/cartier-fashion-avenue.html">Cartier</a>
                  </li>
                  <li>
                    <a href="en/shop/chloe.html">Chloé</a>
                  </li>
                  <li>
                    <a href="en/shop/chopard-boutique.html">Chopard</a>
                  </li>
                  <li>
                    <a href="en/shop/christian-louboutin-1.html">
                      Christian Louboutin
                    </a>
                  </li>
                  <li>
                    <a href="en/shop/henry-jacques.html">Henry Jacques</a>
                  </li>
                  <li>
                    <a href="en/shop/level-shoes.html">Level Shoes</a>
                  </li>
                  <li>
                    <a href="en/shop/louis-vuitton.html">Louis Vuitton</a>
                  </li>
                  <li>
                    <a href="en/shop/tiffany-0.html">
                      Tiffany &amp; Co./ Blue Box Cafe
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-5 col-sm-8 col-height right-border">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  Occasions
                </h5>
                <ul>
                  {/*<li><a href="/en/store-directory?tagId=5">Anniversary</a></li>*/}
                  <li>
                    <a href="en/store-tag/anniversary.html">Anniversary</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=184">Back to School</a></li>*/}
                  <li>
                    <a href="en/store-tag/back-to-school.html">
                      Back to School
                    </a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=15">Birthday</a></li>*/}
                  <li>
                    <a href="en/store-tag/birthday.html">Birthday</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=752">Eid</a></li>*/}
                  <li>
                    <a href="en/store-tag/eid.html">Eid</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=754">Festive Season</a></li>*/}
                  <li>
                    <a href="en/store-tag/festive-season.html">
                      Festive Season
                    </a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=757">Gifts for Her</a></li>*/}
                  <li>
                    <a href="en/store-tag/gifts-for-her.html">Gifts for Her</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=755">Gifts for Him</a></li>*/}
                  <li>
                    <a href="en/store-tag/gifts-for-him.html">Gifts for Him</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=759">Housewarming</a></li>*/}
                  <li>
                    <a href="en/store-tag/housewarming.html">Housewarming</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=412">Souvenirs</a></li>*/}
                  <li>
                    <a href="en/store-tag/souvenirs.html">Souvenirs</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=450">Travel</a></li>*/}
                  <li>
                    <a href="en/store-tag/travel.html">Travel</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=163">Wedding</a></li>*/}
                  <li>
                    <a href="en/store-tag/wedding.html">Wedding</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-9 col-height hidden-sm hidden-xs">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  In the spotlight
                </h5>
                <a
                  className="mega-menu__shop-spotlight"
                  href="en/campaign/chinatown.html"
                  title="Dubai Mall Chinatown"
                >
                  <figure>
                    <div
                      className="site-mega__img bg-image lazyload visible-sm-block"
                      data-bgset="../dynamicassets.azureedge.net/uploads/zabeel_pages/desktop/64115e1ce5f7d.jpg"
                    />
                    <div
                      className="site-mega__img bg-image lazyload visible-md-block"
                      data-bgset="../dynamicassets.azureedge.net/uploads/zabeel_pages/desktop/64115e1ce5f7d.jpg"
                    />
                    <div
                      className="site-mega__img bg-image lazyload visible-lg-block"
                      data-bgset="../dynamicassets.azureedge.net/uploads/zabeel_pages/desktop/64115e1ce5f7d.jpg"
                    />
                  </figure>
                </a>
                <p>
                  Chinatown is the latest addition to Dubai Mall, located on
                  the&nbsp;first floor opposite the Dubai Ice Ri...
                </p>
                <a
                  className="btn btn-link"
                  href="en/campaign/chinatown.html"
                  style={{ fontWeight: "800 !important" }}
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="row margin-xs">
            <div className="col-xs-24">
              <a
                href="en/store-directory.html"
                className="btn btn-default btn-block"
              >
                View all shops
              </a>
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n\t.site-mega__img {\n\t\theight: 250px!important;\n\t\tmargin: 15px 0;\n\t}\n\t"
            }}
          />
        </div>
      </div>
      <div className="site-mega__block" id="mm-dine">
        <div className="container">
          <div className="row margin-xs">
            <div className="row-height">
              <div className="col-md-5 col-sm-8 col-height right-border">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  Top picks
                </h5>
                <ul>
                  <li>
                    <a href="en/shop/angelina-1.html">Angelina</a>
                  </li>
                  <li>
                    <a href="en/shop/eataly.html">Eataly</a>
                  </li>
                  <li>
                    <a href="en/shop/galeries-lafayette-le-gourmet.html">
                      Galeries Lafayette Le Gourmet
                    </a>
                  </li>
                  <li>
                    <a href="en/shop/magnolia-bakery.html">Magnolia Bakery</a>
                  </li>
                  <li>
                    <a href="en/shop/markette-1.html">Markette</a>
                  </li>
                  <li>
                    <a href="en/shop/nandos.html">Nando's</a>
                  </li>
                  <li>
                    <a href="en/shop/social-house-1.html">Social House</a>
                  </li>
                  <li>
                    <a href="en/shop/the-cheesecake-factory.html">
                      The Cheesecake Factory
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-5 col-sm-8 col-height">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  Cuisine
                </h5>
                <ul>
                  {/*<li><a href="/en/dine-directory?tagId=3">American Cuisine</a></li>*/}
                  <li>
                    <a href="en/dine-tag/american-cuisine.html">
                      American Cuisine
                    </a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=188">Bakery</a></li>*/}
                  <li>
                    <a href="en/dine-tag/bakery.html">Bakery</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=213">British Food</a></li>*/}
                  <li>
                    <a href="en/dine-tag/british-food.html">British Food</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=581">Chinese</a></li>*/}
                  <li>
                    <a href="en/dine-tag/chinese.html">Chinese</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=611">Fast Food</a></li>*/}
                  <li>
                    <a href="en/dine-tag/fast-food.html">Fast Food</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=614">French</a></li>*/}
                  <li>
                    <a href="en/dine-tag/french-1.html">French</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=771">Indian Food</a></li>*/}
                  <li>
                    <a href="en/dine-tag/indian-food.html">Indian Food</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=312">Italian</a></li>*/}
                  <li>
                    <a href="en/dine-tag/italian-1.html">Italian</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=315">Japanese</a></li>*/}
                  <li>
                    <a href="en/dine-tag/japanese.html">Japanese</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=779">Middle Eastern Food</a></li>*/}
                  <li>
                    <a href="en/dine-tag/middle-eastern-food.html">
                      Middle Eastern Food
                    </a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=82">Kids</a></li>*/}
                  <li>
                    <a href="en/dine-tag/kids.html">Kids</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=638">Healthy</a></li>*/}
                  <li>
                    <a href="en/dine-tag/healthy.html">Healthy</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-5 col-sm-8 col-height right-border"></div>
              <div className="col-md-9 col-height hidden-sm hidden-xs">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  Featured restaurant
                </h5>
                <a
                  className="mega-menu__shop-spotlight"
                  href="en/shop/gia.html"
                  title="GIA"
                >
                  <figure>
                    <div
                      className="site-mega__img bg-image lazyload visible-xs-block"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic/62e256e1a6758.jpg"
                      data-alt="GIA"
                    />
                    <div
                      className="site-mega__img bg-image lazyload visible-sm-block"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic/62e256e1a6758.jpg"
                      data-alt="GIA"
                    />
                    <div
                      className="site-mega__img bg-image lazyload visible-md-block"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic/62e256e1a6758.jpg"
                      data-alt="GIA"
                    />
                    <div
                      className="site-mega__img bg-image lazyload visible-lg-block"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic/62e256e1a6758.jpg"
                      data-alt="GIA"
                    />
                  </figure>
                </a>
                <p>
                  GIA is the new Italian restaurant featuring an all-day dining
                  menu curated to the local taste. The t...
                </p>
                <a
                  className="btn btn-link"
                  href="en/shop/gia.html"
                  style={{ fontWeight: "800 !important" }}
                >
                  Find out more
                </a>
              </div>
            </div>
          </div>
          <div className="row margin-xs">
            <div className="col-xs-24">
              <a
                href="en/dine-directory.html"
                className="btn btn-default btn-block"
              >
                View all restaurants
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="site-mega__block" id="mm-stay">
        <div className="container megamenuStay">
          <div className="row margin-xs">
            <div className="row-height">
              <div className="col-md-5 col-sm-8 col-height">
                <div className="containerMegamenuStay col-md-5 col-sm-8 col-height" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-mega__block" id="mm-entertain">
        <div className="container megamenuEntertainment">
          <div className="row margin-xs">
            <div className="row-height">
              <div className="containerMegamenuEntertainment col-md-5 col-sm-8 col-height" />
            </div>
          </div>
        </div>
      </div>
      <div className="site-mega__block" id="mm-plan-your-visit">
        <div className="container">
          <div className="row margin-xs">
            <div className="col-md-10 col-sm-8">
              <ul>
                <li>
                  <a href="en/plan-your-visit/opening-hours.html">
                    Opening hours{" "}
                  </a>
                </li>
                <li>
                  <a href="en/plan-your-visit/getting-here.html">
                    Getting here
                  </a>
                </li>
                <li>
                  <a href="en/u-by-emaar.html">U By Emaar</a>
                </li>
                <li>
                  <a href="en/plan-your-visit/parking.html">Parking</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a className="site-mega__close" href="#">
      <span className="icon-cross" />
    </a>
    <div className="site-mega__overlay" />
  </header>
</>

    )
}

export default Header