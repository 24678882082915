import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"

const Home = () => {
  return (
    <>
      <Header />
      <div className="constant-dots">
        <div className="site">
          <div className="site-content home-page">
            <div className="site-sections">
             
              <div className="block-container block-container--hero" id="homepage-slider">
  <div className="block-abs" style={{ width: "100%" }}>
    <div className="block col-xs-24" data-block="" style={{ width: "100%" }}>
      <div
        className="homeCarousel slick-hero slick-slider--inlinedots slick-slider--fullheight slick-initialized slick-slider"
        role="toolbar"
        style={{ width: "100%" }}
      >
        <div aria-live="polite" className="slick-list draggable" style={{ width: "100%" }}>
          <div
            className="slick-track"
            style={{ opacity: 1, width: "100%", left: "0px" }}
            role="listbox"
          >
            <div
              className="block-slide slick-slide slick-cloned"
              data-slick-index={-1}
              id=""
              aria-hidden="true"
              style={{ width: "100%" }}
              tabIndex={-1}
            >
              <div className="block-bottom sg-mb-90px sg-mb-50px-sm">
                <div className="row">
                  <div className="col-sm-20 col-sm-offset-2 col-xs-22 col-xs-offset-1 md-pull-in-line">
                    <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                      <strong>Offer </strong>
                    </h5>
                    <h5 className="text-uppercase sg-mb-15px text-white visible-lg spaced">
                      <strong>Offer</strong>
                    </h5>
                    <div className="row">
                      <div className="col-sm-18">
                        <h1 className="sg-mb-30px sg-mb-10px-xs text-white">
                          SHOP.  UPLOAD RECEIPT.  WIN
                        </h1>
                      </div>
                    </div>
                    <div className="solid-line sg-mb-30px hidden-xs">
                      <button
                        type="button"
                        data-slick-prev=""
                        data-slick-target=".slick-hero"
                        data-role="none"
                        className="slick-btn-custom slick-prev slick-arrow left"
                        aria-label="Prev"
                        role="button"
                        style={{ display: "block" }}
                        tabIndex={-1}
                      >
                        Prev
                      </button>
                      <button
                        type="button"
                        data-slick-next=""
                        data-slick-target=".slick-hero"
                        data-role="none"
                        className="slick-btn-custom slick-next slick-arrow right"
                        aria-label="Next"
                        role="button"
                        style={{ display: "block" }}
                        tabIndex={-1}
                      >
                        Next
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-sm-11 col-xs-22">
                        <div
                          className="h-body-intro text-white sg-mb-15px"
                          style={{ color: "#FFF !important" }}
                        >
                          Shop or dine at any store in Westgate Shoppping Mall and stance a stance to win amazing gifts
                        </div>
                        <a
                          href="https://thedubaimall.com/en/rewards"
                          className="btn btn-default-transparent btn-block-sm"
                          tabIndex={-1}
                        >
                          LEARN MORE
                        </a>
                      </div>
                      <div className="col-sm-13 hidden-xs">
                        <div className="pull-right text-white hero-meta">
                          <div className="sg-ml-10px pull-left hero-meta__time">
                            <span className="block-details-icon">
                              <span className="icon-clock" />
                            </span>
                            <div className="block-details">
                              <h5 className="text-uppercase HN--roman spaced">
                                Open today
                              </h5>
                              <h4 className="HN--medium reset-margin whatTheHell">
                                <span className="text-uppercase">
                                  9 am - 10 pm
                                </span>
                              </h4>
                            </div>
                          </div>
                          <div className="sg-ml-10px pull-left hero-meta__plan">
                            <span className="block-details-icon">
                              <span className="icon-location" />
                            </span>
                            <div className="block-details">
                              <h5 className="text-uppercase HN--roman spaced">
                                Location
                              </h5>
                              <h4 className="HN--medium reset-margin">
                                <a
                                  href="/en/map"
                                  target="_blank"
                                  className="text-white"
                                  tabIndex={-1}
                                >
                                  All Stores
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block-gradient" />
              <div className="block-visuals">
                <figure className="block-cover-img">
                  <div
                    data-bgset="./assets/images/westgate_shop_and_win.png"
                    className="bg-image lazyload visible-xs-block"
                    data-alt="SHOP.  UPLOAD RECEIPT.  WIN"
                    style={{
                      backgroundImage:
                        'url("./assets/images/westgate_shop_and_win.png)',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  />
                  <div
                    data-bgset="./assets/images/westgate_shop_and_win.png"
                    className="bg-image lazyload visible-sm-block"
                    data-alt="SHOP.  UPLOAD RECEIPT.  WIN"
                    style={{
                      backgroundImage:
                        'url("./assets/images/westgate_shop_and_win.png")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  />
                  <div
                    data-bgset="./assets/images/westgate_shop_and_win.png"
                    className="bg-image visible-md-block lazyloaded"
                    data-alt="SHOP.  UPLOAD RECEIPT.  WIN"
                    style={{
                      backgroundImage:
                        'url("./assets/images/westgate_shop_and_win.png")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  >
                    <picture style={{ display: "none" }}>
                      <source
                        data-srcset="./assets/images/westgate_shop_and_win.png"
                        sizes="100%"
                        srcSet="./assets/images/westgate_shop_and_win.png"
                      />
                      <img
                        alt="SHOP.  UPLOAD RECEIPT.  WIN"
                        data-parent-fit="cover"
                        className="lazyautosizes lazyloaded"
                        data-sizes="auto"
                        sizes="100%"
                      />
                    </picture>
                  </div>
                  <div
                    data-bgset="./assets/images/westgate_shop_and_win.png"
                    className="bg-image visible-lg-block lazyloaded"
                    data-alt="SHOP.  UPLOAD RECEIPT.  WIN"
                    style={{
                      backgroundImage:
                        'url("./assets/images/westgate_shop_and_win.png")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  >
                    <picture style={{ display: "none" }}>
                      <source
                        data-srcset="./assets/images/westgate_shop_and_win.png"
                        sizes="100%"
                        srcSet="./assets/images/westgate_shop_and_win.png"
                      />
                      <img
                        alt="SHOP.  UPLOAD RECEIPT.  WIN"
                        data-parent-fit="cover"
                        className="lazyautosizes lazyloaded"
                        data-sizes="auto"
                        sizes="100%"
                      />
                    </picture>
                  </div>
                </figure>
              </div>
            </div>
            <div
              className="block-slide slick-slide"
              data-slick-index={0}
              aria-hidden="true"
              style={{ width: "100%" }}
              tabIndex={-1}
              role="option"
              aria-describedby="slick-slide00"
            >
              <div className="block-bottom sg-mb-90px sg-mb-50px-sm">
                <div className="row">
                  <div className="col-sm-20 col-sm-offset-2 col-xs-22 col-xs-offset-1 md-pull-in-line">
                    <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                      <strong>Event </strong>
                    </h5>
                    <h5 className="text-uppercase sg-mb-15px text-white visible-lg spaced">
                      <strong>Event</strong>
                    </h5>
                    <div className="row">
                      <div className="col-sm-18">
                        <h1 className="sg-mb-30px sg-mb-10px-xs text-white">
                          YOU SHOP. WE DELIVER.
                        </h1>
                      </div>
                    </div>
                    <div className="solid-line sg-mb-30px hidden-xs">
                      <button
                        type="button"
                        data-slick-prev=""
                        data-slick-target=".slick-hero"
                        data-role="none"
                        className="slick-btn-custom slick-prev slick-arrow left"
                        aria-label="Prev"
                        role="button"
                        style={{ display: "block" }}
                        tabIndex={-1}
                      >
                        Prev
                      </button>
                      <button
                        type="button"
                        data-slick-next=""
                        data-slick-target=".slick-hero"
                        data-role="none"
                        className="slick-btn-custom slick-next slick-arrow right"
                        aria-label="Next"
                        role="button"
                        style={{ display: "block" }}
                        tabIndex={-1}
                      >
                        Next
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-sm-11 col-xs-22">
                        <div
                          className="h-body-intro text-white sg-mb-15px"
                          style={{ color: "#FFF !important" }}
                        >
                          Enjoy a seamless shopping experience with our Delivery
                          Service.
                        </div>
                        <a
                          href="https://thedubaimall.com/en/services"
                          className="btn btn-default-transparent btn-block-sm"
                          tabIndex={-1}
                        >
                          LEARN MORE
                        </a>
                      </div>
                      <div className="col-sm-13 hidden-xs">
                        <div className="pull-right text-white hero-meta">
                          <div className="sg-ml-10px pull-left hero-meta__time">
                            <span className="block-details-icon">
                              <span className="icon-clock" />
                            </span>
                            <div className="block-details">
                              <h5 className="text-uppercase HN--roman spaced">
                                Open today
                              </h5>
                              <h4 className="HN--medium reset-margin whatTheHell">
                                <span className="text-uppercase">
                                  9 am - 10 pm
                                </span>
                              </h4>
                            </div>
                          </div>
                          <div className="sg-ml-10px pull-left hero-meta__plan">
                            <span className="block-details-icon">
                              <span className="icon-location" />
                            </span>
                            <div className="block-details">
                              <h5 className="text-uppercase HN--roman spaced">
                                Location
                              </h5>
                              <h4 className="HN--medium reset-margin">
                                <a
                                  href="/en/map"
                                  target="_blank"
                                  className="text-white"
                                  tabIndex={-1}
                                >
                                  All Stores
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block-gradient" />
              <div className="block-visuals">
                <figure className="block-cover-img">
                  <div
                    data-bgset="https://dynamicassets.azureedge.net/uploads/mall/carousel/pictureMobile/668b8636f1880.jpg"
                    className="bg-image lazyload visible-xs-block"
                    data-alt="YOU SHOP. WE DELIVER."
                    style={{
                      backgroundImage:
                        'url("https://dynamicassets.azureedge.net/uploads/mall/carousel/pictureMobile/668b8636f1880.jpg")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  />
                  <div
                    data-bgset="https://dynamicassets.azureedge.net/uploads/mall/carousel/pictureIpad/668b84b140bc8.jpg"
                    className="bg-image lazyload visible-sm-block"
                    data-alt="YOU SHOP. WE DELIVER."
                    style={{
                      backgroundImage:
                        'url("https://dynamicassets.azureedge.net/uploads/mall/carousel/pictureIpad/668b84b140bc8.jpg")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  />
                  <div
                    data-bgset="https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg"
                    className="bg-image visible-md-block lazyloaded"
                    data-alt="YOU SHOP. WE DELIVER."
                    style={{
                      backgroundImage:
                        'url("https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  >
                    <picture style={{ display: "none" }}>
                      <source
                        data-srcset="https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg"
                        sizes="100%"
                        srcSet="https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg"
                      />
                      <img
                        alt="YOU SHOP. WE DELIVER."
                        data-parent-fit="cover"
                        className="lazyautosizes lazyloaded"
                        data-sizes="auto"
                        sizes="100%"
                      />
                    </picture>
                  </div>
                  <div
                    data-bgset="https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg"
                    className="bg-image visible-lg-block lazyloaded"
                    data-alt="YOU SHOP. WE DELIVER."
                    style={{
                      backgroundImage:
                        'url("https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  >
                    <picture style={{ display: "none" }}>
                      <source
                        data-srcset="https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg"
                        sizes="100%"
                        srcSet="https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg"
                      />
                      <img
                        alt="YOU SHOP. WE DELIVER."
                        data-parent-fit="cover"
                        className="lazyautosizes lazyloaded"
                        data-sizes="auto"
                        sizes="100%"
                      />
                    </picture>
                  </div>
                </figure>
              </div>
            </div>
            <div
              className="block-slide slick-slide slick-current slick-active"
              data-slick-index={1}
              aria-hidden="false"
              style={{ width: "100%" }}
              tabIndex={-1}
              role="option"
              aria-describedby="slick-slide01"
            >
              <div className="block-bottom sg-mb-90px sg-mb-50px-sm">
                <div className="row">
                  <div className="col-sm-20 col-sm-offset-2 col-xs-22 col-xs-offset-1 md-pull-in-line">
                    <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                      <strong>Offers </strong>
                    </h5>
                    <h5 className="text-uppercase sg-mb-15px text-white visible-lg spaced">
                      <strong>Offers</strong>
                    </h5>
                    <div className="row">
                      <div className="col-sm-18">
                        <h1 className="sg-mb-30px sg-mb-10px-xs text-white">
                          SHOP.  UPLOAD RECEIPT.  WIN
                        </h1>
                      </div>
                    </div>
                    <div className="solid-line sg-mb-30px hidden-xs">
                      <button
                        type="button"
                        data-slick-prev=""
                        data-slick-target=".slick-hero"
                        data-role="none"
                        className="slick-btn-custom slick-prev slick-arrow left"
                        aria-label="Prev"
                        role="button"
                        style={{ display: "block" }}
                        tabIndex={0}
                      >
                        Prev
                      </button>
                      <button
                        type="button"
                        data-slick-next=""
                        data-slick-target=".slick-hero"
                        data-role="none"
                        className="slick-btn-custom slick-next slick-arrow right"
                        aria-label="Next"
                        role="button"
                        style={{ display: "block" }}
                        tabIndex={0}
                      >
                        Next
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-sm-11 col-xs-22">
                        <div
                          className="h-body-intro text-white sg-mb-15px"
                          style={{ color: "#FFF !important" }}
                        >
                          Shop or dine at any store in Westgate Shoppping Mall and stance a stance to win amazing gifts
                        </div>
                        <a
                          href="https://thedubaimall.com/en/rewards"
                          className="btn btn-default-transparent btn-block-sm"
                          tabIndex={0}
                        >
                          LEARN MORE
                        </a>
                      </div>
                      <div className="col-sm-13 hidden-xs">
                        <div className="pull-right text-white hero-meta">
                          <div className="sg-ml-10px pull-left hero-meta__time">
                            <span className="block-details-icon">
                              <span className="icon-clock" />
                            </span>
                            <div className="block-details">
                              <h5 className="text-uppercase HN--roman spaced">
                                Open today
                              </h5>
                              <h4 className="HN--medium reset-margin whatTheHell">
                                <span className="text-uppercase">
                                  9 am - 10 pm
                                </span>
                              </h4>
                            </div>
                          </div>
                          <div className="sg-ml-10px pull-left hero-meta__plan">
                            <span className="block-details-icon">
                              <span className="icon-location" />
                            </span>
                            <div className="block-details">
                              <h5 className="text-uppercase HN--roman spaced">
                                Location
                              </h5>
                              <h4 className="HN--medium reset-margin">
                                <a
                                  href="/en/map"
                                  target="_blank"
                                  className="text-white"
                                  tabIndex={0}
                                >
                                  All Stores
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block-gradient" />
              <div className="block-visuals">
                <figure className="block-cover-img">
                  <div
                    data-bgset="https://dynamicassets.azureedge.net/uploads/mall/carousel/pictureMobile/603c71f0626b2.jpg"
                    className="bg-image lazyload visible-xs-block"
                    data-alt="SHOP.  UPLOAD RECEIPT.  WIN"
                    style={{
                      backgroundImage:
                        'url("https://dynamicassets.azureedge.net/uploads/mall/carousel/pictureMobile/603c71f0626b2.jpg")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  />
                  <div
                    data-bgset="https://dynamicassets.azureedge.net/uploads/mall/carousel/pictureIpad/603c71f03bea2.png"
                    className="bg-image lazyload visible-sm-block"
                    data-alt="SHOP.  UPLOAD RECEIPT.  WIN"
                    style={{
                      backgroundImage:
                        'url("https://dynamicassets.azureedge.net/uploads/mall/carousel/pictureIpad/603c71f03bea2.png")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  />
                  <div
                    data-bgset="./assets/images/westgate_shop_and_win.png"
                    className="bg-image visible-md-block lazyloaded"
                    data-alt="SHOP.  UPLOAD RECEIPT.  WIN"
                    style={{
                      backgroundImage:
                        'url("./assets/images/westgate_shop_and_win.png")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  >
                    <picture style={{ display: "none" }}>
                      <source
                        data-srcset="./assets/images/westgate_shop_and_win.png"
                        sizes="100%"
                        srcSet="./assets/images/westgate_shop_and_win.png"
                      />
                      <img
                        alt="SHOP.  UPLOAD RECEIPT.  WIN"
                        data-parent-fit="cover"
                        className="lazyautosizes lazyloaded"
                        data-sizes="auto"
                        sizes="100%"
                      />
                    </picture>
                  </div>
                  <div
                    data-bgset="./assets/images/westgate_shop_and_win.png"
                    className="bg-image visible-lg-block lazyloaded"
                    data-alt="SHOP.  UPLOAD RECEIPT.  WIN"
                    style={{
                      backgroundImage:
                        'url("./assets/images/westgate_shop_and_win.png")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  >
                    <picture style={{ display: "none" }}>
                      <source
                        data-srcset="./assets/images/westgate_shop_and_win.png"
                        sizes="100%"
                        srcSet="./assets/images/westgate_shop_and_win.png"
                      />
                      <img
                        alt="SHOP.  UPLOAD RECEIPT.  WIN"
                        data-parent-fit="cover"
                        className="lazyautosizes lazyloaded"
                        data-sizes="auto"
                        sizes="100%"
                      />
                    </picture>
                  </div>
                </figure>
              </div>
            </div>
            <div
              className="block-slide slick-slide slick-cloned"
              data-slick-index={2}
              id=""
              aria-hidden="true"
              style={{ width: "100%" }}
              tabIndex={-1}
            >
              <div className="block-bottom sg-mb-90px sg-mb-50px-sm">
                <div className="row">
                  <div className="col-sm-20 col-sm-offset-2 col-xs-22 col-xs-offset-1 md-pull-in-line">
                    <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                      <strong>Event </strong>
                    </h5>
                    <h5 className="text-uppercase sg-mb-15px text-white visible-lg spaced">
                      <strong>Event</strong>
                    </h5>
                    <div className="row">
                      <div className="col-sm-18">
                        <h1 className="sg-mb-30px sg-mb-10px-xs text-white">
                          YOU SHOP. WE DELIVER.
                        </h1>
                      </div>
                    </div>
                    <div className="solid-line sg-mb-30px hidden-xs">
                      <button
                        type="button"
                        data-slick-prev=""
                        data-slick-target=".slick-hero"
                        data-role="none"
                        className="slick-btn-custom slick-prev slick-arrow left"
                        aria-label="Prev"
                        role="button"
                        style={{ display: "block" }}
                        tabIndex={-1}
                      >
                        Prev
                      </button>
                      <button
                        type="button"
                        data-slick-next=""
                        data-slick-target=".slick-hero"
                        data-role="none"
                        className="slick-btn-custom slick-next slick-arrow right"
                        aria-label="Next"
                        role="button"
                        style={{ display: "block" }}
                        tabIndex={-1}
                      >
                        Next
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-sm-11 col-xs-22">
                        <div
                          className="h-body-intro text-white sg-mb-15px"
                          style={{ color: "#FFF !important" }}
                        >
                          Enjoy a seamless shopping experience with our Delivery
                          Service.
                        </div>
                        <a
                          href="https://thedubaimall.com/en/services"
                          className="btn btn-default-transparent btn-block-sm"
                          tabIndex={-1}
                        >
                          LEARN MORE
                        </a>
                      </div>
                      <div className="col-sm-13 hidden-xs">
                        <div className="pull-right text-white hero-meta">
                          <div className="sg-ml-10px pull-left hero-meta__time">
                            <span className="block-details-icon">
                              <span className="icon-clock" />
                            </span>
                            <div className="block-details">
                              <h5 className="text-uppercase HN--roman spaced">
                                Open today
                              </h5>
                              <h4 className="HN--medium reset-margin whatTheHell">
                                <span className="text-uppercase">
                                  9 am - 10 pm
                                </span>
                              </h4>
                            </div>
                          </div>
                          <div className="sg-ml-10px pull-left hero-meta__plan">
                            <span className="block-details-icon">
                              <span className="icon-location" />
                            </span>
                            <div className="block-details">
                              <h5 className="text-uppercase HN--roman spaced">
                                Location
                              </h5>
                              <h4 className="HN--medium reset-margin">
                                <a
                                  href="/en/map"
                                  target="_blank"
                                  className="text-white"
                                  tabIndex={-1}
                                >
                                  All Stores
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block-gradient" />
              <div className="block-visuals">
                <figure className="block-cover-img">
                  <div
                    data-bgset="https://dynamicassets.azureedge.net/uploads/mall/carousel/pictureMobile/668b8636f1880.jpg"
                    className="bg-image lazyload visible-xs-block"
                    data-alt="YOU SHOP. WE DELIVER."
                    style={{
                      backgroundImage:
                        'url("https://dynamicassets.azureedge.net/uploads/mall/carousel/pictureMobile/668b8636f1880.jpg")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  />
                  <div
                    data-bgset="https://dynamicassets.azureedge.net/uploads/mall/carousel/pictureIpad/668b84b140bc8.jpg"
                    className="bg-image lazyload visible-sm-block"
                    data-alt="YOU SHOP. WE DELIVER."
                    style={{
                      backgroundImage:
                        'url("https://dynamicassets.azureedge.net/uploads/mall/carousel/pictureIpad/668b84b140bc8.jpg")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  />
                  <div
                    data-bgset="https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg"
                    className="bg-image visible-md-block lazyloaded"
                    data-alt="YOU SHOP. WE DELIVER."
                    style={{
                      backgroundImage:
                        'url("https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  >
                    <picture style={{ display: "none" }}>
                      <source
                        data-srcset="https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg"
                        sizes="100%"
                        srcSet="https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg"
                      />
                      <img
                        alt="YOU SHOP. WE DELIVER."
                        data-parent-fit="cover"
                        className="lazyautosizes lazyloaded"
                        data-sizes="auto"
                        sizes="100%"
                      />
                    </picture>
                  </div>
                  <div
                    data-bgset="https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg"
                    className="bg-image visible-lg-block lazyloaded"
                    data-alt="YOU SHOP. WE DELIVER."
                    style={{
                      backgroundImage:
                        'url("https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg")',
                      backgroundSize: "cover",
                      width: "100%",
                      height: "100vh"
                    }}
                  >
                    <picture style={{ display: "none" }}>
                      <source
                        data-srcset="https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg"
                        sizes="100%"
                        srcSet="https://dynamicassets.azureedge.net/uploads/mall/carousel/picture/668b84b10cfb7.jpg"
                      />
                      <img
                        alt="YOU SHOP. WE DELIVER."
                        data-parent-fit="cover"
                        className="lazyautosizes lazyloaded"
                        data-sizes="auto"
                        sizes="100%"
                      />
                    </picture>
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <ul className="slick-dots" style={{}} role="tablist">
          <li
            className=""
            aria-hidden="true"
            role="presentation"
            aria-selected="true"
            aria-controls="navigation00"
            id="slick-slide00"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabIndex={0}
            >
              1
            </button>
          </li>
          <li
            aria-hidden="false"
            role="presentation"
            aria-selected="false"
            aria-controls="navigation01"
            id="slick-slide01"
            className="slick-active"
          >
            <button
              type="button"
              data-role="none"
              role="button"
              aria-required="false"
              tabIndex={0}
            >
              2
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    className="block-padding-hero"
    data-xs={1}
    data-sm={1}
    data-md={1}
    data-lg={1}
  />
</div>


              {/*Home */}
              <div className="block-container">
                <div className="block-abs">
                  <div
                    className="block block-border-right col-md-14 col-sm-12 hidden-xs"
                    data-block=""
                  >
                    <div className="block-gradient" />
                    <div className="block-visuals">
                      <figure className="block-cover-img">
                        {/*<div class="bg-image lazyload" id="bg-img-fashion-avenue"></div>*/}
                        <div
                          data-bgset="./assets/images/hugo-boss_kenya.jpg"
                          className="bg-image lazyloaded"
                          data-alt="Entertain"
                          style={{
                            backgroundImage:
                              'url("./assets/images/hugo-boss_kenya.jpg")'
                          }}
                        >
                          <picture style={{ display: "none" }}>
                            <source
                              data-srcset="./assets/images/hugo-boss_kenya.jpg"
                              sizes="1913px"
                              srcSet="./assets/images/hugo-boss_kenya.jpg"
                            />
                            <img
                              alt="Entertain"
                              data-parent-fit="cover"
                              className="lazyautosizes lazyloaded"
                              data-sizes="auto"
                              sizes="1913px"
                            />
                          </picture>
                        </div>
                      </figure>
                    </div>
                  </div>
                  <div
                    className="block block-border-left col-md-10 col-sm-12 block-white"
                    data-block=""
                  >
                    <div className="block-content" id="blockFashionAvenue">
                      <div className="display-table">
                        <div
                          className="display-table-cell col-middle text-center"
                          style={{ position: "relative" }}
                        >
                          <h5
                            className="sg-mb-50px text-uppercase visible-xs hidden-sm visible-md visible-lg"
                            style={{
                              position: "absolute",
                              top: "10%",
                              left: 0,
                              right: 0
                            }}
                          >
                            Fashion Avenue
                          </h5>
                          <div className="hidden-xs visible-sm">
                            <br />
                            <h5 className="sg-mb-50px text-uppercase">
                              Fashion Avenue
                            </h5>
                          </div>
                          <h2
                            className="sg-mb-30px text-uppercase block__push-in hidden-xs hidden-sm"
                            style={{ fontWeight: 400 }}
                          >
                            Redefining
                            <br />
                            luxury
                          </h2>
                          <h3
                            className="sg-mb-30px text-uppercase block__push-in visible-sm"
                            style={{ fontWeight: 400 }}
                          >
                            Redefining
                            <br />
                            luxury
                          </h3>
                          <h1
                            className="sg-mb-30px text-uppercase block__push-in visible-xs"
                            style={{ fontWeight: 400 }}
                          >
                            Redefining
                            <br />
                            luxury
                          </h1>
                          <div className="col-lg-12 col-lg-offset-6 col-md-18 col-md-offset-3">
                            <p className="clearfix sg-mb-30px">
                              More than 20 of the most covetable luxury brands at 
                              Westgate Shopping Mall
                            </p>
                            <a
                              href="stores/category/fashion"
                              className="btn btn-primary-transparent btn-primary-transparent--diap-sm text-uppercase hidden-xs visible-sm"
                              style={{ color: "#000", borderColor: "#000" }}
                            >
                              Explore more
                            </a>
                          </div>
                          <div
                            className="visible-xs hidden-sm visible-md visible-lg"
                            style={{
                              position: "absolute",
                              bottom: "10%",
                              left: 15,
                              right: 15
                            }}
                          >
                            <a
                              href="stores/category/fashion"
                              className="btn btn-primary-transparent btn-primary-transparent--diap-sm text-uppercase hidden-xs visible-md visible-lg"
                              style={{ maxWidth: "50%", margin: "auto" }}
                            >
                              Explore more
                            </a>
                            <a
                              href="en/fashion-avenue.html"
                              className="btn btn-primary-transparent btn-primary-transparent--diap-sm text-uppercase visible-xs hidden-sm"
                              style={{ color: "#FFF", borderColor: "#FFF" }}
                            >
                              Explore more
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block-gradient visible-xs" />
                    <div className="block-visuals visible-xs">
                      <figure className="block-cover-img">
                        <div
                          data-bgset="./assets/images/hugo-boss_kenya.jpg"
                          className="bg-image lazyloaded"
                          data-alt="Entertain"
                          style={{
                            backgroundImage:
                              'url("./assets/images/hugo-boss_kenya.jpg")'
                          }}
                        >
                          <picture style={{ display: "none" }}>
                            <source
                              data-srcset="./assets/images/hugo-boss_kenya.jpg"
                              sizes="1913px"
                              srcSet="./assets/images/hugo-boss_kenya.jpg"
                            />
                            <img
                              alt="Entertain"
                              data-parent-fit="cover"
                              className="lazyautosizes lazyloaded"
                              data-sizes="auto"
                              sizes="1913px"
                            />
                          </picture>
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
                <div
                  className="block-padding"
                  data-block-height=""
                  data-xs={1}
                  data-sm={1}
                  data-md={1}
                  data-lg={1}
                />
              </div>
              <div className="block-container">
                <div className="block-abs">
                  <div className="block col-xs-24" data-block="">
                    <div className="block-bottom sg-mb-50px sg-mb-20px-xs">
                      <div
                        className="container-fluid md-full-width md-reset-padding"
                        id="home_view1"
                      >
                        <div className="row">
                          <div className="col-xs-22 col-sm-offset-half md-pull-in-line">
                            <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                              <strong>Entertain</strong>
                            </h5>
                            <h5 className="text-uppercase sg-mb-25px text-white visible-lg spaced">
                              <strong>Entertain</strong>
                            </h5>
                            <h1
                              className="sg-mb-10px text-white col-sm-16"
                              style={{ marginLeft: 0, paddingLeft: 0 }}
                            >
                              Westgate  Cinema
                            </h1>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-22 col-sm-offset-half md-pull-in-line">
                            <span className="sg-mb-10px text-white col-sm-16">
                              <p className="sg-mb-25px text-white hidden-xs">
                                Check our current lineup and book tickets online
                              </p>
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-22 col-sm-offset-half md-pull-in-line">
                            <a
                              href="/movies"
                              className="btn btn-default-transparent btn-block-sm text-uppercase margin-right-xs-px"
                            >
                              Book tickets
                            </a>
                            <a
                              href="/entertainment"
                              className="btn btn-default-transparent btn-block-sm text-uppercase"
                            >
                              Discover entertainment
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block-gradient" />
                    <div className="block-visuals">
                      <figure className="block-cover-img">
                        <div
                          data-bgset="./assets/images/Cinema-Westgate.webp"
                          className="bg-image lazyloaded"
                          data-alt="Entertain"
                          style={{
                            backgroundImage:
                              'url("./assets/images/Cinema-Westgate.webp")'
                          }}
                        >
                          <picture style={{ display: "none" }}>
                            <source
                              data-srcset="./assets/images/Cinema-Westgate.webp"
                              sizes="1913px"
                              srcSet="./assets/images/Cinema-Westgate.webp"
                            />
                            <img
                              alt="Entertain"
                              data-parent-fit="cover"
                              className="lazyautosizes lazyloaded"
                              data-sizes="auto"
                              sizes="1913px"
                            />
                          </picture>
                        </div>
                      </figure>
                    </div>
                   
                  </div>
                </div>
                <div
                  className="block-padding"
                  data-block-height=""
                  data-xs={1}
                  data-sm={1}
                  data-md={1}
                  data-lg={1}
                />
              </div>
              <div className="block-container">
                <div className="block-abs">
                  <div
                    className="block block-border-right col-md-12 col-sm-24 "
                    id="home_view2"
                    data-block=""
                  >
                    <div className="block-bottom sg-mb-50px sg-mb-20px-xs">
                      <div className="container-fluid md-reset-padding md-full-width">
                        <div className="row">
                          <div className="col-xs-22 col-xs-offset-1 md-pull-in-line">
                            <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                              <strong>Dine</strong>
                            </h5>
                            <h5 className="text-uppercase sg-mb-25px text-white visible-lg spaced">
                              <strong>Dine</strong>
                            </h5>
                            <div className="visible-xs">
                              <h1 className="sg-mb-10px text-white" />
                            </div>
                            <div className="hidden-xs">
                              <h2 className="sg-mb-10px text-white" />
                            </div>
                            <div className="row">
                              <div className="col-md-20 col-sm-14">
                                <p className="sg-mb-25px text-white hidden-xs" />
                              </div>
                            </div>
                           
                            <a
                              href="stores/category/dine"
                              className="btn btn-default-transparent btn-block-sm text-uppercase"
                            >
                              Discover Restaurants
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block-gradient" />
                    <div className="block-visuals">
                      <figure className="block-cover-img">
                      <div
  data-bgset="./assets/images/Dine-ExclusiveGym.webp"
  className="bg-image lazyloaded"
  data-alt="Entertain"
  style={{
    backgroundImage:
      'url("./assets/images/Dine-ExclusiveGym.webp")'
  }}
>
  <picture style={{ display: "none" }}>
    <source
      data-srcset="./assets/images/Dine-ExclusiveGym.webp"
      sizes="1913px"
      srcSet="./assets/images/Dine-ExclusiveGym.webp"
    />
    <img
      alt="Entertain"
      data-parent-fit="cover"
      className="lazyautosizes lazyloaded"
      data-sizes="auto"
      sizes="1913px"
    />
  </picture>
</div>
                      </figure>
                    </div>
                  </div>
                  <div
                    className="block block-border-left col-md-12 col-sm-24"
                    data-block=""
                  >
                    <div className="block-wrap">
                      <div
                        className="block-bottom sg-mb-50px sg-mb-20px-xs"
                        id="home_view3"
                      >
                        <div className="container-fluid md-reset-padding md-full-width">
                          <div className="row">
                            <div className="col-xs-22 col-xs-offset-1 md-pull-in-line">
                              <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                                <strong>Shop</strong>
                              </h5>
                              <h5 className="text-uppercase sg-mb-15px text-white visible-lg spaced">
                                <strong>Shop</strong>
                              </h5>
                              <div className="visible-xs">
                                <h1 className="sg-mb-10px text-white" />
                              </div>
                              <div className="hidden-xs">
                                <h2 className="sg-mb-10px text-white" />
                              </div>
                              <div className="row">
                                <div className="col-md-20 col-sm-12">
                                  <p className="sg-mb-25px text-white hidden-xs" />
                                </div>
                              </div>
                             
                              <a
                                href="stores/category/shop"
                                className="btn btn-default-transparent btn-block-sm text-uppercase"
                              >
                                Discover Shops
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block-gradient" />
                      <div className="block-visuals">
                        <figure className="block-cover-img">
                        <div
  data-bgset="./assets/images/hla_westgate.jpg"
  className="bg-image lazyloaded"
  data-alt="Entertain"
  style={{
    backgroundImage:
      'url("./assets/images/hla_westgate.jpg")'
  }}
>
  <picture style={{ display: "none" }}>
    <source
      data-srcset="./assets/hla_westgate.jpg"
      sizes="1913px"
      srcSet="./assets/images/hla_westgate.jpg"
    />
    <img
      alt="Entertain"
      data-parent-fit="cover"
      className="lazyautosizes lazyloaded"
      data-sizes="auto"
      sizes="1913px"
    />
  </picture>
</div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="block-padding"
                  data-block-height=""
                  data-xs={2}
                  data-sm={2}
                  data-md={1}
                  data-lg={1}
                />
              </div>
              
              <div className="clearfix">

                {/* workout*/}
                <div className="block-container workout" >
                <div className="block-abs">
                  <div
                    className="block block-border-right col-md-12 col-sm-24 "
                    id="home_view2"
                    data-block=""
                  >
                    <div className="block-bottom sg-mb-50px sg-mb-20px-xs">
                      <div className="container-fluid md-reset-padding md-full-width">
                        <div className="row">
                          <div className="col-xs-22 col-xs-offset-1 md-pull-in-line">
                            <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                              <strong>Wear</strong>
                            </h5>
                            <h5 className="text-uppercase sg-mb-25px text-white visible-lg spaced">
                              <strong>Wear</strong>
                            </h5>
                            <div className="visible-xs">
                              <h1 className="sg-mb-10px text-white" />
                            </div>
                            <div className="hidden-xs">
                              <h2 className="sg-mb-10px text-white" />
                            </div>
                            <div className="row">
                              <div className="col-md-20 col-sm-14">
                                <p className="sg-mb-25px text-white hidden-xs" />
                              </div>
                            </div>
                           
                            <a
                              href="stores/category/fashion"
                              className="btn btn-default-transparent btn-block-sm text-uppercase"
                            >
                              Discover wear
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block-gradient" />
                    <div className="block-visuals">
                      <figure className="block-cover-img">
                      <div
  data-bgset="./assets/images/clarks_kenya.jpg"
  className="bg-image lazyloaded"
  data-alt="Entertain"
  style={{
    backgroundImage:
      'url("./assets/images/clarks_kenya.jpg")'
  }}
>
  <picture style={{ display: "none" }}>
    <source
      data-srcset="./assets/images/clarks_kenya.jpg"
      sizes="1913px"
      srcSet="./assets/images/clarks_kenya.jpg"
    />
    <img
      alt="Entertain"
      data-parent-fit="cover"
      className="lazyautosizes lazyloaded"
      data-sizes="auto"
      sizes="1913px"
    />
  </picture>
</div>
                      </figure>
                    </div>
                  </div>
                  <div
                    className="block block-border-left col-md-12 col-sm-24"
                    data-block=""
                  >
                    <div className="block-wrap">
                      <div
                        className="block-bottom sg-mb-50px sg-mb-20px-xs"
                        id="home_view3"
                      >
                        <div className="container-fluid md-reset-padding md-full-width">
                          <div className="row">
                            <div className="col-xs-22 col-xs-offset-1 md-pull-in-line">
                              <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                                <strong>Work out</strong>
                              </h5>
                              <h5 className="text-uppercase sg-mb-15px text-white visible-lg spaced">
                                <strong>Work out</strong>
                              </h5>
                              <div className="visible-xs">
                                <h1 className="sg-mb-10px text-white" />
                              </div>
                              <div className="hidden-xs">
                                <h2 className="sg-mb-10px text-white" />
                              </div>
                              <div className="row">
                                <div className="col-md-20 col-sm-12">
                                  <p className="sg-mb-25px text-white hidden-xs" />
                                </div>
                              </div>
                              <a
                                href="#"
                                target="_blank"
                                className="exploreMoreStayCTA btn btn-default-transparent btn-block-sm margin-right-xs-px text-uppercase"
                              >
                                Explore our gym
                              </a>
                              <a
                                href="en/stay.html"
                                className="btn btn-default-transparent btn-block-sm text-uppercase"
                              >
                                Discover fitness
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block-gradient" />
                      <div className="block-visuals">
                        <figure className="block-cover-img">
                        <div
  data-bgset="./assets/images/W-ExclusiveGym-28Westgate.webp"
  className="bg-image lazyloaded"
  data-alt="Entertain"
  style={{
    backgroundImage:
      'url("./assets/images/W-ExclusiveGym-28Westgate.webp")'
  }}
>
  <picture style={{ display: "none" }}>
    <source
      data-srcset="./assets/images/W-ExclusiveGym-28Westgate.webp"
      sizes="1913px"
      srcSet="./assets/images/W-ExclusiveGym-28Westgate.webp"
    />
    <img
      alt="Entertain"
      data-parent-fit="cover"
      className="lazyautosizes lazyloaded"
      data-sizes="auto"
      sizes="1913px"
    />
  </picture>
</div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="block-padding"
                  data-block-height=""
                  data-xs={2}
                  data-sm={2}
                  data-md={1}
                  data-lg={1}
                />
              </div>

                {/* end work out */}
                <section
                  className="site-section"
                  style={{ width: "100%", overflow: "hidden" }}
                >


                  <div className="visible-xs">
                    <br />
                  </div>
                </section>
                <section className="site-section">
                  <div className="view-panel-banner">
                    <div className="view-panel-banner__rel">
                      <div className="row">
                        <div className="col-xs-22 col-xs-offset-1 text-center">
                          <div className="visible-xs visible-sm">
                            <h1 className="sg-mb-20px text-white">
                              Westgate  <br />
                              Gift Voucher
                            </h1>
                          </div>
                          <div className="visible-md visible-lg">
                            <h2 className="sg-mb-20px text-white">
                              Westgate<br />
                              Gift Voucher
                            </h2>
                          </div>
                          <a
                            href="en/whats-new.html"
                            className="btn btn-default-transparent btn-block-sm text-uppercase"
                          >
                            Buy Now
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="view-panel-banner__gradient half-opacity" />
                    <div className="view-panel-banner__visual">
                      <div
                        data-bgset="./assets/images/bottom_banner.png"
                        className="bg-image lazyloaded"
                        data-alt="Entertain"
                        style={{
                          backgroundImage:
                            'url("./assets/images/bottom_banner.png")'
                        }}
                      >
                        <picture style={{ display: "none" }}>
                          <source
                            data-srcset="./assets/images/bottom_banner.png"
                            sizes="1913px"
                            srcSet="./assets/images/bottom_banner.png"
                          />
                          <img
                            alt="Entertain"
                            data-parent-fit="cover"
                            className="lazyautosizes lazyloaded"
                            data-sizes="auto"
                            sizes="1913px"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div
          className="site-collapse-menu site-collapse-menu--main hidden"
          id="site-collapse-menu"
          style={{ marginTop: 50 }}
        >
          <nav>
            <ul className="js--accordion" role="tablist">
              <li className="site-collapse-menu-item">
                <a href="en/whats-new.html">What's New</a>
              </li>
              <li className="site-collapse-menu-item">
                <a href="en/shop.html">Shop</a>
              </li>
              <li className="site-collapse-menu-item">
                <a href="en/dine.html">Dine</a>
              </li>
              <li className="site-collapse-menu-item">
                <a href="en/stay.html">Stay</a>
              </li>
              <li className="site-collapse-menu-item">
                <a href="en/entertain.html">Entertain</a>
              </li>
              <li className="site-collapse-menu-item">
                <a className="site-collapse-menu__level-0" href="#mm-5">
                  Location
                </a>
                <ul className="collapse" id="mm-5">
                  <li className="site-collapse-menu-item">
                    <a href="en/plan-your-visit/opening-hours.html">Opening hours</a>
                  </li>
                  <li className="site-collapse-menu-item">
                    <a href="en/plan-your-visit/getting-here.html">Getting here</a>
                  </li>
                  <li className="site-collapse-menu-item">
                    <a href="en/u-by-emaar.html">U By Emaar</a>
                  </li>
                  <li>
                    <a href="en/plan-your-visit/parking.html">Parking</a>
                  </li>
                </ul>
              </li>
              <li className="site-collapse-menu-item">
                <a href="en/services.html">Services</a>
              </li>
            </ul>
          </nav>
          <nav className="inverse">
            <ul>
              <li className="site-collapse-menu-item">
                <a href="en/fashion-avenue.html">Fashion avenue</a>
              </li>
              <li className="site-collapse-menu-item">
                <a href="en/fountain-view.html">Fountain views</a>
              </li>
              <li className="site-collapse-menu-item">
                <a href="">Pop-up Market</a>
              </li>
              <li className="site-collapse-menu-item">
                <a href="en/campaign/souk-al-bahar.html">Souk Al Bahar</a>
              </li>
              <li className="site-collapse-menu-item">
                <a href="en/offers.html">Offers</a>
              </li>
              <li className="site-collapse-menu-item">
                <a href="en/events.html">Events</a>
              </li>
            </ul>
          </nav>
          <nav>
            <ul className="js--accordion" role="tablist">
              <li className="site-collapse-menu-item menu_lan" id="language-selecter">
                <a href="ar.html">العربية</a>
              </li>
              <li className="site-collapse-menu-item"></li>
            </ul>
          </nav>
        </div>
        <div className="visible-xs sfooter-menu">
          <div className="site-collapse-menu" id="site-collapse-menu-footer">
            <nav>
              <ul>
                <li className="site-collapse-menu-item">
                  <a href="en/whats-new.html">What's New</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/shop.html">Shop</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/dine-directory.html">Dine</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/stay.html">Stay</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/entertain.html">Entertain</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/plan-your-visit/opening-hours.html">Location</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/services.html">Services</a>
                </li>
              </ul>
            </nav>
            <nav className="inverse">
              <ul>
                <li className="site-collapse-menu-item">
                  <a href="en/fashion-avenue.html"> Fashion Avenue</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/offers.html">Offers</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/events.html"> Events</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <Footer />

    </>

  )
}

export default Home