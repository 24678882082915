import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"

const NotFound = ()=>{
    
    return(
        <>
        <Header />
        <div className="constant-dots">
                <div className="site">
                    <div className="site-content">
                        <div className="site-sections">
                          
                            <section className="site-section">
                                {/* @view-panel */}
                                <div className="view-panel view-panel--xs">
                                    <div className="view-panel__abs">
                                        <div className="view-panel__top hidden-xs">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-24">
                                                        <ul
                                                            className="breadcrumb breadcrumb--reverse sg-mt-20px"
                                                            itemScope=""
                                                            itemType="https://schema.org/BreadcrumbList"
                                                        >
                                                            <li
                                                                itemProp="itemListElement"
                                                                itemScope=""
                                                                itemType="https://schema.org/ListItem"
                                                            >
                                                                <a itemProp="item" href="/en">
                                                                    <span itemProp="name">Home</span>
                                                                </a>
                                                                <meta itemProp="position" content={1} />
                                                            </li>
                                                            <li
                                                                itemProp="itemListElement"
                                                                itemScope=""
                                                                itemType="https://schema.org/ListItem"
                                                            >
                                                                <span className="arrow" />
                                                                <span itemProp="name">About us</span>
                                                                <meta itemProp="position" content={2} />
                                                            </li>
                                                            <li
                                                                itemProp="itemListElement"
                                                                itemScope=""
                                                                itemType="https://schema.org/ListItem"
                                                            >
                                                                <span className="arrow" />
                                                                <span itemProp="name">About Westgate Shoping Mall</span>
                                                                <meta itemProp="position" content={3} />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="display-table">
                                            <div className="display-table-cell col-middle">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-xs-24">
                                                            <h1 className="text-center text-white">
                                                                {" "}
                                                                About Westgate Shoping Mall
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="view-panel__gradient" />
                                    <div className="view-panel__visual">
                                        <div
                                            data-bgset="./assets/images/westgate-shopping-mall-exterior.jpg"
                                            className="bg-image lazyloaded"
                                            data-alt="Entertain"
                                            style={{
                                                backgroundImage:
                                                    'url("./assets/images/westgate-shopping-mall-exterior.jpg")'
                                            }}
                                        >
                                            <picture style={{ display: "none" }}>
                                                <source
                                                    data-srcset="./assets/images/westgate-shopping-mall-exterior.jpg"
                                                    sizes="1913px"
                                                    srcSet="./assets/images/westgate-shopping-mall-exterior.jpg"
                                                />
                                                <img
                                                    alt="Entertain"
                                                    data-parent-fit="cover"
                                                    className="lazyautosizes lazyloaded"
                                                    data-sizes="auto"
                                                    sizes="1913px"
                                                />
                                            </picture>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="site-section">
                                <div className="container sg-mt-30px">
                                    <div className="row">
                                        <div className="col-xs-24">
                                            <div className="row ">
                                                <div className="col-sm-24">
                                                    <center>
                                                        <strong>WESTGATE, NAIROBI’S PREMIER SHOPPING MALL
                                                        </strong>
                                                    </center>
                                                    <p>
                                                        <br />
                                                        <br /> <strong>Experience Lifetyle</strong>
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        Westgate, Nairobi's premier shopping mall is East Africa's finest shopping, leisure and pleasure destination for
                                                        millions of resident and international visitors. With lots of daily, weekly and monthly entertainment options for the
                                                        whole family.
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        Westgate is the only shopping mall in East Africa that has shoppers engaged in exciting and enlightening
                                                        events for all.
                                                    </p>
                                                    <p>
                                                        <br /> <strong>Variety</strong>
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        With over 80 stores and a variety of art and craft kiosks all open from 9:00 a.m. to 8:00 p.m. as well as entertainment
                                                        outlets and restaurants that are open till midnight seven days a week; Westgate upholds the standards to
                                                        keep our shoppers happy and give them the best variety.
                                                    </p>
                                                    <p>&nbsp;</p>

                                                    <p>
                                                        <br /> <strong>Prime location</strong>
                                                    </p>
                                                    <p>
                                                        Westgate Shopping Mall attracts a highly cosmopolitan and sophisticated clientele, and reaches a well-defined and
                                                        attractive demographic audience.
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        Located in the heart of Westlands along Mwanzi Road, Westgate Shopping Mall is easily accessible from the city
                                                        center and Nairobi environs. Shoppers are assured of an enjoyable and convenient shopping experience in a relaxed
                                                        atmosphere; all under one roof. Westgate has ample parking for over 550 cars.
                                                    </p>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div
                    className="site-collapse-menu site-collapse-menu--main hidden"
                    id="site-collapse-menu"
                    style={{ marginTop: 50 }}
                >
                    <nav>
                        <ul className="js--accordion" role="tablist">
                            <li className="site-collapse-menu-item">
                                <a href="/en/whats-new">What's New</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/shop">Shop</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/dine">Dine</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/stay">Stay</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/entertain">Entertain</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a className="site-collapse-menu__level-0" href="#mm-5">
                                    Plan Your Visit
                                </a>
                                <ul className="collapse" id="mm-5">
                                    <li className="site-collapse-menu-item">
                                        <a href="/en/plan-your-visit/opening-hours">Opening hours</a>
                                    </li>
                                    <li className="site-collapse-menu-item">
                                        <a href="/en/plan-your-visit/getting-here">Getting here</a>
                                    </li>
                                    <li className="site-collapse-menu-item">
                                        <a href="/en/u-by-emaar">U By Emaar</a>
                                    </li>
                                    <li>
                                        <a href="/en/plan-your-visit/parking">Parking</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/services">Services</a>
                            </li>
                        </ul>
                    </nav>
                    <nav className="inverse">
                        <ul>
                            <li className="site-collapse-menu-item">
                                <a href="/en/fashion-avenue">Fashion avenue</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/fountain-view">Fountain views</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/campaign/chinatown">Chinatown</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/campaign/souk-al-bahar">Souk Al Bahar</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/offers">Offers</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/events">Events</a>
                            </li>
                        </ul>
                    </nav>
                    <nav>
                        <ul className="js--accordion" role="tablist">
                            <li className="site-collapse-menu-item menu_lan" id="language-selecter">
                                <a href="/ar/about-us/about-the-dubai-mall">العربية</a>
                            </li>
                            <li className="site-collapse-menu-item"></li>
                        </ul>
                    </nav>
                </div>
                <div className="visible-xs sfooter-menu">
                    <div className="site-collapse-menu" id="site-collapse-menu-footer">
                        <nav>
                            <ul>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/whats-new">What's New</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/shop">Shop</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/dine-directory">Dine</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/stay">Stay</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/entertain">Entertain</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/plan-your-visit/opening-hours">Plan Your Visit</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/services">Services</a>
                                </li>
                            </ul>
                        </nav>
                        <nav className="inverse">
                            <ul>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/fashion-avenue"> Fashion Avenue</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/offers">Offers</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/events"> Events</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
     

        <Footer />
        </>
    )
}

export default NotFound