/*
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function MovieDetails() {
  const { movieId } = useParams(); // Extract movie slug from the URL
  const [movieData, setMovieData] = useState(null);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    const fetchMovieData = async () => {
      try {
        const response = await fetch('http://localhost/cinema/films.php');
        const data = await response.json();

        // Accessing the 'value' array from the data object
        const movies = data.value;

        // Generate slugs for each movie and find the matching movie
        const selectedMovie = movies.find(item => generateSlug(item.Title) === movieId);
        if (selectedMovie) {
          setMovieData(selectedMovie);
          fetchSessions(selectedMovie.ScheduledFilmId);
        } else {
          console.error('Movie not found:', movieId);
        }
      } catch (error) {
        console.error('Error fetching movie data:', error);
      }
    };

    const fetchSessions = async (filmId) => {
      try {
        const response = await fetch('http://localhost/cinema/sessions.php');
        const data = await response.json();

        // Accessing the 'value' array from the data object
        const sessionsData = data.value;

        // Filter sessions by ScheduledFilmId
        const filteredSessions = sessionsData.filter(item => item.ScheduledFilmId === filmId);
        setSessions(groupSessionsByDate(filteredSessions));
      } catch (error) {
        console.error('Error fetching sessions:', error);
      }
    };

    fetchMovieData();
  }, [movieId]);

  const generateSlug = (string) => {
    let slug = string.toLowerCase();
    slug = slug.replace(/[^a-z0-9]+/gi, '-').replace(/(^-|-$)/g, '');
    return slug;
  };

  const convertToHoursMins = (minutes) => {
    if (minutes < 0) return "Invalid input";
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours} hr ${mins} min`;
  };

  const groupSessionsByDate = (sessions) => {
    const groupedSessions = {};
    sessions.forEach(item => {
      const dateTime = new Date(item.Showtime);
      const date = dateTime.toISOString().split('T')[0];
      const time = dateTime.toTimeString().split(' ')[0].substring(0, 5);
      const screen = item.ScreenNumber > 4 ? `VIP Screen ${item.ScreenNumber}` : `Screen ${item.ScreenNumber}`;
      const view = item.SessionAttributesNames.join(', ');

      if (!groupedSessions[date]) {
        groupedSessions[date] = [];
      }

      groupedSessions[date].push({ time, screen, view });
    });

    return groupedSessions;
  };

  if (!movieData) return <div>Loading...</div>;

  const { Title: filmName, Rating: filmRating, RatingDescription: filmDes, RunTime, GraphicUrl: image, GenreId, GenreId2, GenreId3 } = movieData;
  const genres = {
    "0000000001": "ACTION",
    "0000000002": "SCIENCE FICTION",
    "0000000003": "DRAMA / MUSICAL",
    "0000000004": "COMEDY",
    "0000000005": "THRILLER",
    "0000000006": "ANIMATION",
    "0000000007": "ACTION / DRAMA",
    "0000000008": "ACTION / SCIENCE FICTION",
    "0000000009": "COMEDY / ROMANCE",
    "0000000010": "ROMANCE",
    "0000000011": "COMEDY / DRAMA",
    "0000000012": "ACTION / ADVENTURE / HISTORY",
    "0000000013": "ACTION /ADVENTURE",
    "0000000014": "HISTORY",
    "0000000015": "DOCUMENTARY",
    "0000000016": "ACTION / ADVENTURE / SCI-FICTION",
    "0000000017": "ADVENTURE",
    "0000000018": "SPORT",
    "0000000019": "DRAMA / MYSTERY",
    "0000000020": "HORROR",
    "0000000021": "CRIME",
    "0000000022": "DRAMA",
    "0000000023": "FANTASY",
    "0000000024": "FAMILY",
    "0000000025": "BIOGRAPHY",
    "0000000026": "DRAMA / ROMANCE",
    "0000000027": "HEAVY METAL MUSIC",
    "0000000028": "MUSIC"
  };

  const genre = [genres[GenreId], genres[GenreId2], genres[GenreId3]].filter(Boolean).join(', ');

  return (
    <div className="row timetable" align="left">
      <div style={{ maxWidth: '200px' }} className="col-md-1">
        <img width="675" height="1013" src={image} alt={filmName} className="attachment-post-thumbnail size-post-thumbnail wp-post-image" />
      </div>
      <div className="col-md-3">
        <div className="movie-details">
          <h5 className="movie-title">{filmName}</h5>
          <span className="movie_rating">{filmRating}</span>
          <span className="movie_des">{filmDes}</span>
          <span className="movie_cat">{convertToHoursMins(RunTime)}</span>
        </div>
        <div className="movie-genre">{genre}</div>
      </div>
      <div className="col-md-8">
        <table>
          {Object.keys(sessions).map(date => (
            <React.Fragment key={date}>
              {sessions[date].map(({ time, screen, view }, index) => (
                <tr key={`${date}-${index}`}>
                  {index === 0 && (
                    <td rowSpan={sessions[date].length} style={{ padding: 0, margin: '7px 0 3px' }}>
                      {new Date(date).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
                    </td>
                  )}
                  <td style={{ width: '40%' }} className="timing">{time}</td>
                  <td style={{ width: '20%', padding: 0 }}>&nbsp;{screen}</td>
                  <td style={{ width: '10%', padding: 0 }}><span style={{ width: '30%' }} className="visuals">{view}</span></td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </table>
      </div>
    </div>
  );
}

export default MovieDetails;

*/
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import "./showtimes_1.css";
import "./showtimes_2.css";
import "./showtimes_3.css";

function MovieDetails() {
  const { movieId } = useParams();
  const [movieData, setMovieData] = useState(null);
  const [groupedSessions, setGroupedSessions] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const datesContainerRef = useRef(null);

  useEffect(() => {
    const fetchMovieData = async () => {
      try {
        const response = await fetch('http://localhost/cinema/films.php');
        const data = await response.json();
        const movies = data.value;

        const selectedMovie = movies.find(item => generateSlug(item.Title) === movieId);
        if (selectedMovie) {
          setMovieData(selectedMovie);
          fetchSessions(selectedMovie.ScheduledFilmId);
        } else {
          console.error('Movie not found:', movieId);
        }
      } catch (error) {
        console.error('Error fetching movie data:', error);
      }
    };

    const fetchSessions = async (filmId) => {
      try {
        const response = await fetch('http://localhost/cinema/sessions.php');
        const data = await response.json();
        const sessionsData = data.value;

        const filteredSessions = sessionsData.filter(item => item.ScheduledFilmId === filmId);
        const grouped = groupSessionsByDate(filteredSessions);
        setGroupedSessions(grouped);
        setSelectedDate(Object.keys(grouped)[0]); // Set the first date as selected initially
      } catch (error) {
        console.error('Error fetching sessions:', error);
      }
    };

    fetchMovieData();
  }, [movieId]);

  const generateSlug = (string) => {
    let slug = string.toLowerCase();
    slug = slug.replace(/[^a-z0-9]+/gi, '-').replace(/(^-|-$)/g, '');
    return slug;
  };
  const convertToHoursMins = (minutes) => {
    if (minutes < 0) return "Invalid input";
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours} hr ${mins} min`;
  };

  const groupSessionsByDate = (sessions) => {
    const grouped = {};
    sessions.forEach(item => {
      const dateTime = new Date(item.Showtime);
      const date = dateTime.toISOString().split('T')[0];
      const time = dateTime.toTimeString().split(' ')[0].substring(0, 5);
      const screen = item.ScreenNumber > 4 ? `VIP Screen ${item.ScreenNumber}` : `Screen ${item.ScreenNumber}`;
      const view = item.SessionAttributesNames.join(', ');

      if (!grouped[date]) {
        grouped[date] = { premium: [], vip: [] };
      }

      if (item.ScreenNumber > 4) {
        grouped[date].vip.push({ time, screen, view });
      } else {
        grouped[date].premium.push({ time, screen, view });
      }
    });

    return grouped;
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const scrollLeft = () => {
    datesContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    datesContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  if (!movieData) return <div>Loading...</div>;

  const { Title: filmName, Rating: filmRating, RatingDescription: filmDes, RunTime, Synopsis: filmSynopsis, GraphicUrl: image, GenreId, GenreId2, GenreId3 } = movieData;
  const genres = {
    "0000000001": "ACTION",
    "0000000002": "SCIENCE FICTION",
    "0000000003": "DRAMA / MUSICAL",
    "0000000004": "COMEDY",
    "0000000005": "THRILLER",
    "0000000006": "ANIMATION",
    "0000000007": "ACTION / DRAMA",
    "0000000008": "ACTION / SCIENCE FICTION",
    "0000000009": "COMEDY / ROMANCE",
    "0000000010": "ROMANCE",
    "0000000011": "COMEDY / DRAMA",
    "0000000012": "ACTION / ADVENTURE / HISTORY",
    "0000000013": "ACTION /ADVENTURE",
    "0000000014": "HISTORY",
    "0000000015": "DOCUMENTARY",
    "0000000016": "ACTION / ADVENTURE / SCI-FICTION",
    "0000000017": "ADVENTURE",
    "0000000018": "SPORT",
    "0000000019": "DRAMA / MYSTERY",
    "0000000020": "HORROR",
    "0000000021": "CRIME",
    "0000000022": "DRAMA",
    "0000000023": "FANTASY",
    "0000000024": "FAMILY",
    "0000000025": "BIOGRAPHY",
    "0000000026": "DRAMA / ROMANCE",
    "0000000027": "HEAVY METAL MUSIC",
    "0000000028": "MUSIC"
  };

  const genre = [genres[GenreId], genres[GenreId2], genres[GenreId3]].filter(Boolean).join(', ');

  return (
    <div className="showtime-desktop">
      <div className="div">
        {/* Movie Title */}
        <div className="a-quiet-place-day">{filmName}</div>

        {/* Banner */}
        <div className="banner">
          <img className="rectangle" src={image} alt={filmName} />
          <div className="video-player"></div>
        </div>

        {/* Horizontal Scrolling Dates with Arrows */}
        <div className="dates-container" style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={scrollLeft} className="scroll-button" style={{ cursor: 'pointer' }}>&lt;</button>
          <div className="dates" ref={datesContainerRef} style={{ display: 'flex', overflowX: 'auto', scrollBehavior: 'smooth' }}>
            {Object.keys(groupedSessions).map(date => {
              const dateTime = new Date(date);
              const weekday = dateTime.toLocaleDateString('en-US', { weekday: 'long' });
              const month = dateTime.toLocaleDateString('en-US', { month: 'long' });
              const day = dateTime.getDate();
              return (
                <div
                  className={`overlap ${date === selectedDate ? 'selected' : ''}`}
                  key={date}
                  onClick={() => handleDateClick(date)}
                  style={{ cursor: 'pointer', padding: '10px', marginRight: '10px', backgroundColor: date === selectedDate ? '#FFB43A' : '#ccc', borderRadius: '10px' }}
                >
                  <div className="text-wrapper">{weekday.toUpperCase()}</div>
                  <div className="text-wrapper-2">{month.toUpperCase()}</div>
                  <div className="text-wrapper-3">{day}</div>
                </div>
              );
            })}
          </div>
          <button onClick={scrollRight} className="scroll-button" style={{ cursor: 'pointer' }}>&gt;</button>
        </div>

        {/* Runtime */}
        <div className="runtime">RUNTIME: {convertToHoursMins(RunTime)}</div>

        {/* Movie Synopsis */}
        <p className="movie-info">{filmSynopsis}</p>

        {/* Rating */}
        <div className="rating">
          <div className="overlap-5">
            <div className="rectangle-2"></div>
            <div className="rating-2">RATING {filmRating}</div>
          </div>
        </div>

        {/* Genres */}
        <div className="genre">
          <div className="overlap-5">
            <div className="text-wrapper-11">{genre}</div>
            <div className="rectangle-3"></div>
          </div>
        </div>

        {/* VIP Timings */}
        <div className="VIP-timing">
          <div className="vip-label">2D - VIP</div>
          {groupedSessions[selectedDate]?.vip.map((session, index) => (
            <div className="group-3" key={index}>
              <div className="element-wrapper">
                <div className="element">{session.time}</div>
              </div>
              <div className="element-kshs">{session.view} - VIP</div>
            </div>
          ))}
        </div>

        {/* Premium Timings */}
        <div className="premium-timing">
          <div className="premium-label">2D - PREMIUM</div>
          {groupedSessions[selectedDate]?.premium.map((session, index) => (
            <div className="group" key={index}>
              <div className="element-wrapper">
                <div className="element">{session.time}</div>
              </div>
              <div className="element-kshs">{session.view} - PREMIUM</div>
            </div>
          ))}
        </div>

        {/* Book Seats */}
        <div className="book-seats">
          <div className="overlap-9">
            <div className="book-seats-2">BOOK SEATS</div>
            <img className="arrow" src="img/arrow-2.svg" alt="arrow" />
          </div>
        </div>

        {/* Footer */}
        <footer className="footer">
          <div className="westgate-cinema">WESTGATE CINEMA 2024</div>
          <div className="follow-us">FOLLOW US</div>
          <div className="download-westgate">DOWNLOAD WESTGATE APP</div>
          <img className="group-5" src="img/group-244.png" alt="group" />
          <img className="group-6" src="img/group.png" alt="group" />
          <div className="frame">
            <div className="group-7">
              <div className="vector-wrapper"><img className="vector" src="img/vector.svg" alt="vector" /></div>
              <div className="img-wrapper"><img className="vector-2" src="img/vector-1.svg" alt="vector" /></div>
              <div className="overlap-10"><img className="vector-3" src="img/vector-2.svg" alt="vector" /></div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default MovieDetails;
