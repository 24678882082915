import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
const Privacy =()=>{
    return(
        <>
<Header/>
<div className="constant-dots">
  <div className="site">
    <div className="site-content">
      <div className="site-sections">
        <nav className="site-sections__menu hidden-xs">
          <ul>
            <li>
              <a href="../fashion-avenue.html">Fashion avenue</a>
            </li>
            <li>
              <a href="../fountain-view.html">Fountain views</a>
            </li>
            <li>
              <a href="../campaign/chinatown.html">Chinatown</a>
            </li>
            <li>
              <a href="../campaign/souk-al-bahar.html">Souk Al Bahar</a>
            </li>
            <li>
              <a href="../offers.html">Offers</a>
            </li>
            <li>
              <a href="../events.html">Events</a>
            </li>
          </ul>
        </nav>
        <section className="site-section">
          {/* @view-panel */}
          <div className="view-panel view-panel--xs">
            <div className="view-panel__abs">
              <div className="view-panel__top hidden-xs">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-24">
                      <ul
                        className="breadcrumb breadcrumb--reverse sg-mt-20px"
                        itemScope=""
                        itemType="https://schema.org/BreadcrumbList"
                      >
                        <li
                          itemProp="itemListElement"
                          itemScope=""
                          itemType="https://schema.org/ListItem"
                        >
                          <a itemProp="item" href="../index.html">
                            <span itemProp="name">Home</span>
                          </a>
                          <meta itemProp="position" content={1} />
                        </li>
                        <li
                          itemProp="itemListElement"
                          itemScope=""
                          itemType="https://schema.org/ListItem"
                        >
                          <span className="arrow" />
                          <span itemProp="name">About us</span>
                          <meta itemProp="position" content={2} />
                        </li>
                        <li
                          itemProp="itemListElement"
                          itemScope=""
                          itemType="https://schema.org/ListItem"
                        >
                          <span className="arrow" />
                          <span itemProp="name">Privacy Policy</span>
                          <meta itemProp="position" content={3} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="display-table">
                <div className="display-table-cell col-middle">
                  <div className="container">
                    <div className="row">
                      <div className="col-xs-24">
                        <h1 className="text-center text-white">
                          Privacy Policy
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="view-panel__gradient" />
            <div className="view-panel__visual">
              <div
                className="bg-image lazyload"
                data-bgset="https://staticassets.azureedge.net/assets/media/about-us/hero-privacy-policy-1.jpg [(max-width: 766px] |
											 https://staticassets.azureedge.net/assets/media/about-us/hero-privacy-policy-1.jpg [(max-width: 1022px] |
											 https://staticassets.azureedge.net/assets/media/about-us/hero-privacy-policy-1.jpg"
                data-alt="Privacy Policy"
              />
            </div>
          </div>
        </section>
        <section className="site-section">
          <div className="container sg-mt-30px hidden-xs privacy-policies-fix">
            <div className="row">
              <div className="col-xs-24">
                <div className="row ">
                  <div className="col-sm-24">
                    <h3>
                      <strong>Privacy Policy</strong>
                    </h3>
                    <h3>
                      <strong>1. Introduction</strong>
                    </h3>
                    <p className="mr">
                      We take your privacy seriously and are committed to
                      safeguarding your personal information at Emaar Malls
                      Group PJSC (which term includes all subsidiaries of Emaar
                      Malls Group PJSC). (“Company”, “we” or “us” or “Emaar”)
                      values you as our guest, respect your privacy and are
                      committed to protecting it through our compliance with
                      this policy.
                    </p>
                    <p className="mr">
                      This policy describes the sources of your personal
                      information that we may collect from you or that you may
                      provide to us, our purposes for collecting and processing
                      your information, and our practices for collecting, using,
                      maintaining, retaining, protecting and disclosing that
                      information.
                    </p>
                    <p className="mr">
                      Please read this policy carefully to understand our
                      policies and practices regarding your information and how
                      we will treat it. If you do not agree with our policies
                      and practices, your choice is not to use our platforms. By
                      accessing or using our platforms, you agree to this
                      privacy policy.
                    </p>
                    <p className="mr">
                      If you have any questions or concerns about this privacy
                      policy or your personal information, please contact us
                      using the details provided in Section – ‘How do you
                      contact us?’.
                    </p>
                    <h3>
                      <strong>2. What information we collect about you?</strong>
                    </h3>
                    <h5>
                      <strong>2.1 Personal information you give us </strong>
                    </h5>
                    <p className="mr">
                      The personal information we collect on or through our
                      platforms may include:
                    </p>
                    <ul>
                      <li>
                        Information that you provide by filling in forms. This
                        may include information provided at the time of
                        registering to use our Website, subscribing to our
                        service, registering for newsletter, using the ‘contact
                        us’ form, using the “Enquiry” forms, using the ‘WeCare’
                        form, using the careers portal or requesting further
                        services, register for ‘Emaar Gift Card’, entering a
                        contest or promotion sponsored by us, reporting a
                        problem with our Website or services. The personal
                        information that you provide us with may include your
                        name, email address, phone number, nationality,
                        residential address, identification proof (such as
                        Emirates ID, or Passport), credit card details, partner
                        issued membership ID etc
                      </li>
                      <li>
                        Records and copies of your correspondence (including
                        e-mail addresses), when you contact us.
                      </li>
                      <li>
                        Information that you provide when you contact us via
                        social media, web chats, ‘WhatsApp’ chat or directly
                        call our customer service team for general enquiries,
                        register complaints, request for any assistance/
                        services. Information may include your name, email ID,
                        contact number and call recordings (in case your call
                        our customer service team).
                      </li>
                      <li>
                        Information that you provide to surveys that trigger for
                        feedback to help us improve ourselves, details of
                        transactions you carry out through our Website and of
                        the fulfilment of your orders. You may be required to
                        provide financial information before placing an order or
                        making payment through our Website.
                      </li>
                      <li>
                        Information obtained through any system operated for the
                        security, safety and access management (CCTV, access
                        right system) when you visit our malls.
                      </li>
                      <li>
                        Vehicle registration number and details recorded through
                        Automatic Number Plate Recognition (ANPR) systems at our
                        Centre car parks and any other usage of our Parking
                        management systems.
                      </li>
                      <li>
                        Information recorded when you sign up for the internet
                        WIFI service when you visit our malls and any usage
                        details of WIFI including information about your device
                        and internet connection, including your IP address,
                        device ID, operating system, browser type and webpages
                        visited.
                      </li>
                      <li>
                        Information collected to contact you when you enter or
                        renew a tenancy contract on behalf of the Organization
                        you represent.
                      </li>
                      <li>
                        Information recorded during any events, games or
                        contests taking place in the mall.
                      </li>
                      <li>
                        Medical and health information of accidents and injuries
                        occurring in the mall as well as accident records and
                        other relevant information.
                      </li>
                      <li>
                        Information collected when you utilize any of our
                        services available for visitors of the Malls such as
                        porter service, delivery service, gift cards purchase,
                        etc.
                      </li>
                    </ul>
                    <h5>
                      <strong>2.2 Other information we collect </strong>
                    </h5>
                    <p className="mr">
                      There is other information we may collect that does not
                      directly reveal your specific identity or does not
                      directly relate to you as an individual. We may
                      automatically collect the following information when you
                      visit our website or use Emaar mobile applications or
                      utilize our services:
                    </p>
                    <ul>
                      <li>
                        Your equipment, browsing actions and patterns collected
                        automatically as you navigate through our websites.
                      </li>
                      <li>
                        Usage details, time of requests, browser types,
                        operating system, IP addresses and information collected
                        through cookies, web beacons and other tracking
                        technologies.
                      </li>
                      <li>
                        Details of your visits to our website, including traffic
                        data, location data, logs and other communication data
                        and the resources that you access and use on the
                        website.
                      </li>
                    </ul>
                    <p className="mr">
                      The information we collect automatically is statistical
                      data. It helps us to improve our Website and to deliver a
                      better and more personalized service by enabling us to:
                    </p>
                    <ul>
                      <li>Estimate our audience size and usage patterns.</li>
                      <li>
                        Store information about your preferences, allowing us to
                        customize our Website according to your individual
                        interests.
                      </li>
                      <li>
                        Speed up your searches and recognize you when you return
                        to our Website.
                      </li>
                    </ul>
                    <p className="mr">
                      The technologies we use for this automatic data collection
                      may include:
                    </p>
                    <ul>
                      <li>
                        <strong>Cookies:</strong> A cookie is a small piece of
                        data, which includes an identifier made of letters and
                        numbers that is sent by a web browser and stored on your
                        computer. Cookies are used as a reliable mechanism to
                        remember information about you, so your interaction with
                        our website is seamlessly simple. Cookies typically do
                        not contain any information that personally identify
                        you, but personal information that we store about you
                        may be linked to the information stored in and obtained
                        from cookies. Please{" "}
                        <a
                          className="text-underline"
                          href="cookie-policies.html"
                        >
                          click here
                        </a>{" "}
                        for details on Cookie Policy.
                      </li>
                      <li>
                        <strong>Flash Cookies:</strong> Certain features of our
                        Website may use local stored objects (or Flash cookies)
                        to collect and store information about your preferences
                        and navigation to, from and on our Website. Flash
                        cookies are not managed by the same browser settings as
                        are used for browser cookies.
                      </li>
                      <li>
                        <strong>Web Beacons:</strong> Pages of the Website and
                        our e-mails may contain small electronic files known as
                        web beacons (also referred to as clear gifs. pixel tags
                        and single-pixel gifs) that permit the Company, for
                        example, to count users who have visited those pages or
                        opened an e-mail and for other related website
                        statistics (for example, recording the popularity of
                        certain website content and verifying system and server
                        integrity).
                      </li>
                    </ul>
                    <p className="mr">
                      We use these cookies for the following purposes:
                    </p>
                    <ul>
                      <li>
                        To identify you when you visit our platforms and to help
                        you navigate through our platforms;
                      </li>
                      <li>
                        To help determine if you are logged into our platforms:
                      </li>
                      <li>
                        To store information about your preferences and to
                        personalize our platforms for you;
                      </li>
                      <li>
                        To secure and protect your user account and our
                        platforms; and
                      </li>
                      <li>
                        To analyse the use and performance of our platforms.
                      </li>
                    </ul>
                    <p className="mr">
                      We also use Bluetooth (BLE) Beacons, Wi-Fi, and similar
                      technologies: These technologies that may communicate with
                      your (mobile) device and identify its location if the
                      device has Bluetooth and/or Wi-Fi enabled and is properly
                      configured. We use location information to analyse the
                      footfall of the mall’s visitors, movement of visitors
                      through our mall to improve the shopping experience and
                      mall operations. We do not link this information to a
                      visitor or device, but instead measure general traffic
                      patterns. All such data shall be anonymized during storage
                      and retention.
                    </p>
                    <h5>
                      <strong>2.3 Information we receive from others</strong>
                    </h5>
                    <p className="mr">
                      We work closely with third parties and receive information
                      about you from them as well. We also collect personal
                      information from individuals who may refer you as a friend
                      to our loyalty schemes or products or services. We ask
                      these individuals to confirm that you are happy to hear
                      from us.
                    </p>
                    <h3>
                      <strong>
                        3. What are the sources of your personal data?
                      </strong>
                    </h3>
                    <p className="mr">
                      The sources of your personal data collected (collectively
                      referred as “Platforms” in this policy) may include
                      without limitation the following:
                    </p>
                    <ul>
                      <li>
                        <strong>Emaar websites:</strong> These are websites we
                        created for you that are operated by us, they include
                        websites operated by Emaar under our own domains and web
                        addresses (URLs) and our own micro-sites that are part
                        of a third-party social media networks such as Facebook,
                        Twitter, or Instagram.
                      </li>
                      <li>
                        <strong>
                          Emails, text messaging services, and other electronic
                          messages:
                        </strong>{" "}
                        These are the electronic text-based interaction between
                        you and Emaar.
                      </li>
                      <li>
                        <strong>Emaar mobile applications:</strong> These are
                        smartphone applications created for you and operated by
                        Emaar or one of our partners.
                      </li>
                      <li>
                        <strong>Emaar customer service:</strong> Any
                        communication between you and our customer service
                        center (e.g. phone, chatbot, email, etc.).
                      </li>
                      <li>
                        <strong>Offline registration forms:</strong> These
                        include printed registrations, surveys, or
                        questionnaires Emaar collects via registration forms,
                        contests, events, and various promotions.
                      </li>
                      <li>
                        <strong>Advertising:</strong> You might interact with
                        one of our or our partners’ advertisements on our
                        websites and apps and we might receive and collect this
                        information.
                      </li>
                      <li>
                        <strong>Career Portal:</strong> These includes
                        recruitment portal across Emaar.
                      </li>
                      <li>
                        <strong>Data we create:</strong> Emaar may also create
                        data about you, when you avail our services.
                      </li>
                      <li>
                        <strong>Data from other sources:</strong> These include
                        social media networks, market research agencies, Emaar
                        promotional partners, public sources, and data received
                        when we acquire other companies.
                      </li>
                      <li>
                        <strong>CCTV cameras:</strong> Security footage (image
                        and video) from CCTV cameras placed across the shopping
                        Centre.
                      </li>
                      <li>
                        <strong>Automatic Number Plate Registration:</strong>{" "}
                        Vehicle registration number and details recorded through
                        Automatic Number Plate Recognition (ANPR) systems at our
                        Centre car parks.
                      </li>
                      <li>
                        <strong>Emaar Loyalty Program:</strong> Once you
                        register for our loyalty program, we would collect and
                        process your personal information and transaction
                        history.
                      </li>
                      <li>
                        <strong>Emaar Public Wi-Fi:</strong> Any usage of the
                        Emaar public WIFI network.
                      </li>
                    </ul>
                    <p className="mr">
                      This policy does not apply to information collected:
                    </p>
                    <ul>
                      <li>
                        through any other means, including any other website
                        operated by any third party; or
                      </li>
                      <li>
                        by any third party, including through any application or
                        content (including advertising) that may link to the
                        Website.
                      </li>
                    </ul>
                    <h3>
                      <strong>
                        4. Why do we collect and how we use your personal
                        information?
                      </strong>
                    </h3>
                    <p className="mr">
                      We use personal information for several legitimate
                      interests, including providing and improving our services,
                      administering our relationship with you and our business.
                    </p>
                    <h5>
                      <strong>
                        4.1 To provide, develop and improve Emaar Services:
                      </strong>
                    </h5>
                    <p className="mr">
                      We use the personal information (such as device ID, IP
                      address, web-pages visited, geo-location details, CCTV
                      footage etc.,) we collect to perform essential business
                      operation purposes pursuant to our legitimate business
                      interests, for example to understand usage patterns (such
                      as foot traffic) within our malls; and to develop,
                      provide, improve, and personalize Emaar services. We do
                      not link this information to a visitor, shopper or device,
                      but instead measure general traffic patterns and other
                      analytics to help us improve our customer experience and
                      service offerings. All such data shall be anonymized
                      during storage and retention.
                    </p>
                    <h5>
                      <strong>
                        4.2 To manage your membership of our loyalty programs –
                        ‘U BY EMAAR’:
                      </strong>
                    </h5>
                    <p className="mr">
                      We encourage and invite you to join our loyalty program,
                      so that you may benefit from exclusive offers, discounts,
                      and promotions available to members. You may join our
                      loyalty program by registering online on the ‘U BY EMAAR’
                      webpage –{" "}
                      <a
                        className="text-underline"
                        href="https://www.ubyemaar.com/"
                        target="_blank"
                      >
                        https://www.ubyemaar.com.
                      </a>
                    </p>
                    <p className="mr">
                      Once you register for our loyalty program, we would
                      collect and process the following personal information:
                      name, telephone number, email ID, ‘U BY EMAAR’ ID and
                      transaction history. We would need to collect and use your
                      personal information to fulfil our contractual obligations
                      under the loyalty program.
                    </p>
                    <p className="mr">
                      As part of the loyalty program, we would also send our
                      members information regarding special offers, discounts
                      and promotions in pursuit of legitimate business
                      interests. In the event you prefer not to receive such
                      information, you may opt-out by following the instruction
                      provided Section – “How to opt-out?”.
                    </p>
                    <h5>
                      <strong>
                        4.3 To respond to any queries made by you:
                      </strong>
                    </h5>
                    <p className="mr">
                      We would need to collect and use the following personal
                      information: name, email address, phone number,
                      nationality and details of your enquiry.
                    </p>
                    <p className="mr">
                      In certain cases, your enquiry may relate to a specific
                      service request, for example: requesting a ‘shop and drop
                      service’. For such requests, we need to use your personal
                      information to help us respond to your request and to
                      fulfil our contractual obligations under the requested
                      service.
                    </p>
                    <p className="mr">
                      Additionally, when you call our customer service centre,
                      we would maintain a record of the communications,
                      including call recordings, in pursuit of our legitimate
                      business interest to monitor and improve the quality of
                      our guest support.
                    </p>
                    <h5>
                      <strong>
                        4.4 To provide you with information about services and
                        latest offers of Emaar Malls Group:
                      </strong>
                    </h5>
                    <p className="mr">
                      It is in our legitimate business interests to send you
                      information about Emaar’s services and latest offers. We
                      use your personal information such as email ID and/ or
                      contact number to share information relating to Emaar’s
                      services and latest offers that may be of interest to you.
                      In the event you prefer not to receive such information,
                      you may opt-out by following the instruction provided
                      Section – “How to opt-out?”.
                    </p>
                    <h5>
                      <strong>
                        4.5 To personalize the information, we send you:
                      </strong>
                    </h5>
                    <p className="mr">
                      We may combine the information we receive and collect
                      about you to better understand your interests and
                      preferences so that we can provide you with an experience
                      that is tailored to those interests and preferences. For
                      example, we may do this by notifying you of any events
                      conducted in the mall, or sending you personalized offers,
                      discounts or promotions by our mobile application, or
                      email (where you have agreed to receive our emails), or
                      advertising content that is relevant to your interests. In
                      the event you prefer not to receive such information, you
                      may opt out by following the instruction provided Section
                      – “How to opt out?”.
                    </p>
                    <h5>
                      <strong>4.6 To facilitate Advertisements:</strong>
                    </h5>
                    <p className="mr">
                      Emaar uses the personal information you provide us for a
                      commercial purpose to help us tailor and show
                      advertisements to you. We may partner with third parties
                      to help us display relevant advertising and to manage our
                      advertising across multiple channels including social
                      media platforms. Our third-party partners may use cookies
                      and non-cookie-based technologies to help us show you
                      advertising based upon your browsing activities and
                      interests. In the event you wish to opt-out, you may do so
                      by following the instruction provided Section – “How to
                      opt out?” and by following the opt-out instructions
                      provided in social media platforms such as Facebook and
                      Instagram that you may use.
                    </p>
                    <h5>
                      <strong>
                        4.7 To enable you to avail special offers and benefits
                        from our business partners:
                      </strong>
                    </h5>
                    <p className="mr">
                      We have strong and longstanding partnerships with business
                      partners across sectors including Travel, Entertainment,
                      and Lifestyle. Our partnerships allow you to avail special
                      benefits and offers from our business partners. For
                      example, Emirates Skyward members can avail Skywards Miles
                      when they shop (above a pre-determined value) at one of
                      the outlets available within our malls.
                    </p>
                    <p className="mr">
                      To help you avail of these benefits, we would need to
                      collect and share the following personal information:
                      name, Partner issued Membership ID and transaction
                      history, with our business partners. We would need to
                      collect and process your personal information to fulfil
                      our contractual obligations towards our business partners
                      and to ensure you avail the best of benefits and offers.
                    </p>
                    <h5>
                      <strong>4.8 To manage your Emaar Gift Card:</strong>
                    </h5>
                    <p className="mr">
                      Emaar Gift Card is like a cash voucher that can be loaded
                      only once with a set amount of funds on. It is accepted as
                      a payment mechanism at outlets across Emaar, which means
                      that the gift card can be used at multiple locations
                      across the Emaar group. For customers availing the Emaar
                      Gift Card, we collect and use personal data such as Name,
                      Email, Contact Details, Date of Birth, Nationality,
                      Payment Card details and transactions (carried out using
                      the Emaar Gift Card). We collect and use your personal
                      information to fulfil our contractual obligations towards
                      you and to ensure you avail the best of benefits and
                      offers.
                    </p>
                    <h5>
                      <strong>
                        4.9 To request your feedback, and to respond to
                        complaints:
                      </strong>
                    </h5>
                    <p className="mr">
                      Your satisfaction is our utmost priority and we
                      proactively take necessary steps to ensure you are well
                      satisfied with our services.
                    </p>
                    <p className="mr">
                      To help us respond to your feedback or complaints, we
                      would need to collect the following personal information:
                      name, email ID, transaction details (such as service you
                      availed at the mall) and your feedback/ complaints in the
                      “We Care submission form”, through a survey conducted
                      within our malls or after the completion of your call with
                      our customer service team.
                    </p>
                    <p className="mr">
                      We collect and use your personal information in pursuit of
                      our legitimate business interests to address your concerns
                      and to take necessary steps towards continual improvement.
                      In the event you prefer not to receive such information,
                      you may opt out by following the instruction provided
                      Section – “How to opt out?”.
                    </p>
                    <h5>
                      <strong>
                        4.10 To establish a working relationship with our
                        Tenants and Business Partners:
                      </strong>
                    </h5>
                    <p className="mr">
                      We collect/ receive personal information about you through
                      one of the following channels:
                    </p>
                    <ul>
                      <li>
                        When you send us an enquiry for Tenancy or if you were
                        previously engaged under a Tenancy arrangement with us.
                      </li>
                      <li>
                        When you participate in any of our sales, marketing and
                        thought leadership events organized or hosted by Emaar;
                      </li>
                      <li>
                        When our sales executive or business leaders visit
                        events organized by external organizations (including
                        the company you represent);
                      </li>
                      <li>
                        We collect from our sales executives or business leaders
                        who share a professional/ personal relationship with
                        you; or
                      </li>
                      <li>
                        We collect from publicly available information that you
                        have shared on professional networking platforms such as
                        LinkedIn.
                      </li>
                    </ul>
                    <p className="mr">
                      We collect the following personal information: name, email
                      ID, contact number, designation, and the company you
                      represent. We use this information for the purposes,
                      listed below, in pursuit of our legitimate business
                      interests:
                    </p>
                    <ul>
                      <li>
                        To share information relating to Emaar services, and
                        possible tenancy opportunities;
                      </li>
                      <li>
                        To invite you to future events, that may be of interest
                        to you and/ or the company you represent; and
                      </li>
                      <li>
                        To establish a working relationship with the company you
                        represent.
                      </li>
                    </ul>
                    <h5>
                      <strong>
                        4.11 To enter into, renew or fulfil service contracts
                        with our Tenants and Business Partners:
                      </strong>
                    </h5>
                    <p className="mr">
                      We collect and use personal information such as name,
                      email ID, contact number and in certain cases passport/
                      government ID proof to facilitate the performance of the
                      tenancy contract between Emaar and the Organization you
                      represent. We may also collect personal information (such
                      as Name, contact details, passport/ government ID etc.,)
                      of the employees of your Organization that would be
                      stationed within our malls to facilitate security pass
                      clearance pursuant to our legitimate business interest and
                      to fulfil our contractual obligations.
                    </p>
                    <h5>
                      <strong>
                        4.12 To facilitate email campaigns carried out by our
                        sales and marketing departments:
                      </strong>
                    </h5>
                    <p className="mr">
                      As part of our email campaigns, we track whenever you
                      receive, open, click a link, or download any attachments
                      from an e-mail you receive from Emaar. We will carry out
                      automated profiling of such information to evaluate your
                      interest in our service offerings or promotions. This
                      processing will enable us to identify and target potential
                      guests or business partners, tailor our marketing and
                      provide you with relevant and timely content based on your
                      interests, in pursuit of our legitimate business
                      interests. In the event you prefer not to receive such
                      information, you may opt out by following the instruction
                      provided Section – “How to opt out?”.
                    </p>
                    <h5>
                      <strong>
                        4.13 To comply with legal requirements and exercise or
                        defend legal claims:
                      </strong>
                    </h5>
                    <p className="mr">
                      We may need to process and retain your personal
                      information to comply with legal requirements to which we
                      are subject (for example in relation to licensing, health
                      and safety). It is in our legitimate interests to process
                      personal information for the purposes of exercising and
                      defending legal claims. Processing personal information
                      may also be necessary to ensure compliance with the
                      relevant legal and regulatory obligations.
                    </p>
                    <h5>
                      <strong>
                        4.14 To process your payments and protect against
                        fraudulent transactions:
                      </strong>
                    </h5>
                    <p className="mr">
                      We may need to process your personal information to keep
                      your payments safe and secure and protect against
                      fraudulent transactions. It is in our legitimate interests
                      to process personal information to keep your payments
                      secure and to prevent fraud. We may also require your
                      personal information to send payment reminders and legal
                      intimations in case of overdue payments. Processing
                      personal information in this way may also be necessary to
                      ensure compliance with the relevant legal and regulatory
                      obligations.
                    </p>
                    <h5>
                      <strong>
                        4.15 To comply with any opt-out or do not disturb
                        requests we receive from you:
                      </strong>
                    </h5>
                    <p className="mr">
                      We understand that you may not prefer for us to contact
                      you with any offers, promotions or details of our products
                      and services. In the event you opt-out, we may be required
                      to maintain information such as name, email ID/contact
                      number and the subscription(s) that you have opted out of
                      to ensure compliance with your requests. Also, in the
                      future, if you wish to hear from us, you may at any time,
                      contact us to opt-in and we would happy to keep you posted
                      about our latest offers, promotions and/ or details of our
                      products and services.
                    </p>
                    <h5>
                      <strong>
                        4.16 To manage and administer any of our events and
                        competitions which you enter:
                      </strong>
                    </h5>
                    <p className="mr">
                      We collect and use the personal data provided by you when
                      you participate in any of the events/ competitions hosted
                      within our malls. The information may include name, email
                      ID, contact details and government ID proof/ passport
                      details. We use the personal information in pursuant to
                      our contractual obligations and to ensure we can
                      successfully process any awards/ gifts/ offers that you
                      may win in such events.
                    </p>
                    <p className="mr">
                      We may also collect and use personal data of event
                      management representatives to plan and conduct events
                      within our malls. We collect and use the personal
                      information to maintain contact, co-ordinate support and
                      to obtain necessary local clearance to manage and
                      administer the event.
                    </p>
                    <h5>
                      <strong>
                        4.17 To maintain security within our malls and keep the
                        public safe:
                      </strong>
                    </h5>
                    <p className="mr">
                      We may need to process your personal information obtained
                      through any system operated for the security, safety and
                      access management (CCTV, access rights system, Automatic
                      Number Plate Recognition (ANPR)) when you visit our malls
                      or any of its stores. Security footage may include access
                      logs, image, video, audio, license plates, etc. from any
                      of the security systems placed across our malls. We
                      collect and process such information pursuant to our
                      legitimate business interest to maintain security and
                      ensure public safety and to ensure compliance with local
                      laws and regulations.
                    </p>
                    <h5>
                      <strong>
                        4.18 To comply with health and safety obligations:
                      </strong>
                    </h5>
                    <p className="mr">
                      We may be required to process your medical information or
                      any other health related information in the event of an
                      accident or injury at our malls. It is our legitimate
                      interest for the management of accidents and injuries
                      occurring in our malls. This information would also be
                      required for the improvement of the accident prevention
                      system (including fire protection and prevention against
                      foreseeable natural and technological risks). It could
                      also possibly be required for legal purposes or to prepare
                      for and defend against legal claims.
                    </p>
                    <h5>
                      <strong>4.19 To manage vehicle parking:</strong>
                    </h5>
                    <p className="mr">
                      We would be required to record license plates through
                      Automatic Number Plate Recognition (ANPR) systems and
                      other vehicle details as well as parking records where it
                      is in operation at our malls car parking facilities. These
                      would be required for the management of our parking system
                      as well as to maintain security within it and fulfil our
                      contractual obligations effectively.
                    </p>
                    <h3>
                      <strong>5. How to opt out?</strong>
                    </h3>
                    <ul>
                      <li style={{ listStyleType: "decimal" }}>
                        <strong>Email:</strong> You can click on the unsubscribe
                        link provided in the email you receive from us.
                      </li>
                      <li style={{ listStyleType: "decimal" }}>
                        <strong>SMS:</strong> you can follow the instruction
                        provided in the messages you receive from us
                      </li>
                      <li style={{ listStyleType: "decimal" }}>
                        <strong>Applications:</strong> You may use the “opt out”
                        option provided within the Emaar Mall Group applications
                      </li>
                      <li style={{ listStyleType: "decimal" }}>
                        <strong>Contact us:</strong> You may contact us using
                        the details provided in Section – ‘How do you contact
                        us?’.
                      </li>
                    </ul>
                    <h3>
                      <strong>
                        6. Who might we share this information with?
                      </strong>
                    </h3>
                    <p className="mr">
                      We may disclose personal information that we collect, or
                      you provide as described in this privacy policy:
                    </p>
                    <ul>
                      <li>
                        We may share your personal information with any member
                        of the Emaar, including subsidiaries, affiliates and
                        holding companies, to enable you to request our
                        services, to process your payments, understand your
                        preferences, send you information about products and
                        services that you may have expressed interest and
                        conduct the other activities described in this privacy
                        policy. Such group companies are located outside the
                        European Economic Area (“EEA”). Please visit our{" "}
                        <a
                          className="text-underline"
                          href="https://www.emaar.com/en/international-listing"
                        >
                          website
                        </a>{" "}
                        to see a list of locations within our corporate group.
                      </li>
                      <li>
                        We may use carefully selected third parties to perform
                        services on our behalf or to assist us with the
                        provision of services to you. For example, we may engage
                        cloud service providers, IT service providers and other
                        third parties to provide concierge services, security
                        services, brand promotions, marketing, advertising,
                        communications, to personalize and optimize our service,
                        to analyze and enhance data (including data about users’
                        interactions with our services), and to provide legal,
                        accounting, insurance, audit and other professional
                        services. While providing such services, these third
                        parties may have access to your personal information.
                      </li>
                      <li>
                        We may share your personal information with select
                        business partners in sectors including travel,
                        entertainment, lifestyle, and other industries to enable
                        our guests to avail special offers. For example, our
                        partnership with our airline partner enables our guests
                        to earn miles for every purchase (exceeding a specific
                        value) at our Emaar Mall outlets.
                      </li>
                      <li>
                        Where required or permitted by law, personal information
                        may be provided to others, such as regulators and law
                        enforcement agencies.
                      </li>
                      <li>
                        We may share to a buyer or other successor in the event
                        of a merger, divestiture, restructuring, reorganization,
                        dissolution or other sale or transfer of some or all the
                        Company’s assets, whether as a going concern or as part
                        of bankruptcy, liquidation or similar proceeding, in
                        which personal information held by the Company about our
                        Website users is among the assets transferred.
                      </li>
                      <li>
                        We may share with government or regulatory request to
                        comply with any court order, law or legal process
                      </li>
                      <li>
                        We may share with other companies and organizations for
                        the purposes of fraud protection and credit risk
                        reduction if we believe disclosure is necessary or
                        appropriate to protect the rights, property, or safety
                        of the Company, our customers or others.
                      </li>
                    </ul>
                    <p className="mr">
                      The personal information that we collect from you may be
                      transferred to, and stored at, a destination outside the
                      EEA (for example, in Dubai). It may also be processed by
                      staff operating outside the EEA who works for us or for
                      one of our third-party vendors. We will take all steps
                      that are reasonably necessary to ensure that your personal
                      information is treated securely and in accordance with
                      this privacy policy and applicable data protection laws,
                      including, where relevant, entering EU standard
                      contractual clauses (or equivalent measures) with the
                      party outside the EEA receiving the personal information.
                      We carry out such transfers to facilitate the performance
                      of our contract and in pursuit of our legitimate business
                      interests of helping us serve you better. We have
                      implemented adequate safeguards to protect and secure the
                      information involved in such transfers. Where we transfer
                      personal information outside of EU, we either transfer
                      personal information to countries that provide an adequate
                      level of protection (as determined by the European
                      Commission) or we have appropriate safeguards in place.
                      Appropriate safeguards to cover these transfers are in the
                      form of standard contractual/data protection clauses
                      adopted by the European Commission.
                    </p>
                    <p className="mr">
                      If you would like more information on protection measures
                      and transfer mechanisms, please contact us using the
                      details provided in Section – ‘How do you contact us?’.
                    </p>
                    <h3>
                      <strong>
                        7. How long do we keep information about you?{" "}
                      </strong>
                    </h3>
                    <p className="mr">
                      We will only keep your personal information for as long as
                      is reasonably necessary taking into consideration our need
                      to answer queries or resolve problems, any other purpose
                      outlined above or to comply with legal requirements under
                      applicable law(s). This means that we may retain your
                      personal information for a reasonable period, for example,
                      till the end of the leasing contract with the organization
                      you represent, or after your query has been addressed,
                      CCTV footage is held for a period of 30 days before being
                      deleted unless legitimate access is required (for example
                      by the police). In certain cases, we may retain your
                      personal information for a longer period where extended
                      retention periods are required by law or regulation and to
                      establish, exercise or defend our legal rights. We will
                      ensure that it is disposed in a secure manner when it’s no
                      longer needed.
                    </p>
                    <p className="mr">
                      For more information on where and how long your personal
                      information is stored, and for more information on your
                      rights of erasure and portability, please contact us using
                      the details provided in Section – ‘How do you contact
                      us?’.
                    </p>
                    <h3>
                      <strong>8. How secure is your information? </strong>
                    </h3>
                    <p className="mr">
                      Your personal data security is an important concern to us.
                      We provide the utmost care in secure transmission of your
                      personal information from your computer, smartphone, and
                      other electronic devices to our servers. We use industry
                      security standards to safeguard the confidentiality of
                      your information (e.g. firewalls, Transport Security Layer
                      (“TLS”) etc.) and to make sure that your personal
                      information is secure with us.
                    </p>
                    <p className="mr">
                      We have implemented and maintained appropriate technical
                      and organizational security measures, policies and
                      procedures to protect your personal information from the
                      accidental loss, unauthorized access, use, alteration and
                      disclosure. All information you provide to us is stored on
                      our secure servers behind firewalls. All payment
                      transactions are encrypted using TLS technology. Ex:
                      Measures we take includes:
                    </p>
                    <ul>
                      <li>
                        Placing confidentiality requirements on our staff and
                        service providers;
                      </li>
                      <li>
                        Restriction of access to your personal information to
                        employees and third parties strictly on a need to know
                        basis, such as to respond to your enquiry or request;
                      </li>
                      <li>
                        Destroying or anonymizing personal information if it is
                        no longer needed for the purposes for which it was
                        collected; and
                      </li>
                      <li>
                        Using secure communication channels for transmitting
                        personal data.
                      </li>
                    </ul>
                    <p className="mr">
                      The safety and security of your information also depend on
                      you. Where we have given you (or where you have chosen) a
                      password for access to certain parts of our Website, you
                      are responsible for keeping this password confidential. We
                      ask you not to share your password with anyone.
                    </p>
                    <p className="mr">
                      Unfortunately, the transmission of information via the
                      internet is not completely secure. Although we do our best
                      to protect your personal information, we cannot guarantee
                      the security of your personal information transmitted to
                      our Website. Any transmission of personal information is
                      at your own risk. We are not responsible for circumvention
                      of any privacy settings or security measures contained on
                      the Website.
                    </p>
                    <h3>
                      <strong>9. What are your rights? </strong>
                    </h3>
                    <p className="mr">
                      If you are subject to laws that provide you with such
                      rights, you may have certain rights in relation to your
                      personal information given below.
                    </p>
                    <p className="mr">
                      If you wish to access any of these rights, we may ask you
                      for additional information to confirm your identity and
                      for security purposes, before disclosing personal
                      information to you. We reserve the right to charge a fee
                      where permitted by law, for example, if your request is
                      manifestly unfounded or excessive.
                    </p>
                    <p className="mr">
                      If you would like to access the below rights, you may
                      contact us by completing a form on our{" "}
                      <a
                        className="text-underline"
                        href="https://www.emaar.com/gdpr/dsr.aspx"
                      >
                        website
                      </a>{" "}
                      or by mailing us at{" "}
                      <a href="mailto:customer.privacy@emaar.ae" target="_top">
                        customer.privacy@emaar.ae
                      </a>{" "}
                      or sending us a communication to Data Privacy Office, PO
                      Box 9440, Dubai, United Arab Emirates . We shall aim to
                      comply with all requests within reasonable time in-line
                      with applicable laws. We may not always be able to fully
                      address your request, for example,
                    </p>
                    <ul>
                      <li>
                        if it would impact the confidentiality we owe to others,
                        or
                      </li>
                      <li>
                        if we are legally entitled to deal with the request in a
                        different way. or
                      </li>
                      <li>
                        if your request involves deletion of information that
                        are required to comply with the legal requirements.
                      </li>
                    </ul>
                    <p className="mr">
                      We will make every reasonable effort to honour your
                      request in-line with applicable laws. In the event we
                      require additional time due to the complexity of the
                      request, we shall promptly inform you of the same.
                    </p>
                    <h5>
                      <strong>The right to access </strong>
                    </h5>
                    <p className="mr">
                      You have the right to obtain confirmation as to whether we
                      process personal data about you, receive a copy of your
                      personal data held by us, and obtain certain other
                      information about how and why we process your personal
                      data (like the information provided in this privacy
                      statement).
                    </p>
                    <h5>
                      <strong>The right to rectification </strong>
                    </h5>
                    <p className="mr">
                      You have the right to request for your personal data to be
                      amended or rectified where it is inaccurate (for example,
                      if you change your name or address) and to have incomplete
                      personal data completed.
                    </p>
                    <h5>
                      <strong>
                        The right to restrict processing of personal data
                      </strong>
                    </h5>
                    <p className="mr">
                      You have the right to restrict our processing of your
                      personal data in the following cases:
                    </p>
                    <p className="mr">
                      Temporarily for a period when you have contested for the
                      accuracy of the personal data; Temporarily for a period
                      when you have objected for the legitimate interest
                      identified by us, Your personal data have been unlawfully
                      processed and you request the restriction of processing
                      instead of deletion;
                    </p>
                    <p className="mr">
                      The personal data are no longer necessary in relation to
                      the purposes for which they were collected and processed
                      but the personal data are required by you to establish,
                      exercise or defend legal claims;
                    </p>
                    <p className="mr">
                      We can continue to use your personal information following
                      a request for restriction, where:
                    </p>
                    <ul>
                      <li>we have your consent; or</li>
                      <li>to establish, exercise or defend legal claims; or</li>
                      <li>
                        to protect the rights of another natural or legal
                        person.
                      </li>
                    </ul>
                    <h5>
                      <strong>
                        The right to erasure (also known as ‘the right to be
                        forgotten’)
                      </strong>
                    </h5>
                    <p className="mr">
                      You have the right to obtain deletion of your personal
                      data in the following cases:
                    </p>
                    <ul>
                      <li>
                        The personal data are no longer necessary in relation to
                        the purposes for which they were collected and
                        processed;
                      </li>
                      <li>
                        Our lawful basis for processing is consent, you withdraw
                        consent and we have no other lawful basis for the
                        processing;
                      </li>
                      <li>
                        Our lawful basis for processing is that the processing
                        is necessary for a legitimate interest pursued by us,
                        you object to our processing and we do not have
                        overriding legitimate grounds;
                      </li>
                      <li>
                        You object to our processing for direct marketing
                        purposes;
                      </li>
                      <li>
                        Your personal data have been unlawfully processed; and
                      </li>
                      <li>
                        Your personal data must be erased to comply with a legal
                        obligation under EEA to which we are subject.
                      </li>
                    </ul>
                    <p className="mr">
                      We are not required to comply with your request to erase
                      personal information if the processing of your personal
                      information is necessary:
                    </p>
                    <ul>
                      <li>For compliance with a legal obligation; or</li>
                      <li>
                        For the establishment, exercise or defence of legal
                        claims.
                      </li>
                    </ul>
                    <h5>
                      <strong>
                        The right to object to the processing of personal data
                      </strong>
                    </h5>
                    <p className="mr">
                      You have the right to object to our processing of your
                      personal data in the following cases:
                    </p>
                    <p className="mr">
                      our lawful basis for processing is that the processing is
                      necessary for a legitimate interest pursued by us; and our
                      processing for direct marketing purposes.
                    </p>
                    <h5>
                      <strong>The right to data portability</strong>
                    </h5>
                    <p className="mr">
                      You have the right to receive your personal data provided
                      to us and have the right to send the data to another
                      organization (or ask us to do so if technically feasible)
                      where our lawful basis for processing the personal data is
                      consent or necessity for the performance of our contract
                      with you and the processing is carried out by automated
                      means.
                    </p>
                    <h5>
                      <strong>
                        The right to withdraw consent at any time (where
                        processing is based on consent)
                      </strong>
                    </h5>
                    <p className="mr">
                      Where we process personal data based on consent,
                      individuals have a right to withdraw consent at any time.
                    </p>
                    <h5>
                      <strong>
                        The right to lodge a complaint with a supervisory
                        authority
                      </strong>
                    </h5>
                    <p className="mr">
                      If you are not content with how we manage your personal
                      information, you can lodge a complaint with a supervisory
                      authority in your country of residence, place of work or
                      the country in which an alleged infringement of data
                      protection law has occurred within the EEA. We sincerely
                      hope that you will never need to, but if you do want to
                      complain about our use of personal data, you can contact
                      us in one of the ways mentioned in Section 11 – ‘How do
                      you contact us?’. We will look into and respond to any
                      complaints we receive.
                    </p>
                    <h3>
                      <strong>10. Updates on Policy</strong>
                    </h3>
                    <p className="mr">
                      This policy may change from time to time. Your continued
                      use of our platforms after we make changes is deemed to be
                      acceptance of those changes, so please check the policy
                      periodically for updates.
                    </p>
                    <h3>
                      <strong>11. How do you contact us? </strong>
                    </h3>
                    <p className="mr">
                      In case of any queries related to this policy, you can
                      contact our Data Privacy Office in one of the following
                      ways.
                    </p>
                    <ul>
                      <li style={{ listStyleType: "decimal" }}>
                        Email us at{" "}
                        <a
                          className="text-underline"
                          href="mailto:customer.privacy@emaar.ae"
                          target="_top"
                        >
                          customer.privacy@emaar.ae
                        </a>
                      </li>
                      <li style={{ listStyleType: "decimal" }}>
                        Call us at <a href="tel:80036227">800-EMAAR (36227)</a>
                      </li>
                      <li style={{ listStyleType: "decimal" }}>
                        Send us a communication to the Data Privacy Office,
                        Emaar Malls Group PJSC, P.O. Box 191741, Dubai, United
                        Arab Emirates.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>


<Footer/>
        </>
    )
}

export default Privacy