import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Helmet } from 'react-helmet';
const About = () => {
    return (
        <>
            <Helmet>
                <title>About us - Westgate Shopping Mall</title>
                <meta name="description" content={`Westgate Shopping Mall - Nairobi’s Premier Shopping Mall was opened to the
public in 2007 and is located in the vibrant heart of Westlands, Nairobi an area
that is both commercial and residential. Westlands has the highest concentra-
tion of middle to upper-class shoppers and is growing towards being the city’s
main hub. Westgate Shopping Mall conforms to high international standards in
the mall industry attracting highly cosmopolitan and sophisticated clientele
and reaching a well-defined demographic audience.`} />
                <meta property="og:title" content={``} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={``} />
                <meta property="og:image" content={``} />
                <meta property="og:image:height" content="1637" />
                <meta property="og:image:width" content="2560" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:url" content={``} />
                <meta name="twitter:title" content={``} />
                <meta name="twitter:description" content={`Westgate Shopping Mall - Nairobi’s Premier Shopping Mall was opened to the
public in 2007 and is located in the vibrant heart of Westlands, Nairobi an area
that is both commercial and residential. Westlands has the highest concentra-
tion of middle to upper-class shoppers and is growing towards being the city’s
main hub. Westgate Shopping Mall conforms to high international standards in
the mall industry attracting highly cosmopolitan and sophisticated clientele
and reaching a well-defined demographic audience.`} />
                <meta name="twitter:image:src" content={``} />
                <meta name="twitter:image:width" content={2560} />
                <meta name="twitter:image:height" content={1637} />
            </Helmet>
            <Header />
            <div className="constant-dots">
                <div className="site">
                    <div className="site-content">
                        <div className="site-sections">

                            <section className="site-section">
                                {/* @view-panel */}
                                <div className="view-panel view-panel--xs">
                                    <div className="view-panel__abs">
                                        <div className="view-panel__top hidden-xs">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-24">
                                                        <ul
                                                            className="breadcrumb breadcrumb--reverse sg-mt-20px"
                                                            itemScope=""
                                                            itemType="https://schema.org/BreadcrumbList"
                                                        >
                                                            <li
                                                                itemProp="itemListElement"
                                                                itemScope=""
                                                                itemType="https://schema.org/ListItem"
                                                            >
                                                                <a itemProp="item" href="/en">
                                                                    <span itemProp="name">Home</span>
                                                                </a>
                                                                <meta itemProp="position" content={1} />
                                                            </li>
                                                            <li
                                                                itemProp="itemListElement"
                                                                itemScope=""
                                                                itemType="https://schema.org/ListItem"
                                                            >
                                                                <span className="arrow" />
                                                                <span itemProp="name">About us</span>
                                                                <meta itemProp="position" content={2} />
                                                            </li>
                                                            <li
                                                                itemProp="itemListElement"
                                                                itemScope=""
                                                                itemType="https://schema.org/ListItem"
                                                            >
                                                                <span className="arrow" />
                                                                <span itemProp="name">About Westgate Shoping Mall</span>
                                                                <meta itemProp="position" content={3} />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="display-table">
                                            <div className="display-table-cell col-middle">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-xs-24">
                                                            <h1 className="text-center text-white">
                                                                {" "}
                                                                About Westgate Shoping Mall
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="view-panel__gradient" />
                                    <div className="view-panel__visual">
                                        <div
                                            data-bgset="./assets/images/westgate-shopping-mall-exterior.jpg"
                                            className="bg-image lazyloaded"
                                            data-alt="Entertain"
                                            style={{
                                                backgroundImage:
                                                    'url("./assets/images/westgate-shopping-mall-exterior.jpg")'
                                            }}
                                        >
                                            <picture style={{ display: "none" }}>
                                                <source
                                                    data-srcset="./assets/images/westgate-shopping-mall-exterior.jpg"
                                                    sizes="1913px"
                                                    srcSet="./assets/images/westgate-shopping-mall-exterior.jpg"
                                                />
                                                <img
                                                    alt="Entertain"
                                                    data-parent-fit="cover"
                                                    className="lazyautosizes lazyloaded"
                                                    data-sizes="auto"
                                                    sizes="1913px"
                                                />
                                            </picture>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="site-section">
                                <div className="container sg-mt-30px">
                                    <div className="row">
                                        <div className="col-xs-24">
                                            <div className="row ">
                                                <div className="col-sm-24">
                                                    <center>
                                                        <strong>WESTGATE, NAIROBI’S PREMIER SHOPPING MALL
                                                        </strong>
                                                    </center>
                                                    <p>
                                                        <br />
                                                        <br /> <strong>About</strong>
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        Westgate Shopping Mall - Nairobi’s Premier Shopping Mall was opened to the
                                                        public in 2007 and is located in the vibrant heart of Westlands, Nairobi an area
                                                        that is both commercial and residential. Westlands has the highest concentra-
                                                        tion of middle to upper-class shoppers and is growing towards being the city’s
                                                        main hub. Westgate Shopping Mall conforms to high international standards in
                                                        the mall industry attracting highly cosmopolitan and sophisticated clientele
                                                        and reaching a well-defined demographic audience.
                                                    </p>
                                                    <p>Westgate is a popular destination for families, the young and old alike,
                                                        business people and tourists and is open early until late in the night, 7 days a
                                                        week including on public holidays.</p>
                                                    <p>
                                                        <br />
                                                        <br /> <strong>Retail outlets</strong>
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        Westgate is a multifunctional mall with retail outlets trading in food and
                                                        beverage, fashion and beauty, entertainment, sporting and fitness, financial
                                                        services, décor, toys, electronics and mobile services, courier services, dry
                                                        cleaning services, books and stationery and photography services among other
                                                        trades.
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        These include flagship stores of some globally renowned brands
                                                        including a Carrefour supermarket, fashion brands like Nike, Mango, Boss,
                                                        Lacoste, Adidas, Puma, Springfield, Women’secret, Pretty Ballerinas, Swarovski
                                                        and many more.
                                                    </p>
                                                    <p>
                                                        <br /> <strong>Restaurants</strong>
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        Under the food and beverage options, Westgate is host to some of the most
                                                        popular eateries (both restaurants and global fast food outlets) in Nairobi
                                                        including The Grilll Shack, Art-Caffe’, Bukhara, Ocean Basket, Pizza Hut,
                                                        Subway, KFC, Pizza Inn, Chicken Inn to name a few
                                                    </p>
                                                    <p>&nbsp;</p>

                                                    <p>
                                                        <br /> <strong>Entertainment</strong>
                                                    </p>
                                                    <p>
                                                        On the entertainment front, Westgate offers the best entertainment facilities
                                                        within a mall including Westgate Cinema that has 6-screens of which 2 are
                                                        dedicated VIP screens, Strikez @ Westgate a gaming arcade that includes a
                                                        10-lane state-of-the-art bowling alley, pool tables, electronic table games and a
                                                        restaurant, W Exclusive Wellness and Fitness centre that is a world class
                                                        luxurious gym with state-of–the-art facilities and features an indoor heated
                                                        swimming pool and a jungle themed kid's play area that is sure to keep the
                                                        little ones fully engaged.
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        <br /> <strong>Popup Market</strong>
                                                    </p>
                                                    <p>
                                                        The one-of-its-kind uniquely styled Westgate Pop-up Market houses various
                                                        vendors and specialty shops offering a vibrant range of Kenyan hand made
                                                        products, gift items and refreshments.
                                                    </p>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div
                    className="site-collapse-menu site-collapse-menu--main hidden"
                    id="site-collapse-menu"
                    style={{ marginTop: 50 }}
                >
                    <nav>
                        <ul className="js--accordion" role="tablist">
                            <li className="site-collapse-menu-item">
                                <a href="/en/whats-new">What's New</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/shop">Shop</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/dine">Dine</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/stay">Stay</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/entertain">Entertain</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a className="site-collapse-menu__level-0" href="#mm-5">
                                    Plan Your Visit
                                </a>
                                <ul className="collapse" id="mm-5">
                                    <li className="site-collapse-menu-item">
                                        <a href="/en/plan-your-visit/opening-hours">Opening hours</a>
                                    </li>
                                    <li className="site-collapse-menu-item">
                                        <a href="/en/plan-your-visit/getting-here">Getting here</a>
                                    </li>
                                    <li className="site-collapse-menu-item">
                                        <a href="/en/u-by-emaar">U By Emaar</a>
                                    </li>
                                    <li>
                                        <a href="/en/plan-your-visit/parking">Parking</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/services">Services</a>
                            </li>
                        </ul>
                    </nav>
                    <nav className="inverse">
                        <ul>
                            <li className="site-collapse-menu-item">
                                <a href="/en/fashion-avenue">Fashion avenue</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/fountain-view">Fountain views</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/campaign/chinatown">Chinatown</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/campaign/souk-al-bahar">Souk Al Bahar</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/offers">Offers</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/events">Events</a>
                            </li>
                        </ul>
                    </nav>
                    <nav>
                        <ul className="js--accordion" role="tablist">
                            <li className="site-collapse-menu-item menu_lan" id="language-selecter">
                                <a href="/ar/about-us/about-the-dubai-mall">العربية</a>
                            </li>
                            <li className="site-collapse-menu-item"></li>
                        </ul>
                    </nav>
                </div>
                <div className="visible-xs sfooter-menu">
                    <div className="site-collapse-menu" id="site-collapse-menu-footer">
                        <nav>
                            <ul>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/whats-new">What's New</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/shop">Shop</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/dine-directory">Dine</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/stay">Stay</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/entertain">Entertain</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/plan-your-visit/opening-hours">Plan Your Visit</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/services">Services</a>
                                </li>
                            </ul>
                        </nav>
                        <nav className="inverse">
                            <ul>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/fashion-avenue"> Fashion Avenue</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/offers">Offers</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/events"> Events</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default About