import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Header from "../components/header"
import Footer from "../components/footer"
import { Helmet } from 'react-helmet'
// Function to generate an SEO-friendly slug from a given string
const generateSlug = (string) => {
    return string
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, ''); // Additional trim for leading/trailing hyphens
  }

const ServiceProvider = () => {
    const { slug } = useParams(); // Extract the slug from the URL
  const [store, setStore] = useState(null); // State to hold the matching store details
  const [loading, setLoading] = useState(true); // State to handle loading state
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const response = await fetch('https://wezesha.ai/services.php');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        //console.log('Fetched data:', data); // For debugging

        // Loop through the data to find the matching slug
        const matchingStore = data.find((item) => generateSlug(item.title) === slug);

        if (matchingStore) {
          setStore(matchingStore); // Set the matching store details in state
        } else {
          setError('Store not found');
        }
      } catch (error) {
        console.error('Error fetching store data:', error);
        setError('Error fetching store data');
      } finally {
        setLoading(false);
      }
    };

    fetchStoreData();
  }, [slug]);

 

  if (error) {
    return <div>{error}</div>;
  }
    return (
        store && (
        <>
          <Helmet>
                <title>{store.title} - Westgate Shopping Mall</title>
                <meta name="description" content={`Westgate Shopping Mall - Nairobi’s Premier Shopping Mall was opened to the
public in 2007 and is located in the vibrant heart of Westlands, Nairobi an area
that is both commercial and residential. Westlands has the highest concentra-
tion of middle to upper-class shoppers and is growing towards being the city’s
main hub. Westgate Shopping Mall conforms to high international standards in
the mall industry attracting highly cosmopolitan and sophisticated clientele
and reaching a well-defined demographic audience.`} />
                <meta property="og:title" content={``} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={``} />
                <meta property="og:image" content={``} />
                <meta property="og:image:height" content="1637" />
                <meta property="og:image:width" content="2560" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:url" content={``} />
                <meta name="twitter:title" content={``} />
                <meta name="twitter:description" content={`Westgate Shopping Mall - Nairobi’s Premier Shopping Mall was opened to the
public in 2007 and is located in the vibrant heart of Westlands, Nairobi an area
that is both commercial and residential. Westlands has the highest concentra-
tion of middle to upper-class shoppers and is growing towards being the city’s
main hub. Westgate Shopping Mall conforms to high international standards in
the mall industry attracting highly cosmopolitan and sophisticated clientele
and reaching a well-defined demographic audience.`} />
                <meta name="twitter:image:src" content={``} />
                <meta name="twitter:image:width" content={2560} />
                <meta name="twitter:image:height" content={1637} />
            </Helmet>
            <Header />
            <div className="constant-dots">
                <div className="site">
                    <div className="site-content">
                        <div className="site-sections">
                            <div className="block-container block-container--hero">
                                <div className="block-abs">
                                    <div className="block-top hidden-xs">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm-22 col-sm-offset-1">
                                                    <ul
                                                        className="breadcrumb breadcrumb--reverse sg-mt-20px"
                                                        itemScope=""
                                                        itemType="https://schema.org/BreadcrumbList"
                                                    >
                                                        <li
                                                            style={{ marginLeft: 0, paddingLeft: 0 }}
                                                            itemProp="itemListElement"
                                                            itemScope=""
                                                            itemType="https://schema.org/ListItem"
                                                        >
                                                            <a href="/en" itemProp="item">
                                                                <span itemProp="name">Home</span>
                                                            </a>
                                                            <meta itemProp="position" content={1} />
                                                        </li>
                                                        <li
                                                            itemProp="itemListElement"
                                                            itemScope=""
                                                            itemType="https://schema.org/ListItem"
                                                        >
                                                            <span className="arrow" />
                                                            <a
                                                                itemScope=""
                                                                itemType="https://schema.org/WebPage"
                                                                itemProp="item"
                                                                itemID="/en/store-directory"
                                                                href="/en/store-directory"
                                                            >
                                                                <span itemProp="name">Store Directory</span>
                                                            </a>
                                                            <meta itemProp="position" content={2} />
                                                        </li>
                                                        <li
                                                            itemProp="itemListElement"
                                                            itemScope=""
                                                            itemType="https://schema.org/ListItem"
                                                        >
                                                            <span className="arrow" />
                                                            <span itemProp="name">Hugo Boss</span>
                                                            <meta itemProp="position" content={3} />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block col-xs-24" data-block="">
                                        <div className="block-slide">
                                            <div className="block-bottom sg-mb-90px sg-mb-20px-sm">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-sm-22 col-sm-offset-1">
                                                            <img
                                                                src={`https://cdn.westgate.co.ke/app_images/logos/${store.shop_logo}`} alt={`${store.title} logo`} 
                                                                className="sg-mb-30px sg-hero-logo"
                                                            />
                                                            <div className="solid-line hidden-xs sg-mb-20px" />
                                                        </div>
                                                    </div>
                                                    <div className="row" id="end_gold_twig">
                                                        <div className="col-sm-10 col-sm-offset-1">
                                                            <p className="text-white">
                                                            {store.shop_desc}

                                                            </p>
                                                        </div>
                                                        <div className="col-sm-11 col-sm-offset-1 hidden-xs">
                                                            <div className="row margin-bottom-xs">
                                                                <div className="col-xs-24">
                                                                    <h5 className="text-white text-uppercase">
                                                                        Contact :
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="row" id="end_gold_twig_inside">
                                                                <div className="col-sm-24 col-md-10">
                                                                    <p className="text-white">
                                                                        <span className="icon-phone nice-big-icon pull-left" />
                                                                        <a
                                                                            href={`tel: ${store.phoneNumber} `}
                                                                            className="text-white plusicon_ar topLinkGold"
                                                                        >
                                                                           {store.phoneNumber}
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                                <div className="visible-sm">
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row visible-xs sg-mb-20px"
                                                        id="icon_reverse_ar"
                                                    >
                                                        <div className="col-sm-22 col-sm-offset-1">
                                                            <a
                                                                 href={`tel: ${store.phoneNumber} `}
                                                                className="btn btn-default-transparent text-uppercase visible-xs"
                                                            >
                                                                <span className="icon-phone" />
                                                                <span>{store.phoneNumber}</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="block-gradient"></div>
                                            <div className="block-visuals">
                                                <figure className="block-cover-img">
                                                    <div
                                                        data-bgset="./assets/images/boss-westgate.jpg"
                                                        className="bg-image lazyloaded"
                                                        data-alt="Entertain"
                                                        style={{
                                                            backgroundImage: `url('https://cdn.westgate.co.ke/app_images/images/${store.image}')`,
                                                            // Optional: Set the width to 100% of its container
                                                          }}
                                                        >
                                                    
                                                        <picture style={{ display: "none" }}>
                                                            <source
                                                                data-srcset="./assets/images/boss-westgate.jpg"
                                                                sizes="1913px"
                                                                srcSet="./assets/images/boss-westgate.jpg"
                                                            />
                                                            <img
                                                                alt="Entertain"
                                                                data-parent-fit="cover"
                                                                className="lazyautosizes lazyloaded"
                                                                data-sizes="auto"
                                                                sizes="1913px"
                                                            />
                                                        </picture>
                                                    </div>
                                                    {/*
                    <div
                      className="bg-image lazyload visible-xs-block"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic1/641ab084a05b6.jpg"
                      data-alt="Hugo Boss"
                    />
                    <div
                      className="bg-image lazyload visible-sm-block"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic2/641ab084c8c84.jpg"
                      data-alt="Hugo Boss"
                    />
                    <div
                      className="bg-image lazyload visible-md-block"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic/641ab0846f0c0.jpg"
                      data-alt="Hugo Boss"
                    />
                    <div
                      className="bg-image lazyload visible-lg-block"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic/641ab0846f0c0.jpg"
                      data-alt="Hugo Boss"
                    /> */}
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="block-padding-hero"
                                    data-xs={1}
                                    data-sm={1}
                                    data-md={1}
                                    data-lg={1}
                                />
                            </div>
                            <div className="block-container block-container-gold">
                                <div className="block-abs">
                                    <div
                                        className="block block-border-right col-sm-16 gold-fix"
                                        data-block=""
                                    >
                                        <div className="map-overlay" />
                                        <div id="mapContainer">
                                            {/*<iframe id="michello-map" class="map-embed" src="/map.php?unitNumber=TDM-GF-121" width="100%" height="100%" frameborder=0></iframe>*/}
                                            <iframe
                                                id="michello-map"
                                                className="map-embed"
                                                src="./assets/images/Westgate-map-ground.svg"
                                                width="100%"
                                                height="100%"
                                                frameBorder={0}
                                            />
                                        </div>
                                        <div
                                            id="map_notice_bar"
                                            className="map_notice_bar map_notice_bar_gold"
                                        >
                                            <div className="row" style={{ position: "relative" }}>
                                                <div className="col-xs-24 col-height col-middle">
                                                    <p className="parkingLocation"></p>
                                                    <div style={{ float: "left", width: 40 }}>
                                                        <span className="icon-map nice-big-icon" />
                                                    </div>
                                                    <div style={{ float: "left", width: "calc(100% - 40px)" }}>
                                                        <strong>Location</strong>: {store.title} /{" "}
                                                        <strong>Floor </strong>: {store.floor}/{" "}
                                                        
                                                    </div>
                                                    <div style={{ clear: "both" }} />
                                                    <p />
                                                </div>
                                                {/*<div style="position: absolute; text-align: right; color: rgb(0, 107, 183); height: 10px; display: block; visibility: visible; margin: 0px; padding: 0px; font-size: 80%; top: -20px; right: 0">Map Data ©Micello</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block block-border-left col-sm-8">
                                        <p style={{ textAlign: "center", marginTop: "50% !important" }}>
                                            There are currently no active offers for this shop
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="block-padding"
                                    data-block-height=""
                                    data-xs={2}
                                    data-sm={1}
                                    data-md={1}
                                    data-lg={1}
                                />
                            </div>
                           
                            <div className="block-container">
    <div className="block-abs">
        <div
            className="block block-border-right col-md-14 col-sm-12"
            data-block=""
        >
            <div className="block-content">
                <div className="display-table">
                    <div className="display-table-cell col-middle text-center">
                        <iframe
                            id="videoPlayer"
                            width="100%"
                            height="100%"
                            src={`https://www.youtube.com/embed/${store.youtube_url}`}
                            frameBorder={0}
                            allowFullScreen=""
                            style={{
                                display: "block",
                                margin: 0,
                                padding: 0,
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                position: "absolute"
                            }}
                        />
                        <a
                            id="playBtn"
                            href="#"
                            className="btn-play btn-play--white"
                            onClick={(e) => {
                                e.preventDefault();
                                document.getElementById('videoPlayer').style.display = 'block';
                            }}
                        >
                            <span className="icon-play" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="block-gradient">
                <div className="block-visualss">
                    <div className="block-visuals">
                        <figure className="block-cover-img"></figure>
                    </div>
                </div>
            </div>
        </div>
        <div
            className="block block-border-left col-md-10 col-sm-12"
            data-block=""
        >
            <p style={{ textAlign: "center", marginTop: "37%" }}>
                There are currently no active events for this shop
            </p>
        </div>
    </div>
    <div
        className="block-padding"
        data-block-height=""
        data-xs={2}
        data-sm={1}
        data-md={1}
        data-lg={1}
    />
</div>

                            <div className="clearfix" />
                          

                        </div>
                    </div>
                </div>
                <div
                    className="site-collapse-menu site-collapse-menu--main hidden"
                    id="site-collapse-menu"
                    style={{ marginTop: 50 }}
                >
                    <nav>
                        <ul className="js--accordion" role="tablist">
                            <li className="site-collapse-menu-item">
                                <a href="/en/whats-new">What's New</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/shop">Shop</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/dine">Dine</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/stay">Stay</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/entertain">Entertain</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a className="site-collapse-menu__level-0" href="#mm-5">
                                    Plan Your Visit
                                </a>
                                <ul className="collapse" id="mm-5">
                                    <li className="site-collapse-menu-item">
                                        <a href="/en/plan-your-visit/opening-hours">Opening hours</a>
                                    </li>
                                    <li className="site-collapse-menu-item">
                                        <a href="/en/plan-your-visit/getting-here">Getting here</a>
                                    </li>
                                    <li className="site-collapse-menu-item">
                                        <a href="/en/u-by-emaar">U By Emaar</a>
                                    </li>
                                    <li>
                                        <a href="/en/plan-your-visit/parking">Parking</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/services">Services</a>
                            </li>
                        </ul>
                    </nav>
                    <nav className="inverse">
                        <ul>
                            <li className="site-collapse-menu-item">
                                <a href="/en/fashion-avenue">Fashion avenue</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/fountain-view">Fountain views</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/campaign/chinatown">Chinatown</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/campaign/souk-al-bahar">Souk Al Bahar</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/offers">Offers</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/events">Events</a>
                            </li>
                        </ul>
                    </nav>
                    <nav>
                        <ul className="js--accordion" role="tablist">
                            <li className="site-collapse-menu-item menu_lan" id="language-selecter">
                                <a href="/ar/shop/Hugo Boss">العربية</a>
                            </li>
                            <li className="site-collapse-menu-item"></li>
                        </ul>
                    </nav>
                </div>
                <div className="visible-xs sfooter-menu">
                    <div className="site-collapse-menu" id="site-collapse-menu-footer">
                        <nav>
                            <ul>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/whats-new">What's New</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/shop">Shop</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/dine-directory">Dine</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/stay">Stay</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/entertain">Entertain</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/plan-your-visit/opening-hours">Plan Your Visit</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/services">Services</a>
                                </li>
                            </ul>
                        </nav>
                        <nav className="inverse">
                            <ul>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/fashion-avenue"> Fashion Avenue</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/offers">Offers</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/events"> Events</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            <Footer />
        </>
        )
    )
}

export default ServiceProvider