import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"

const Entertain = () => {

  return (

    <>
     <Helmet>
                <title>Entertainment - Westgate Shopping Mall</title>
                <meta name="description" content={`Westgate Shopping Mall - Nairobi’s Premier Shopping Mall was opened to the
public in 2007 and is located in the vibrant heart of Westlands, Nairobi an area
that is both commercial and residential. Westlands has the highest concentra-
tion of middle to upper-class shoppers and is growing towards being the city’s
main hub. Westgate Shopping Mall conforms to high international standards in
the mall industry attracting highly cosmopolitan and sophisticated clientele
and reaching a well-defined demographic audience.`} />
                <meta property="og:title" content={``} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={``} />
                <meta property="og:image" content={``} />
                <meta property="og:image:height" content="1637" />
                <meta property="og:image:width" content="2560" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:url" content={``} />
                <meta name="twitter:title" content={``} />
                <meta name="twitter:description" content={`Westgate Shopping Mall - Nairobi’s Premier Shopping Mall was opened to the
public in 2007 and is located in the vibrant heart of Westlands, Nairobi an area
that is both commercial and residential. Westlands has the highest concentra-
tion of middle to upper-class shoppers and is growing towards being the city’s
main hub. Westgate Shopping Mall conforms to high international standards in
the mall industry attracting highly cosmopolitan and sophisticated clientele
and reaching a well-defined demographic audience.`} />
                <meta name="twitter:image:src" content={``} />
                <meta name="twitter:image:width" content={2560} />
                <meta name="twitter:image:height" content={1637} />
            </Helmet>
      <Header />
      <div className="constant-dots">
        <div className="site">
          <img
            src="http://mena-gmtdmp.mookie1.com/t/v2/activity?tagid=V2_236194&src.rand=[timestamp]"
            style={{ display: "none" }}
          />
          <div className="site-content entertain-content">
            <div className="site-sections">
              <nav className="site-sections__menu hidden-xs">
                <ul>
                  <li>
                    <a href="fashion-avenue.html">Fashion avenue</a>
                  </li>
                  <li>
                    <a href="fountain-view.html">Fountain views</a>
                  </li>
                  <li>
                    <a href="campaign/chinatown.html">Chinatown</a>
                  </li>
                  <li>
                    <a href="campaign/souk-al-bahar.html">Souk Al Bahar</a>
                  </li>
                  <li>
                    <a href="offers.html">Offers</a>
                  </li>
                  <li>
                    <a href="events.html">Events</a>
                  </li>
                </ul>
              </nav>
              <div className="block-container block-container--hero">
                <div className="block-abs">
                  <div className="block-top hidden-xs">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-22 col-sm-offset-1">
                          <ul
                            className="breadcrumb breadcrumb--reverse sg-mt-20px"
                            itemScope=""
                            itemType="https://schema.org/BreadcrumbList"
                          >
                            <li
                              itemProp="itemListElement"
                              itemScope=""
                              itemType="https://schema.org/ListItem"
                            >
                              <a itemProp="item" href="index.html">
                                <span itemProp="name">Home</span>
                              </a>
                              <meta itemProp="position" content={1} />
                            </li>
                            <li
                              itemProp="itemListElement"
                              itemScope=""
                              itemType="https://schema.org/ListItem"
                            >
                              <span className="arrow" />
                              <span itemProp="name">Entertain</span>
                              <meta itemProp="position" content={2} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block col-xs-24" data-block="">
                    <div className="block-bottom sg-mb-90px sg-mb-20px-sm entertain-fix">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-sm-22 col-sm-offset-1">
                            <p className="text-uppercase text-white sg-mb-20px">
                              Entertain
                            </p>
                            <h1
                              className="view-panel__heading text-white sg-mb-20px"
                              style={{ width: "100% !important" }}
                            >
                              Out of the Ordinary
                            </h1>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-22 col-sm-offset-1">
                            <p className="text-white hidden-xs entertain-description">
                              <span className="entertain-line" />
                              Whether you seek the thrill of great heights or an
                              up-close encounter with the ocean's most mysterious
                              creatures, there’s something for the entire family to
                              explore at Dubai Mall.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block-gradient" />
                    <div className="block-visuals">
                      <figure className="block-cover-img">
                        <div
                          data-bgset="./assets/images/westgate_cinema_home.jpg"
                          className="bg-image lazyloaded"
                          data-alt="Entertain"
                          style={{
                            backgroundImage:
                              'url("./assets/images/westgate_cinema_home.jpg")'
                          }}
                        >
                          <picture style={{ display: "none" }}>
                            <source
                              data-srcset="./assets/images/westgate_cinema_home.jpg"
                              sizes="1913px"
                              srcSet="./assets/images/westgate_cinema_home.jpg"
                            />
                            <img
                              alt="Entertain"
                              data-parent-fit="cover"
                              className="lazyautosizes lazyloaded"
                              data-sizes="auto"
                              sizes="1913px"
                            />
                          </picture>
                        </div>

                      </figure>
                    </div>
                  </div>
                </div>
                <div
                  className="block-padding-hero"
                  data-xs={1}
                  data-sm={1}
                  data-md={1}
                  data-lg={1}
                />
              </div>
              <div className="block-container block-container-col-2">
                <div className="block-abs">
                  <div
                    className="block block-border-right col-md-12 col-sm-12 block-white"
                    data-block=""
                  >
                    <div className="block-bottom sg-mb-40px">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-sm-22 col-sm-offset-1 text-white">
                            <div className="visible-xs">
                              <h1 className="sg-mb-10px">Strike up some fun</h1>
                              <p className="entertain-display-none" />
                            </div>
                            <div className="hidden-xs">
                              <h2 className="sg-mb-10px">Strike up some fun</h2>
                              <p className="entertain-display-none" />
                            </div>
                            <a
                              href="entertain-detail/trampoextreme.html"
                              className="btn btn-default-transparent btn-block-sm text-uppercase margin-right-xs-px"
                            >
                              Explore more
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block-gradient" />
                    <div className="block-visuals">
                      <figure className="block-cover-img">
                        <div
                          data-bgset="./assets/images/strikez_bowling.jpg"
                          className="bg-image lazyloaded"
                          data-alt="Entertain"
                          style={{
                            backgroundImage:
                              'url("./assets/images/strikez_bowling.jpg")'
                          }}
                        >
                          <picture style={{ display: "none" }}>
                            <source
                              data-srcset="./assets/images/strikez_bowling.jpg"
                              sizes="1913px"
                              srcSet="./assets/images/strikez_bowling.jpg"
                            />
                            <img
                              alt="Entertain"
                              data-parent-fit="cover"
                              className="lazyautosizes lazyloaded"
                              data-sizes="auto"
                              sizes="1913px"
                            />
                          </picture>
                        </div>
                      </figure>
                    </div>
                  </div>
                  <div
                    className="block block-border-left col-md-12 col-sm-12 block-white"
                    data-block=""
                  >
                    <div className="block-bottom sg-mb-40px">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-sm-22 col-sm-offset-1 text-white">
                            <div className="visible-xs">
                              <h1 className="sg-mb-10px">Westgate Cinema</h1>
                              <p className="entertain-display-none" />
                            </div>
                            <div className="hidden-xs">
                              <h2 className="sg-mb-10px">Westgate Cinema</h2>
                              <p className="entertain-display-none" />
                            </div>
                            <a
                              href="entertain-detail/ekart-zabeel.html"
                              className="btn btn-default-transparent btn-block-sm text-uppercase margin-right-xs-px"
                            >
                              Book Tickets
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block-gradient" />
                    <div className="block-visuals">
                      <figure className="block-cover-img">
                        <div
                          data-bgset="./assets/images/westgate_cinema.jpg"
                          className="bg-image lazyloaded"
                          data-alt="Entertain"
                          style={{
                            backgroundImage:
                              'url("./assets/images/westgate_cinema.jpg")'
                          }}
                        >
                          <picture style={{ display: "none" }}>
                            <source
                              data-srcset="./assets/images/westgate_cinema.jpg"
                              sizes="1913px"
                              srcSet="./assets/images/westgate_cinema.jpg"
                            />
                            <img
                              alt="Entertain"
                              data-parent-fit="cover"
                              className="lazyautosizes lazyloaded"
                              data-sizes="auto"
                              sizes="1913px"
                            />
                          </picture>
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
                <div
                  className="block-padding"
                  data-block-height=""
                  data-xs={2}
                  data-sm={1}
                  data-md={1}
                  data-lg={1}
                />
              </div>
              <div className="block-container block-container-col-2">
                <div className="block-abs">
                  <div
                    className="block block-border-right col-md-12 col-sm-12 block-white"
                    data-block=""
                  >
                    <div className="block-bottom sg-mb-40px">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-sm-22 col-sm-offset-1 text-white">
                            <div className="visible-xs">
                              <h1 className="sg-mb-10px">Westgate Kids Corner</h1>

                            </div>
                            <div className="hidden-xs">
                              <h2 className="sg-mb-10px">Westgate Kids Corner</h2>

                            </div>
                            <a
                              href="entertain-detail/Westgate Kids Corner-1.html"
                              className="btn btn-default-transparent btn-block-sm text-uppercase margin-right-xs-px"
                            >
                              Explore more
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block-gradient" />
                    <div className="block-visuals">
                      <figure className="block-cover-img">
                        <div
                          data-bgset="./assets/images/westgate_kids.jpg"
                          className="bg-image lazyloaded"
                          data-alt="Entertain"
                          style={{
                            backgroundImage:
                              'url("./assets/images/westgate_kids.jpg")'
                          }}
                        >
                          <picture style={{ display: "none" }}>
                            <source
                              data-srcset="./assets/images/westgate_kids.jpg"
                              sizes="1913px"
                              srcSet="./assets/images/westgate_kids.jpg"
                            />
                            <img
                              alt="Entertain"
                              data-parent-fit="cover"
                              className="lazyautosizes lazyloaded"
                              data-sizes="auto"
                              sizes="1913px"
                            />
                          </picture>
                        </div>

                      </figure>
                    </div>
                  </div>
                  <div
                    className="block block-border-left col-md-12 col-sm-12 block-white"
                    data-block=""
                  >
                    <div className="block-bottom sg-mb-40px">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-sm-22 col-sm-offset-1 text-white">
                            <div className="visible-xs">
                              <h1 className="sg-mb-10px">25M Heated Pool</h1>

                            </div>
                            <div className="hidden-xs">
                              <h2 className="sg-mb-10px">25M eated Pool</h2>

                            </div>
                            <a
                              href="entertain-detail/at-the-top-burj-khalifa-1.html"
                              className="btn btn-default-transparent btn-block-sm text-uppercase margin-right-xs-px"
                            >
                              Join W-Exclusive
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="block-gradient" />
                    <div className="block-visuals">
                      <figure className="block-cover-img">
                        <div
                          data-bgset="./assets/images/W-Exclusive-Gym-Swimming-pool-Westgate.webp"
                          className="bg-image lazyloaded"
                          data-alt="Entertain"
                          style={{
                            backgroundImage:
                              'url("./assets/images/W-Exclusive-Gym-Swimming-pool-Westgate.webp")'
                          }}
                        >
                          <picture style={{ display: "none" }}>
                            <source
                              data-srcset="./assets/images/W-Exclusive-Gym-Swimming-pool-Westgate.webp"
                              sizes="1913px"
                              srcSet="./assets/images/W-Exclusive-Gym-Swimming-pool-Westgate.webp"
                            />
                            <img
                              alt="Entertain"
                              data-parent-fit="cover"
                              className="lazyautosizes lazyloaded"
                              data-sizes="auto"
                              sizes="1913px"
                            />
                          </picture>
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
                <div
                  className="block-padding"
                  data-block-height=""
                  data-xs={2}
                  data-sm={1}
                  data-md={1}
                  data-lg={1}
                />
              </div>
           
              <div className="block-container block-container-col-1">
                <div className="block-abs">
                  <div
                    className="block block-border-right col-md-24 col-sm-24 block-white"
                    data-block=""
                  >
                    <div className="block-bottom sg-mb-40px">
                      <div className="container-fluid" id="entertain_text">
                        {/*
                        <div className="row">
                          <div className="col-sm-11 col-sm-offset-1">
                            <div className="visible-xs">
                              <h1 className="sg-mb-10px text-white">Dubai Dino</h1>
                            </div>
                            <div className="hidden-xs">
                              <h2 className="sg-mb-10px text-white">Dubai Dino</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row hidden-xs">
                          <div className="col-sm-11 col-sm-offset-1">
                            <div className="solid-line hidden-xs sg-mb-20px" />
                            <p className="text-white">
                              Come to The Souk Dome and pay a visit to our 155
                              million-year-old resident, the Dubai Dino.
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-11 col-sm-offset-1">
                            <a
                              href="entertain-detail/dubai-dino-1.html"
                              className="btn btn-default-transparent btn-block-sm text-uppercase margin-right-xs-px"
                            >
                              Explore more
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="block-gradient" />
                    <div className="block-visuals">
                      <figure className="block-cover-img">
                      <div
                                                                data-bgset="./assets/images/Westgate-Cinema-37Westgate.webp"
                                                                className="bg-image lazyloaded"
                                                                data-alt="Entertain"
                                                                style={{
                                                                    backgroundImage:
                                                                        'url("./assets/images/Westgate-Cinema-37Westgate.webp")'
                                                                }}
                                                            >
                                                                <picture style={{ display: "none" }}>
                                                                    <source
                                                                        data-srcset="./assets/images/Westgate-Cinema-37Westgate.webp"
                                                                        sizes="1913px"
                                                                        srcSet="./assets/images/Westgate-Cinema-37Westgate.webp"
                                                                    />
                                                                    <img
                                                                        alt="Entertain"
                                                                        data-parent-fit="cover"
                                                                        className="lazyautosizes lazyloaded"
                                                                        data-sizes="auto"
                                                                        sizes="1913px"
                                                                    />
                                                                </picture>
                                                            </div>
                      </figure>
                    </div>
                  </div>
                </div>
                <div
                  className="block-padding"
                  data-block-height=""
                  data-xs={1}
                  data-sm={1}
                  data-md={1}
                  data-lg={1}
                />
              </div>
              <div className="clearfix">
                <section
                  className="site-section"
                  style={{ width: "100%", overflow: "hidden" }}
                >
                  <div className="container sg-mb-25px">
                    <div className="row">
                      <div className="col-xs-24">
                        <div className="visible-xs">
                          <h2 className="text-center sg-mt-40px sg-mb-25px">
                            Discover Events &amp; Offers
                          </h2>
                        </div>
                        <div className="hidden-xs">
                          <h3 className="text-center sg-mt-40px">
                            Discover Events &amp; Offers
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="container no-gutter-md sg-mb-30pxm "
                    id="home_view4"
                  >
                    <div className="slick-slider-container">
                      <div className="slick-slider-container__row slick-slider-with-text">
                        <div className="row">
                          <div
                            className="slick-events-offers-slider pull-nav-up diap-dots"
                            data-prefilter=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row hidden-xs sg-mb-40px">
                      <div className="col-xs-24 text-center">
                        <div className="sg-mt-20px" id="home_view5">
                          <a
                            href="events.html"
                            className="btn btn-default btn-block-sm margin-right-xs-px"
                          >
                            Discover events
                          </a>
                          <a
                            href="offers.html"
                            className="btn btn-default btn-block-sm"
                          >
                            Discover offers
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="visible-xs">
                    <br />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

    </>
  )
}

export default Entertain