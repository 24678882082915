import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"

const Events = () => {
    return (
        <>
         <Helmet>
                <title>Upcoming Events - Westgate Shopping Mall</title>
                <meta name="description" content={`Westgate Shopping Mall - Nairobi’s Premier Shopping Mall was opened to the
public in 2007 and is located in the vibrant heart of Westlands, Nairobi an area
that is both commercial and residential. Westlands has the highest concentra-
tion of middle to upper-class shoppers and is growing towards being the city’s
main hub. Westgate Shopping Mall conforms to high international standards in
the mall industry attracting highly cosmopolitan and sophisticated clientele
and reaching a well-defined demographic audience.`} />
                <meta property="og:title" content={``} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={``} />
                <meta property="og:image" content={``} />
                <meta property="og:image:height" content="1637" />
                <meta property="og:image:width" content="2560" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:url" content={``} />
                <meta name="twitter:title" content={``} />
                <meta name="twitter:description" content={`Westgate Shopping Mall - Nairobi’s Premier Shopping Mall was opened to the
public in 2007 and is located in the vibrant heart of Westlands, Nairobi an area
that is both commercial and residential. Westlands has the highest concentra-
tion of middle to upper-class shoppers and is growing towards being the city’s
main hub. Westgate Shopping Mall conforms to high international standards in
the mall industry attracting highly cosmopolitan and sophisticated clientele
and reaching a well-defined demographic audience.`} />
                <meta name="twitter:image:src" content={``} />
                <meta name="twitter:image:width" content={2560} />
                <meta name="twitter:image:height" content={1637} />
            </Helmet>
            <Header />
            <div className="constant-dots">
                <div className="site">
                    <img
                        src="//mena-gmtdmp.mookie1.com/t/v2/activity?tagid=V2_236201&src.rand=[timestamp]"
                        style={{ display: "none" }}
                    />
                    <div className="site-content">
                        <div className="site-sections">
                            <section className="site-section">
                                <div
                                    className="container sg-mb-25px sg-mt-40px"
                                    id="events-search-result-container-parent"
                                >
                                    <div className="row block-grid-legend">
                                        <div className="">
                                            <p
                                                className="events-display-indicator"
                                                data-label-showing-indicator="Showing %s1% Results"
                                            >
                                                Showing Results
                                            </p>
                                            <div
                                                className="nosearch-result col-md-24 text-center hidden"
                                                style={{ minHeight: "0px !important" }}
                                            >
                                                <h3 className="nosearch-result__message sg-mb-15px text-center">
                                                    New updates will be posted soon
                                                </h3>
                                                {/*<p class="nosearch-result__message sg-mb-15px"> New updates will be posted soon</p>*/}
                                                {/*<p class="nosearch-result__message sg-mb-15px"> Sorry, we couldn&#039;t find an event to match your search.</p>*/}
                                                {/*<p class="nosearch-result__suggestion sg-mb-5px">Did you mean <span class=""><a href="#" class="nosearch-result__suggestion--word"></a></span></p>*/}
                                                {/*<p class="nosearch-result__suggestion--or sg-mb-5px">Or</p>*/}
                                                {/*<p class="nosearch-result__suggestion--tryagain sg-mb-10px">Please try a new search entry</p>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-xs-24">
                                            <h5
                                                className="text-center text-uppercase bordered"
                                                id="all-events-head"
                                            >
                                                <span>Westgate Events</span>
                                            </h5>
                                            <h5
                                                className="text-center text-uppercase bordered hidden"
                                                id="matched-events-head"
                                            >
                                                <span>Matched Results</span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row events-search-result-container">
                                        <div className="col-sm-8 padding-bottom-xs" style={{ marginBottom: 30 }}>
                                            <div className="panel-card">
                                                <article>
                                                    <figure>
                                                        <a href="/en/event-detail/gosh-arts-at-level-shoes-1">
                                                            <div
                                                                className="panel-card__img bg-image sg-mb-10px lazyloaded"
                                                                data-bgset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/66b6019fd32fb.jpg"
                                                                data-alt="Goshá Arts at Level Shoes"
                                                                style={{
                                                                    backgroundImage:
                                                                        'url("https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/66b6019fd32fb.jpg")'
                                                                }}
                                                            >
                                                                <picture style={{ display: "none" }}>
                                                                    <source
                                                                        data-srcset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/66b6019fd32fb.jpg"
                                                                        sizes="440px"
                                                                        srcSet="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/66b6019fd32fb.jpg"
                                                                    />
                                                                    <img
                                                                        alt="Goshá Arts at Level Shoes"
                                                                        data-parent-fit="cover"
                                                                        className="lazyautosizes lazyloaded"
                                                                        data-sizes="auto"
                                                                        sizes="440px"
                                                                    />
                                                                </picture>
                                                            </div>
                                                        </a>
                                                    </figure>
                                                    <main className="aasd">
                                                        <p className="light-gray  p--date sg-mb-10px p-date-events">
                                                            09 Aug - 09 Sep 2024
                                                        </p>
                                                        {/* <h5 class="text-uppercase sg-mb-5px shop-name-events"><strong>GOSHÁ ARTS AT LEVEL SHOES</strong></h5> */}
                                                        <h5 className="text-uppercase sg-mb-5px shop-name-events">
                                                            <strong>LEVEL SHOES</strong>
                                                        </h5>
                                                        <p className="sg-mb-5px event-description-events">
                                                            Experience an exciting first-of-it’s-kind collaboration between
                                                            Level Shoes and ...
                                                        </p>
                                                        <a
                                                            style={{ fontSize: 12 }}
                                                            className="btn btn-link hidden-xs sg-mb-20px find-more-events"
                                                            href="/en/event-detail/gosh-arts-at-level-shoes-1"
                                                        >
                                                            <strong>Find out more </strong>
                                                        </a>
                                                        <a
                                                            className="btn btn-default visible-xs sg-mb-20px sg-mt-10px"
                                                            href="/en/event-detail/gosh-arts-at-level-shoes-1"
                                                        >
                                                            Find out more{" "}
                                                        </a>
                                                    </main>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="col-sm-8 padding-bottom-xs" style={{ marginBottom: 30 }}>
                                            <div className="panel-card">
                                                <article>
                                                    <figure>
                                                        <a href="/en/event-detail/marli-new-york-at-bloomingdales">
                                                            <div
                                                                className="panel-card__img bg-image sg-mb-10px lazyloaded"
                                                                data-bgset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/66b4811039a41.jpg"
                                                                data-alt="MARLI New York at Bloomingdale's"
                                                                style={{
                                                                    backgroundImage:
                                                                        'url("https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/66b4811039a41.jpg")'
                                                                }}
                                                            >
                                                                <picture style={{ display: "none" }}>
                                                                    <source
                                                                        data-srcset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/66b4811039a41.jpg"
                                                                        sizes="440px"
                                                                        srcSet="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/66b4811039a41.jpg"
                                                                    />
                                                                    <img
                                                                        alt="MARLI New York at Bloomingdale's"
                                                                        data-parent-fit="cover"
                                                                        className="lazyautosizes lazyloaded"
                                                                        data-sizes="auto"
                                                                        sizes="440px"
                                                                    />
                                                                </picture>
                                                            </div>
                                                        </a>
                                                    </figure>
                                                    <main className="aasd">
                                                        <p className="light-gray  p--date sg-mb-10px p-date-events">
                                                            08 Aug - 30 Sep 2024
                                                        </p>
                                                        {/* <h5 class="text-uppercase sg-mb-5px shop-name-events"><strong>MARLI NEW YORK AT BLOOMINGDALE'S</strong></h5> */}
                                                        <h5 className="text-uppercase sg-mb-5px shop-name-events">
                                                            <strong>BLOOMINGDALE'S</strong>
                                                        </h5>
                                                        <p className="sg-mb-5px event-description-events">
                                                            Discover MARLI New York, one of New York's most celebrated
                                                            jewelers in-store at ...
                                                        </p>
                                                        <a
                                                            style={{ fontSize: 12 }}
                                                            className="btn btn-link hidden-xs sg-mb-20px find-more-events"
                                                            href="/en/event-detail/marli-new-york-at-bloomingdales"
                                                        >
                                                            <strong>Find out more </strong>
                                                        </a>
                                                        <a
                                                            className="btn btn-default visible-xs sg-mb-20px sg-mt-10px"
                                                            href="/en/event-detail/marli-new-york-at-bloomingdales"
                                                        >
                                                            Find out more{" "}
                                                        </a>
                                                    </main>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="col-sm-8 padding-bottom-xs" style={{ marginBottom: 30 }}>
                                            <div className="panel-card">
                                                <article>
                                                    <figure>
                                                        <a href="/en/event-detail/kidzania-martial-arts-boot-camp">
                                                            <div
                                                                className="panel-card__img bg-image sg-mb-10px lazyloaded"
                                                                data-bgset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/6699f9de01347.jpg"
                                                                data-alt="KidZania Martial Arts Boot Camp"
                                                                style={{
                                                                    backgroundImage:
                                                                        'url("https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/6699f9de01347.jpg")'
                                                                }}
                                                            >
                                                                <picture style={{ display: "none" }}>
                                                                    <source
                                                                        data-srcset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/6699f9de01347.jpg"
                                                                        sizes="440px"
                                                                        srcSet="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/6699f9de01347.jpg"
                                                                    />
                                                                    <img
                                                                        alt="KidZania Martial Arts Boot Camp"
                                                                        data-parent-fit="cover"
                                                                        className="lazyautosizes lazyloaded"
                                                                        data-sizes="auto"
                                                                        sizes="440px"
                                                                    />
                                                                </picture>
                                                            </div>
                                                        </a>
                                                    </figure>
                                                    <main className="aasd">
                                                        <p className="light-gray  p--date sg-mb-10px p-date-events">
                                                            02 Aug - 25 Aug 2024
                                                        </p>
                                                        {/* <h5 class="text-uppercase sg-mb-5px shop-name-events"><strong>KIDZANIA MARTIAL ARTS BOOT CAMP</strong></h5> */}
                                                        <h5 className="text-uppercase sg-mb-5px shop-name-events">
                                                            <strong>KIDZANIA</strong>
                                                        </h5>
                                                        <p className="sg-mb-5px event-description-events">
                                                            KidZania Martial Arts Boot Camp:&nbsp;Empowering Young Warriors
                                                            Through Fun and Fitne...
                                                        </p>
                                                        <a
                                                            style={{ fontSize: 12 }}
                                                            className="btn btn-link hidden-xs sg-mb-20px find-more-events"
                                                            href="/en/event-detail/kidzania-martial-arts-boot-camp"
                                                        >
                                                            <strong>Find out more </strong>
                                                        </a>
                                                        <a
                                                            className="btn btn-default visible-xs sg-mb-20px sg-mt-10px"
                                                            href="/en/event-detail/kidzania-martial-arts-boot-camp"
                                                        >
                                                            Find out more{" "}
                                                        </a>
                                                    </main>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="clearfix hidden-xs" />
                                        <div className="col-sm-8 padding-bottom-xs" style={{ marginBottom: 30 }}>
                                            <div className="panel-card">
                                                <article>
                                                    <figure>
                                                        <a href="/en/event-detail/kidzania-games-2024">
                                                            <div
                                                                className="panel-card__img bg-image sg-mb-10px lazyloaded"
                                                                data-bgset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/6699fa9de1834.jpg"
                                                                data-alt="KidZania Games 2024"
                                                                style={{
                                                                    backgroundImage:
                                                                        'url("https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/6699fa9de1834.jpg")'
                                                                }}
                                                            >
                                                                <picture style={{ display: "none" }}>
                                                                    <source
                                                                        data-srcset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/6699fa9de1834.jpg"
                                                                        sizes="440px"
                                                                        srcSet="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/6699fa9de1834.jpg"
                                                                    />
                                                                    <img
                                                                        alt="KidZania Games 2024"
                                                                        data-parent-fit="cover"
                                                                        className="lazyautosizes lazyloaded"
                                                                        data-sizes="auto"
                                                                        sizes="440px"
                                                                    />
                                                                </picture>
                                                            </div>
                                                        </a>
                                                    </figure>
                                                    <main className="aasd">
                                                        <p className="light-gray  p--date sg-mb-10px p-date-events">
                                                            16 Jul - 15 Sep 2024
                                                        </p>
                                                        {/* <h5 class="text-uppercase sg-mb-5px shop-name-events"><strong>KIDZANIA GAMES 2024</strong></h5> */}
                                                        <h5 className="text-uppercase sg-mb-5px shop-name-events">
                                                            <strong>KIDZANIA</strong>
                                                        </h5>
                                                        <p className="sg-mb-5px event-description-events">
                                                            KidZania Games 2024: Join the Adventure, Embrace the Spirit
                                                            &nbsp; Are you ready to...
                                                        </p>
                                                        <a
                                                            style={{ fontSize: 12 }}
                                                            className="btn btn-link hidden-xs sg-mb-20px find-more-events"
                                                            href="/en/event-detail/kidzania-games-2024"
                                                        >
                                                            <strong>Find out more </strong>
                                                        </a>
                                                        <a
                                                            className="btn btn-default visible-xs sg-mb-20px sg-mt-10px"
                                                            href="/en/event-detail/kidzania-games-2024"
                                                        >
                                                            Find out more{" "}
                                                        </a>
                                                    </main>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="col-sm-8 padding-bottom-xs" style={{ marginBottom: 30 }}>
                                            <div className="panel-card">
                                                <article>
                                                    <figure>
                                                        <a href="/en/event-detail/-summer-on-the-rink-at-dubai-ice-rink-">
                                                            <div
                                                                className="panel-card__img bg-image sg-mb-10px lazyloaded"
                                                                data-bgset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/668e54b237281.jpg"
                                                                data-alt="Summer on the Rink at Dubai Ice Rink"
                                                                style={{
                                                                    backgroundImage:
                                                                        'url("https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/668e54b237281.jpg")'
                                                                }}
                                                            >
                                                                <picture style={{ display: "none" }}>
                                                                    <source
                                                                        data-srcset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/668e54b237281.jpg"
                                                                        sizes="440px"
                                                                        srcSet="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/668e54b237281.jpg"
                                                                    />
                                                                    <img
                                                                        alt="Summer on the Rink at Dubai Ice Rink"
                                                                        data-parent-fit="cover"
                                                                        className="lazyautosizes lazyloaded"
                                                                        data-sizes="auto"
                                                                        sizes="440px"
                                                                    />
                                                                </picture>
                                                            </div>
                                                        </a>
                                                    </figure>
                                                    <main className="aasd">
                                                        <p className="light-gray  p--date sg-mb-10px p-date-events">
                                                            05 Jul - 25 Aug 2024
                                                        </p>
                                                        {/* <h5 class="text-uppercase sg-mb-5px shop-name-events"><strong>SUMMER ON THE RINK AT DUBAI ICE RINK</strong></h5> */}
                                                        <h5 className="text-uppercase sg-mb-5px shop-name-events">
                                                            <strong>DUBAI ICE RINK</strong>
                                                        </h5>
                                                        <p className="sg-mb-5px event-description-events">
                                                            Summer on the Rink at Dubai Ice Rink: Musical Fun for the Whole
                                                            Family Cool dow...
                                                        </p>
                                                        <a
                                                            style={{ fontSize: 12 }}
                                                            className="btn btn-link hidden-xs sg-mb-20px find-more-events"
                                                            href="/en/event-detail/-summer-on-the-rink-at-dubai-ice-rink-"
                                                        >
                                                            <strong>Find out more </strong>
                                                        </a>
                                                        <a
                                                            className="btn btn-default visible-xs sg-mb-20px sg-mt-10px"
                                                            href="/en/event-detail/-summer-on-the-rink-at-dubai-ice-rink-"
                                                        >
                                                            Find out more{" "}
                                                        </a>
                                                    </main>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="col-sm-8 padding-bottom-xs" style={{ marginBottom: 30 }}>
                                            <div className="panel-card">
                                                <article>
                                                    <figure>
                                                        <a href="/en/event-detail/dubai-malls-ultimate-summer-camp-is-back">
                                                            <div
                                                                className="panel-card__img bg-image sg-mb-10px lazyloaded"
                                                                data-bgset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/668ce3b4bf463.jpg"
                                                                data-alt="Dubai Mall’s Ultimate Summer Camp is Back"
                                                                style={{
                                                                    backgroundImage:
                                                                        'url("https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/668ce3b4bf463.jpg")'
                                                                }}
                                                            >
                                                                <picture style={{ display: "none" }}>
                                                                    <source
                                                                        data-srcset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/668ce3b4bf463.jpg"
                                                                        sizes="440px"
                                                                        srcSet="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/668ce3b4bf463.jpg"
                                                                    />
                                                                    <img
                                                                        alt="Dubai Mall’s Ultimate Summer Camp is Back"
                                                                        data-parent-fit="cover"
                                                                        className="lazyautosizes lazyloaded"
                                                                        data-sizes="auto"
                                                                        sizes="440px"
                                                                    />
                                                                </picture>
                                                            </div>
                                                        </a>
                                                    </figure>
                                                    <main className="aasd">
                                                        <p className="light-gray  p--date sg-mb-10px p-date-events">
                                                            01 Jul - 29 Aug 2024
                                                        </p>
                                                        {/* <h5 class="text-uppercase sg-mb-5px shop-name-events"><strong>DUBAI MALL’S ULTIMATE SUMMER CAMP IS BACK</strong></h5> */}
                                                        <h5 className="text-uppercase sg-mb-5px shop-name-events">
                                                            <strong>KIDZANIA</strong>
                                                        </h5>
                                                        <p className="sg-mb-5px event-description-events">
                                                            Dubai’s ultimate summer camp is back, combining fun and learning
                                                            across six incr...
                                                        </p>
                                                        <a
                                                            style={{ fontSize: 12 }}
                                                            className="btn btn-link hidden-xs sg-mb-20px find-more-events"
                                                            href="/en/event-detail/dubai-malls-ultimate-summer-camp-is-back"
                                                        >
                                                            <strong>Find out more </strong>
                                                        </a>
                                                        <a
                                                            className="btn btn-default visible-xs sg-mb-20px sg-mt-10px"
                                                            href="/en/event-detail/dubai-malls-ultimate-summer-camp-is-back"
                                                        >
                                                            Find out more{" "}
                                                        </a>
                                                    </main>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="clearfix hidden-xs" />
                                    </div>
                                  
                                </div>
                                <div
                                    className="container sg-mb-25px sg-mt-40px hidden"
                                    id="related-events-search-result-container-parent"
                                >
                                    <div className="row">
                                        <div className="col-xs-24">
                                            <h5 className="text-center text-uppercase bordered">
                                                <span>Related Results</span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row related-events-search-result-container" />
                                    <div className="row sg-mb-50px pagination">
                                        <a
                                            href="#"
                                            id="btn-events-related-load-more"
                                            target="_self"
                                            className="pagination__txt"
                                        >
                                            Load more
                                        </a>
                                    </div>
                                </div>
                            </section>

                            <section className="site-section">
                                <a
                                    href="#"
                                    id="refineEvents"
                                    className="link--back-to-top hidden"
                                    target="_self"
                                >
                                    <i className="arrow" /> Refine search
                                </a>
                            </section>

                        </div>
                    </div>
                </div>
                <div
                    className="site-collapse-menu site-collapse-menu--main hidden"
                    id="site-collapse-menu"
                    style={{ marginTop: 50 }}
                >
                    <nav>
                        <ul className="js--accordion" role="tablist">
                            <li className="site-collapse-menu-item">
                                <a href="/en/whats-new">What's New</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/shop">Shop</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/dine">Dine</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/stay">Stay</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/entertain">Entertain</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a className="site-collapse-menu__level-0" href="#mm-5">
                                    Plan Your Visit
                                </a>
                                <ul className="collapse" id="mm-5">
                                    <li className="site-collapse-menu-item">
                                        <a href="/en/plan-your-visit/opening-hours">Opening hours</a>
                                    </li>
                                    <li className="site-collapse-menu-item">
                                        <a href="/en/plan-your-visit/getting-here">Getting here</a>
                                    </li>
                                    <li className="site-collapse-menu-item">
                                        <a href="/en/u-by-emaar">U By Emaar</a>
                                    </li>
                                    <li>
                                        <a href="/en/plan-your-visit/parking">Parking</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/services">Services</a>
                            </li>
                        </ul>
                    </nav>
                    <nav className="inverse">
                        <ul>
                            <li className="site-collapse-menu-item">
                                <a href="/en/fashion-avenue">Fashion avenue</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/fountain-view">Fountain views</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/campaign/chinatown">Chinatown</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/campaign/souk-al-bahar">Souk Al Bahar</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/offers">Offers</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="/en/events">Events</a>
                            </li>
                        </ul>
                    </nav>
                   
                </div>
                <div className="visible-xs sfooter-menu">
                    <div className="site-collapse-menu" id="site-collapse-menu-footer">
                        <nav>
                            <ul>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/whats-new">What's New</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/shop">Shop</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/dine-directory">Dine</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/stay">Stay</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/entertain">Entertain</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/plan-your-visit/opening-hours">Plan Your Visit</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/services">Services</a>
                                </li>
                            </ul>
                        </nav>
                        <nav className="inverse">
                            <ul>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/fashion-avenue"> Fashion Avenue</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/offers">Offers</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="/en/events"> Events</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    )
}

export default Events