import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"

const Services = () => {
    return (
        <>
         <Helmet>
                <title>Services - Westgate Shopping Mall</title>
                <meta name="description" content={`Westgate Shopping Mall - Nairobi’s Premier Shopping Mall was opened to the
public in 2007 and is located in the vibrant heart of Westlands, Nairobi an area
that is both commercial and residential. Westlands has the highest concentra-
tion of middle to upper-class shoppers and is growing towards being the city’s
main hub. Westgate Shopping Mall conforms to high international standards in
the mall industry attracting highly cosmopolitan and sophisticated clientele
and reaching a well-defined demographic audience.`} />
                <meta property="og:title" content={``} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={``} />
                <meta property="og:image" content={``} />
                <meta property="og:image:height" content="1637" />
                <meta property="og:image:width" content="2560" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:url" content={``} />
                <meta name="twitter:title" content={``} />
                <meta name="twitter:description" content={`Westgate Shopping Mall - Nairobi’s Premier Shopping Mall was opened to the
public in 2007 and is located in the vibrant heart of Westlands, Nairobi an area
that is both commercial and residential. Westlands has the highest concentra-
tion of middle to upper-class shoppers and is growing towards being the city’s
main hub. Westgate Shopping Mall conforms to high international standards in
the mall industry attracting highly cosmopolitan and sophisticated clientele
and reaching a well-defined demographic audience.`} />
                <meta name="twitter:image:src" content={``} />
                <meta name="twitter:image:width" content={2560} />
                <meta name="twitter:image:height" content={1637} />
            </Helmet>
            <Header />
            <div className="constant-dots">
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n            @media (max-width: 768px) {\n                .fashionavenuItms {\n                    height: 586px !important;\n                }\n            }\n\n            /* Set the aspect ratio to maintain the viewBox */\n            .sg-hero-fv-logo {\n                height: 100% !important;\n                max-height: 115px !important;\n            }\n        "
                    }}
                />
                <div className="site">
                    <img
                        src="http://mena-gmtdmp.mookie1.com/t/v2/activity?tagid=V2_236208&src.rand=[timestamp]"
                        style={{ display: "none" }}
                    />
                    <div className="site-content fashion-avenue-page">
                        <div className="site-sections">
                            <div className="hidden-xs" style={{ height: 95 }} />
                            <div data-fullpage="" id="fullpage_id_fashion">
                                <div
                                    className="block-container  fp-section  block-container--hero vh100bis"
                                    id="fa-fa-first"
                                >
                                    <div className="block col-xs-24">
                                        <div className="block-bottom sg-mb-20px-sm">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-sm-20 col-sm-offset-2 text-center">
                                                        <h5 className="view-panel__category text-white sg-mb-10px">
                                                            {/* <p className="fashion_avenue_white">Fashion Avenue</p> */}
                                                        </h5>
                                                        <h2 className="view-panel__heading text-white sg-mb-15px text-uppercase">
                                                            <strong>
                                                                Premium
                                                                <br />
                                                                Services
                                                            </strong>
                                                        </h2>
                                                        <div className="row">
                                                            <div className="col-sm-12 col-sm-offset-6 text-center">
                                                                <p className="text-white sg-mb-35px">
                                                                    Welcome to a new era of Premium banking, Forex bureaus, Salons and Telcos
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <a
                                                            href="#"
                                                            id="seeShopsFA"
                                                            className="btn btn-default-transparent btn-block-sm text-uppercase sg-mb-35px"
                                                            style={{ zIndex: 999 }}
                                                        >
                                                            View Services
                                                        </a>
                                                        <a
                                                            id="seeShopsFA2"
                                                            className="btn btn-block text-white animated infinite fadeInDown"
                                                            data-fullpage-to={2}
                                                            style={{ zIndex: 998 }}
                                                        >
                                                            <span className="icon-slim-arrow-down icon-sm" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-gradient" />
                                        <div className="block-visuals">
                                            <figure className="block-cover-img">
                                                <div
                                                    data-bgset="./assets/images/DTB-Prestige-westgate-1.jpg"
                                                    className="bg-image lazyloaded"
                                                    data-alt="Entertain"
                                                    style={{
                                                        backgroundImage:
                                                            'url("./assets/images/DTB-Prestige-westgate-1.jpg")'
                                                    }}
                                                >
                                                    <picture style={{ display: "none" }}>
                                                        <source
                                                            data-srcset="./assets/images/DTB-Prestige-westgate-1.jpg"
                                                            sizes="1913px"
                                                            srcSet="./assets/images/DTB-Prestige-westgate-1.jpg"
                                                        />
                                                        <img
                                                            alt="Entertain"
                                                            data-parent-fit="cover"
                                                            className="lazyautosizes lazyloaded"
                                                            data-sizes="auto"
                                                            sizes="1913px"
                                                        />
                                                    </picture>
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                    <div
                                        className="block-padding-hero"
                                        data-xs={1}
                                        data-sm={1}
                                        data-md={1}
                                        data-lg={1}
                                    />
                                </div>
                                <div
                                    className="block-container vh100minusfa"
                                    id="block-burb-and-slider"
                                >
                                    <div className="block-abs ">
                                        <div className="block block-border-right col-md-12 col-sm-24 block-none">
                                            <div className="block-bottom sg-mb-20px">
                                                <div className="container-fluid md-reset-padding md-full-width">
                                                    <div className="block-bottom sg-mb-90px clear-margin-sm">
                                                        <div className="container-fluid"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="block-gradient" />
                                            <div className="block-visuals">
                                                <figure className="block-cover-img">
                                                    <div
                                                        data-bgset="./assets/images/DTB-Prestige-westgate.jpg"
                                                        className="bg-image lazyloaded"
                                                        data-alt="Entertain"
                                                        style={{
                                                            backgroundImage:
                                                                'url("./assets/images/DTB-Prestige-westgate.jpg")'
                                                        }}
                                                    >
                                                        <picture style={{ display: "none" }}>
                                                            <source
                                                                data-srcset="./assets/images/DTB-Prestige-westgate.jpg"
                                                                sizes="1913px"
                                                                srcSet="./assets/images/DTB-Prestige-westgate.jpg"
                                                            />
                                                            <img
                                                                alt="Entertain"
                                                                data-parent-fit="cover"
                                                                className="lazyautosizes lazyloaded"
                                                                data-sizes="auto"
                                                                sizes="1913px"
                                                            />
                                                        </picture>
                                                    </div>
                                                </figure>
                                            </div>
                                        </div>
                                        <div
                                            className="block block-border-left col-md-12 col-sm-24"
                                            id="block-carouser-fa-stores-relation"
                                        >
                                            <div className="block-wrap">
                                                <div className="block-bottom sg-mb-50px sg-mb-20px-xs">
                                                    <div className="container-fluid md-reset-padding md-full-width">
                                                        <div className="row">
                                                            <div className="col-xs-22 col-xs-offset-1 md-pull-in-line"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    id="carousel-example-generic-2"
                                                    className="carousel-fa carousel slide"
                                                    data-ride="carousel"
                                                    data-interval={5000}
                                                    style={{ textAlign: "center", position: "relative" }}
                                                >
                                                    <ol
                                                        className="carousel-indicators"
                                                        style={{
                                                            position: "absolute",
                                                            bottom: 20,
                                                            left: "50%",
                                                            color: "#000",
                                                            zIndex: 999
                                                        }}
                                                    >
                                                        <li
                                                            data-target="#carousel-example-generic-2"
                                                            data-slide-to={0}
                                                            className="active"
                                                        />
                                                        <li
                                                            data-target="#carousel-example-generic-2"
                                                            data-slide-to={1}
                                                            className=""
                                                        />
                                                        <li
                                                            data-target="#carousel-example-generic-2"
                                                            data-slide-to={2}
                                                            className=""
                                                        />
                                                        <li
                                                            data-target="#carousel-example-generic-2"
                                                            data-slide-to={3}
                                                            className=""
                                                        />
                                                    </ol>
                                                    <div
                                                        className="carousel-inner"
                                                        role="listbox"
                                                        style={{ position: "relative", zIndex: 998 }}
                                                    >
                                                        <div className="item active">
                                                            <div
                                                                style={{
                                                                    display: "table",
                                                                    height: "100%",
                                                                    width: "100%"
                                                                }}
                                                            >
                                                                <div
                                                                    className="item-fashion-avenue"
                                                                    style={{
                                                                        display: "table-cell",
                                                                        verticalAlign: "middle"
                                                                    }}
                                                                >
                                                                    <div className="col-sm-12 col-sm-offset-6  text-center ptop20 text-black">
                                                                        <p className="text-black sg-mb-25px hidden-xs hidden-sm fashion_avenue_black">
                                                                            Premium Banking
                                                                        </p>
                                                                        <p className="text-black sg-mb-25px visible-xs visible-sm fashion_avenue_white">
                                                                            Premium Banking
                                                                        </p>
                                                                        <h2 className="view-panel__heading text-black sg-mb-25px">
                                                                            Bank & Exchange in comfort
                                                                        </h2>
                                                                        <p className="text-black sg-mb-50px">
                                                                            Visit DTB, Stanbic, KCB, Absa, Westlands Forex Bureau & Safaricom
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div
                                                                style={{
                                                                    display: "table",
                                                                    height: "100%",
                                                                    width: "100%"
                                                                }}
                                                            >
                                                                <div
                                                                    className="item-fashion-avenue"
                                                                    style={{
                                                                        display: "table-cell",
                                                                        verticalAlign: "middle"
                                                                    }}
                                                                >
                                                                    <div className="col-sm-12 col-sm-offset-6  text-center ptop20 text-black">
                                                                        <p className="text-black sg-mb-25px hidden-xs hidden-sm fashion_avenue_black">
                                                                            Fashion Avenue
                                                                        </p>
                                                                        <p className="text-black sg-mb-25px visible-xs visible-sm fashion_avenue_white">
                                                                            Fashion Avenue
                                                                        </p>
                                                                        <h2 className="view-panel__heading text-black sg-mb-25px">
                                                                            ADMIRED BRANDS
                                                                        </h2>
                                                                        <p className="text-black sg-mb-50px">
                                                                            Rediscover the world’s most coveted brands,
                                                                            curated with the most discerning customer in
                                                                            mind. From flagship stores to exclusive
                                                                            concepts and products, Fashion Avenue is the
                                                                            new home of luxury.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div
                                                                style={{
                                                                    display: "table",
                                                                    height: "100%",
                                                                    width: "100%"
                                                                }}
                                                            >
                                                                <div
                                                                    className="item-fashion-avenue"
                                                                    style={{
                                                                        display: "table-cell",
                                                                        verticalAlign: "middle"
                                                                    }}
                                                                >
                                                                    <div className="col-sm-12 col-sm-offset-6  text-center ptop20 text-black">
                                                                        <p className="text-black sg-mb-25px hidden-xs hidden-sm fashion_avenue_black">
                                                                            Fashion Avenue
                                                                        </p>
                                                                        <p className="text-black sg-mb-25px visible-xs visible-sm fashion_avenue_white">
                                                                            Fashion Avenue
                                                                        </p>
                                                                        <h2 className="view-panel__heading text-black sg-mb-25px">
                                                                            5-STAR EXPERIENCE
                                                                        </h2>
                                                                        <p className="text-black sg-mb-50px">
                                                                            Step into personalised luxury from the moment
                                                                            you arrive. VIP valet, expert personal
                                                                            shoppers, in-mall chauffeur service, shoe
                                                                            shine and more, Fashion Avenue brings hotel
                                                                            style 5-star service to your shopping
                                                                            experience.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div
                                                                style={{
                                                                    display: "table",
                                                                    height: "100%",
                                                                    width: "100%"
                                                                }}
                                                            >
                                                                <div
                                                                    className="item-fashion-avenue"
                                                                    style={{
                                                                        display: "table-cell",
                                                                        verticalAlign: "middle"
                                                                    }}
                                                                >
                                                                    <div className="col-sm-12 col-sm-offset-6  text-center ptop20 text-black">
                                                                        <p className="text-black sg-mb-25px hidden-xs hidden-sm fashion_avenue_black">
                                                                            Fashion Avenue
                                                                        </p>
                                                                        <p className="text-black sg-mb-25px visible-xs visible-sm fashion_avenue_white">
                                                                            Fashion Avenue
                                                                        </p>
                                                                        <h2 className="view-panel__heading text-black sg-mb-25px">
                                                                            FINE FLAVOURS
                                                                        </h2>
                                                                        <p className="text-black sg-mb-50px">
                                                                            Be inspired by premium dining in an exquisite
                                                                            setting. With an expansive terrace and
                                                                            magnificent views of the Dubai Fountain,
                                                                            discover new dining concepts and fine flavours
                                                                            at Fashion Avenue.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <figure className="block-cover-img visible-xs visible-sm">
                                                        <div
                                                            data-bgset="https://staticassets.azureedge.net/assets/media/fashion-avenue/fashion-avenue-05-2.jpg"
                                                            className="bg-image lazyload"
                                                        />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="block-container vh100minusfa"
                                    id="block-burb-and-slider"
                                >
                                    <div className="block-abs ">
                                        <div
                                            className="block block-border-right col-md-12 col-sm-24"
                                            id="block-carouser-fa-stores-relation"
                                        >
                                            <div className="block-wrap">
                                                <div className="block-bottom sg-mb-50px sg-mb-20px-xs">
                                                    <div className="container-fluid md-reset-padding md-full-width">
                                                        <div className="row">
                                                            <div className="col-xs-22 col-xs-offset-1 md-pull-in-line"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    id="carousel-example-generic-2"
                                                    className="carousel-fa carousel slide"
                                                    data-ride="carousel"
                                                    data-interval={5000}
                                                    style={{ textAlign: "center", position: "relative" }}
                                                >
                                                    <div
                                                        className="carousel-inner"
                                                        role="listbox"
                                                        style={{ position: "relative", zIndex: 998 }}
                                                    >
                                                        <div className="item active">
                                                            <div
                                                                style={{
                                                                    display: "table",
                                                                    height: "100%",
                                                                    width: "100%"
                                                                }}
                                                            >
                                                                <div
                                                                    className="item-fashion-avenue"
                                                                    style={{
                                                                        display: "table-cell",
                                                                        verticalAlign: "middle"
                                                                    }}
                                                                >
                                                                    <div className="col-sm-12 col-sm-offset-6  text-center ptop20 text-black">
                                                                        <h2 className="view-panel__heading text-black sg-mb-25px">
                                                                            VIP Salons
                                                                        </h2>
                                                                        <p className="text-black sg-mb-50px">
                                                                            Visit Asleys, Lintos Beauty and other premium salons
                                                                        </p>
                                                                        {/* a href="/en/shop/valentino" class="btn btn-default-transparent btn-block-sm text-uppercase sg-mb-35px">
                                                          Services
                                                      </a */}
                                                                        <div
                                                                            className="visible-xs hidden-sm visible-md visible-lg"
                                                                            style={{}}
                                                                        >
                                                                            <a
                                                                                href="fashion-avenue/seamless-shopping.html"
                                                                                className="btn btn-primary-transparent btn-primary-transparent--diap-sm text-uppercase hidden-xs visible-md visible-lg"
                                                                                style={{ maxWidth: "70%", margin: "auto" }}
                                                                            >
                                                                            VIEW SALONS
                                                                            </a>
                                                                            <a
                                                                                href="fashion-avenue/seamless-shopping.html"
                                                                                className="btn btn-default-transparent btn-block-sm text-uppercase sg-mb-35px"
                                                                                style={{
                                                                                    color: "#FFF",
                                                                                    borderColor: "#FFF"
                                                                                }}
                                                                            >
                                                                                VIEW SALONS
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <figure className="block-cover-img visible-xs visible-sm">
                                                        <div
                                                            data-bgset="./assets/images/ashleys-westgate.jpg"
                                                            className="bg-image lazyloaded"
                                                            data-alt="Entertain"
                                                            style={{
                                                                backgroundImage:
                                                                    'url("./assets/images/ashleys-westgate.jpg")'
                                                            }}
                                                        >
                                                            <picture style={{ display: "none" }}>
                                                                <source
                                                                    data-srcset="./assets/images/ashleys-westgate.jpg"
                                                                    sizes="1913px"
                                                                    srcSet="./assets/images/ashleys-westgate.jpg"
                                                                />
                                                                <img
                                                                    alt="Entertain"
                                                                    data-parent-fit="cover"
                                                                    className="lazyautosizes lazyloaded"
                                                                    data-sizes="auto"
                                                                    sizes="1913px"
                                                                />
                                                            </picture>
                                                        </div>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block block-border-left col-md-12 col-sm-24 block-none">
                                            <div className="block-bottom sg-mb-20px">
                                                <div className="container-fluid md-reset-padding md-full-width">
                                                    <div className="block-bottom sg-mb-90px clear-margin-sm">
                                                        <div className="container-fluid"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="block-gradient" />
                                            <div className="block-visuals">
                                                <figure className="block-cover-img">
                                                    <div
                                                        data-bgset="./assets/images/ashleys-westgate.jpg"
                                                        className="bg-image lazyloaded"
                                                        data-alt="Entertain"
                                                        style={{
                                                            backgroundImage:
                                                                'url("./assets/images/ashleys-westgate.jpg")'
                                                        }}
                                                    >
                                                        <picture style={{ display: "none" }}>
                                                            <source
                                                                data-srcset="./assets/images/ashleys-westgate.jpg"
                                                                sizes="1913px"
                                                                srcSet="./assets/images/ashleys-westgate.jpg"
                                                            />
                                                            <img
                                                                alt="Entertain"
                                                                data-parent-fit="cover"
                                                                className="lazyautosizes lazyloaded"
                                                                data-sizes="auto"
                                                                sizes="1913px"
                                                            />
                                                        </picture>
                                                    </div>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div
                                    className="block-container  fp-section  full-height bg-white"
                                    id="fa-shoplist"
                                >
                                    <div className="block col-xs-24 bg-white">
                                        <div className="display-table text-center">
                                            <div className="display-table-cell col-middle">
                                                <div className="container">
                                                    <div className="row sg-mt-50px sg-mb-30px">
                                                        <div className="col-xs-24">
                                                            <h3 className="text-center bordered sg-mt-60px" id="forex">
                                                                <span id="70stores">
                                                                    BANKING & FOREX
                                                                </span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="full-width sg-mb-10px"
                                                        id="fa-shoplist-results"
                                                    >
                                                        <div className="col col-md-6 col-sm-8 col-xs-12 clear-padding">
                                                            <div className="block-logo-grid__item">
                                                                <a href="service/dtb" target="_self">
                                                                    <div className="display-table">
                                                                        <div className="display-table-cell col-middle text-center">
                                                                            <span
                                                                                className="tile-title"
                                                                                style={{
                                                                                    paddingLeft: 12,
                                                                                    paddingRight: 12,
                                                                                    display: "inline-block"
                                                                                }}
                                                                            >
                                                                                Diamond Trust Bank
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col col-md-6 col-sm-8 col-xs-12 clear-padding">
                                                            <div className="block-logo-grid__item">
                                                                <a
                                                                    href="service/stanbic-bank"
                                                                    target="_self"
                                                                >
                                                                    <div className="display-table">
                                                                        <div className="display-table-cell col-middle text-center">
                                                                            <span
                                                                                className="tile-title"
                                                                                style={{
                                                                                    paddingLeft: 12,
                                                                                    paddingRight: 12,
                                                                                    display: "inline-block"
                                                                                }}
                                                                            >
                                                                               Stanbic
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col col-md-6 col-sm-8 col-xs-12 clear-padding">
                                                            <div className="block-logo-grid__item">
                                                                <a href="service/kcb" target="_self">
                                                                    <div className="display-table">
                                                                        <div className="display-table-cell col-middle text-center">
                                                                            <span
                                                                                className="tile-title"
                                                                                style={{
                                                                                    paddingLeft: 12,
                                                                                    paddingRight: 12,
                                                                                    display: "inline-block"
                                                                                }}
                                                                            >
                                                                               Kenya Commercial Bank(KCB)
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col col-md-6 col-sm-8 col-xs-12 clear-padding">
                                                            <div className="block-logo-grid__item">
                                                                <a
                                                                    href="service/absa"
                                                                    target="_self"
                                                                >
                                                                    <div className="display-table">
                                                                        <div className="display-table-cell col-middle text-center">
                                                                            <span
                                                                                className="tile-title"
                                                                                style={{
                                                                                    paddingLeft: 12,
                                                                                    paddingRight: 12,
                                                                                    display: "inline-block"
                                                                                }}
                                                                            >
                                                                                Absa
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col col-md-6 col-sm-8 col-xs-12 clear-padding">
                                                            <div className="block-logo-grid__item">
                                                                <a
                                                                    href="/service/westlands-forex"
                                                                    target="_self"
                                                                >
                                                                    <div className="display-table">
                                                                        <div className="display-table-cell col-middle text-center">
                                                                            <span
                                                                                className="tile-title"
                                                                                style={{
                                                                                    paddingLeft: 12,
                                                                                    paddingRight: 12,
                                                                                    display: "inline-block"
                                                                                }}
                                                                            >
                                                                                Westlands Forex Bureau
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                      
                              
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Beauty & Salon*/}
                                    <div className="block col-xs-24 bg-white">
                                        <div className="display-table text-center">
                                            <div className="display-table-cell col-middle">
                                                <div className="container">
                                                    <div className="row sg-mt-50px sg-mb-30px">
                                                        <div className="col-xs-24">
                                                            <h3 className="text-center bordered sg-mt-60px">
                                                                <span id="70stores">
                                                                    BEAUTY & SALON
                                                                </span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="full-width sg-mb-10px"
                                                        id="fa-shoplist-results"
                                                    >
                                                        <div className="col col-md-6 col-sm-8 col-xs-12 clear-padding">
                                                            <div className="block-logo-grid__item">
                                                                <a href="store/ashleys-coiffure-spa" target="_self">
                                                                    <div className="display-table">
                                                                        <div className="display-table-cell col-middle text-center">
                                                                            <span
                                                                                className="tile-title"
                                                                                style={{
                                                                                    paddingLeft: 12,
                                                                                    paddingRight: 12,
                                                                                    display: "inline-block"
                                                                                }}
                                                                            >
                                                                                Ashleys
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col col-md-6 col-sm-8 col-xs-12 clear-padding">
                                                            <div className="block-logo-grid__item">
                                                                <a
                                                                    href="store/lintons-beauty"
                                                                    target="_self"
                                                                >
                                                                    <div className="display-table">
                                                                        <div className="display-table-cell col-middle text-center">
                                                                            <span
                                                                                className="tile-title"
                                                                                style={{
                                                                                    paddingLeft: 12,
                                                                                    paddingRight: 12,
                                                                                    display: "inline-block"
                                                                                }}
                                                                            >
                                                                                Lintons Beauty
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col col-md-6 col-sm-8 col-xs-12 clear-padding">
                                                            <div className="block-logo-grid__item">
                                                                <a href="store/yves-rocher" target="_self">
                                                                    <div className="display-table">
                                                                        <div className="display-table-cell col-middle text-center">
                                                                            <span
                                                                                className="tile-title"
                                                                                style={{
                                                                                    paddingLeft: 12,
                                                                                    paddingRight: 12,
                                                                                    display: "inline-block"
                                                                                }}
                                                                            >
                                                                                Yves Rocher
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    
                                                      
                              
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Beauty & Salon end */}
                                    {/* Others*/}
                                    <div className="block col-xs-24 bg-white">
                                        <div className="display-table text-center">
                                            <div className="display-table-cell col-middle">
                                                <div className="container">
                                                    <div className="row sg-mt-50px sg-mb-30px">
                                                        <div className="col-xs-24">
                                                            <h3 className="text-center bordered sg-mt-60px">
                                                                <span id="70stores">
                                                                   OTHER SERVICES
                                                                </span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="full-width sg-mb-10px"
                                                        id="fa-shoplist-results"
                                                    >
                                                        <div className="col col-md-6 col-sm-8 col-xs-12 clear-padding">
                                                            <div className="block-logo-grid__item">
                                                                <a href="service/safaricom" target="_self">
                                                                    <div className="display-table">
                                                                        <div className="display-table-cell col-middle text-center">
                                                                            <span
                                                                                className="tile-title"
                                                                                style={{
                                                                                    paddingLeft: 12,
                                                                                    paddingRight: 12,
                                                                                    display: "inline-block"
                                                                                }}
                                                                            >
                                                                                Safaricom
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col col-md-6 col-sm-8 col-xs-12 clear-padding">
                                                            <div className="block-logo-grid__item">
                                                                <a
                                                                    href="store/lorenzo-drycleaners"
                                                                    target="_self"
                                                                >
                                                                    <div className="display-table">
                                                                        <div className="display-table-cell col-middle text-center">
                                                                            <span
                                                                                className="tile-title"
                                                                                style={{
                                                                                    paddingLeft: 12,
                                                                                    paddingRight: 12,
                                                                                    display: "inline-block"
                                                                                }}
                                                                            >
                                                                                Lorenzo Dry Cleaners
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col col-md-6 col-sm-8 col-xs-12 clear-padding">
                                                            <div className="block-logo-grid__item">
                                                                <a href="store/bonfire-adventure" target="_self">
                                                                    <div className="display-table">
                                                                        <div className="display-table-cell col-middle text-center">
                                                                            <span
                                                                                className="tile-title"
                                                                                style={{
                                                                                    paddingLeft: 12,
                                                                                    paddingRight: 12,
                                                                                    display: "inline-block"
                                                                                }}
                                                                            >
                                                                                Bonfire Adventures
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="col col-md-6 col-sm-8 col-xs-12 clear-padding">
                                                            <div className="block-logo-grid__item">
                                                                <a href="service/aramex" target="_self">
                                                                    <div className="display-table">
                                                                        <div className="display-table-cell col-middle text-center">
                                                                            <span
                                                                                className="tile-title"
                                                                                style={{
                                                                                    paddingLeft: 12,
                                                                                    paddingRight: 12,
                                                                                    display: "inline-block"
                                                                                }}
                                                                            >
                                                                               Aramex
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            
                                                        </div>
                                                    
                                                      
                              
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Other services end */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="site-collapse-menu site-collapse-menu--main hidden"
                id="site-collapse-menu"
                style={{ marginTop: 50 }}
            >
                <nav>
                    <ul className="js--accordion" role="tablist">
                        <li className="site-collapse-menu-item">
                            <a href="whats-new.html">What's New</a>
                        </li>
                        <li className="site-collapse-menu-item">
                            <a href="shop.html">Shop</a>
                        </li>
                        <li className="site-collapse-menu-item">
                            <a href="dine.html">Dine</a>
                        </li>
                        <li className="site-collapse-menu-item">
                            <a href="stay.html">Stay</a>
                        </li>
                        <li className="site-collapse-menu-item">
                            <a href="entertain.html">Entertain</a>
                        </li>
                        <li className="site-collapse-menu-item">
                            <a className="site-collapse-menu__level-0" href="#mm-5">
                                Plan Your Visit
                            </a>
                            <ul className="collapse" id="mm-5">
                                <li className="site-collapse-menu-item">
                                    <a href="plan-your-visit/opening-hours.html">Opening hours</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="plan-your-visit/getting-here.html">Getting here</a>
                                </li>
                                <li className="site-collapse-menu-item">
                                    <a href="u-by-emaar.html">U By Emaar</a>
                                </li>
                                <li>
                                    <a href="plan-your-visit/parking.html">Parking</a>
                                </li>
                            </ul>
                        </li>
                        <li className="site-collapse-menu-item">
                            <a href="services.html">Services</a>
                        </li>
                    </ul>
                </nav>
                <nav className="inverse">
                    <ul>
                        <li className="site-collapse-menu-item">
                            <a href="fashion-avenue.html">Fashion avenue</a>
                        </li>
                        <li className="site-collapse-menu-item">
                            <a href="fountain-view.html">Fountain views</a>
                        </li>
                        <li className="site-collapse-menu-item">
                            <a href="campaign/chinatown.html">Chinatown</a>
                        </li>
                        <li className="site-collapse-menu-item">
                            <a href="campaign/souk-al-bahar.html">Souk Al Bahar</a>
                        </li>
                        <li className="site-collapse-menu-item">
                            <a href="offers.html">Offers</a>
                        </li>
                        <li className="site-collapse-menu-item">
                            <a href="events.html">Events</a>
                        </li>
                    </ul>
                </nav>
                <nav>
                    <ul className="js--accordion" role="tablist">
                        <li className="site-collapse-menu-item menu_lan" id="language-selecter">
                            <a href="../ar/fashion-avenue.html">العربية</a>
                        </li>
                        <li className="site-collapse-menu-item"></li>
                    </ul>
                </nav>
            </div>
            <div className="visible-xs sfooter-menu">
                <div className="site-collapse-menu" id="site-collapse-menu-footer">
                    <nav>
                        <ul>
                            <li className="site-collapse-menu-item">
                                <a href="whats-new.html">What's New</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="shop.html">Shop</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="dine-directory.html">Dine</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="stay.html">Stay</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="entertain.html">Entertain</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="plan-your-visit/opening-hours.html">Plan Your Visit</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="services.html">Services</a>
                            </li>
                        </ul>
                    </nav>
                    <nav className="inverse">
                        <ul>
                            <li className="site-collapse-menu-item">
                                <a href="fashion-avenue.html"> Fashion Avenue</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="offers.html">Offers</a>
                            </li>
                            <li className="site-collapse-menu-item">
                                <a href="events.html"> Events</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Services