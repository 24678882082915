import logo from './logo.svg'
import './App.css'
import { BrowserRouter,Routes, Route } from 'react-router-dom'
import Header from './components/header'
import Footer from './components/footer'
import Home from './pages/home'
import CinemaHomepage from './pages/cinema_main'
import MovieDetails from './pages/showtimes'
import Showtime from './pages/showtime'
import Privacy from './pages/privacy'
import Entertain from './pages/entertain'
import Campaigns from './pages/campaigns'
import Fashion from './pages/fashion'
import Dine from './pages/dine'
import Shop from './pages/shop'
import StoreDetails from './pages/store_details' //Store view  mastercopy
import About from './pages/about'
import Events from './pages/events'
import Faqs from './pages/faqs'
import Services from './pages/services'
import StoreInfo from './pages/store_test' //test dynamic mastercopy
import StoreData from './pages/storeData' //blend mastercopy
import Restaurant from './pages/restaurant'
import ServiceProvider from './pages/provider'
import Branding from './pages/branding'
import Blogs from './pages/blogs'
import NotFound from './pages/404'


function App() {
  return (
    <div>
    
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/events" element={<Events/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/movies" element={<CinemaHomepage/>} />
        <Route path="/movies/:movieId" element={<MovieDetails/>} />
        <Route path="/faqs" element={<Faqs/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/entertainment" element={<Entertain/>} />
        <Route path="/campaigns" element={<Campaigns/>} />
        <Route path="/stores/category/fashion" element={<Fashion/>} />
        <Route path="/stores/category/dine" element={<Dine/>} />
        <Route path="/stores/category/shop" element={<Shop/>} />
        <Route path="/store/:slug" element={<StoreData/>} />
        <Route path="/store-test" element={<StoreDetails/>} />
        <Route path="/restaurant/:slug" element={<Restaurant/>} />
        <Route path="/service/:slug" element={<ServiceProvider/>} />
        <Route path="/branding" element={<Branding/>} />
        <Route path="/blogs" element={<Blogs/>} />
        

        {/* 404 NOT FOUND */}
        <Route path="*" element ={<NotFound />} />
        {/* 404 NOT FOUND */}
       
        
    </Routes>
    </BrowserRouter>
  
    
    </div>
  )
}

export default App;
