/*
import React, { useState, useEffect } from 'react';
import './globals.css';
import './styleguide.css';
import './style.css';

const CinemaHomepage = () => {
    const [films, setFilms] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const fetchFilms = async () => {
            try {
                const response = await fetch('http://localhost/cinema/films.php'); // Assuming this is your PHP endpoint
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                if (data.value && Array.isArray(data.value)) {
                    setFilms(data.value);
                } else {
                    console.error('Unexpected response format:', data);
                }
            } catch (error) {
                console.error('Error fetching films:', error);
            }
        };

        fetchFilms();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % 3); // Assuming you have 3 slides
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    // Generate an SEO-friendly slug from a string
    const generateSlug = (string) => {
        return string.toLowerCase()
            .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
            .replace(/^-|-$/g, '');      // Trim leading and trailing hyphens
    };

    return (
        <div className="desktop">
            <div className="div">
                <div className={`frame ${currentSlide === 0 ? 'active' : ''}`}>
                    <div className="group">
                        <div className="frame-2">
                            <div className="a-quiet-place">A QUIET PLACE</div>
                            <p className="text-wrapper">
                                A woman named Sam finds herself trapped in New York City during the early stages of an invasion by alien
                                creatures with ultra-sensitive hearing.
                            </p>
                        </div>
                        <button className="button">
                            <div className="text-wrapper-2">BUY TICKETS</div>
                        </button>
                        <button className="button-2">
                            <img className="play" src="./assets/images/play.svg" alt="Play" />
                            <div className="text-wrapper-2">WATCH TRAILER</div>
                        </button>
                    </div>
                    <img className="img" src="./assets/images/brbooi6qum0cikvmzxuociwtgbg-1.png" alt="Movie" />
                </div>

                <div className={`frame ${currentSlide === 1 ? 'active' : ''}`}>
                    <div className="group">
                        <div className="frame-2">
                            <div className="a-quiet-place">A QUIET PLACE 2</div>
                            <p className="text-wrapper">
                                A woman named Sam finds herself trapped in New York City during the early stages of an invasion by alien
                                creatures with ultra-sensitive hearing.
                            </p>
                        </div>
                        <button className="button">
                            <div className="text-wrapper-2">BUY TICKETS</div>
                        </button>
                        <button className="button-2">
                            <img className="play" src="./assets/images/play.svg" alt="Play" />
                            <div className="text-wrapper-2">WATCH TRAILER</div>
                        </button>
                    </div>
                    <img className="img" src="./assets/images/brbooi6qum0cikvmzxuociwtgbg-1.png" alt="Movie" />
                </div>

                <div className={`frame ${currentSlide === 2 ? 'active' : ''}`}>
                    <div className="group">
                        <div className="frame-2">
                            <div className="a-quiet-place">A QUIET PLACE 3</div>
                            <p className="text-wrapper">
                                A woman named Sam finds herself trapped in New York City during the early stages of an invasion by alien
                                creatures with ultra-sensitive hearing.
                            </p>
                        </div>
                        <button className="button">
                            <div className="text-wrapper-2">BUY TICKETS</div>
                        </button>
                        <button className="button-2">
                            <img className="play" src="./assets/images/play.svg" alt="Play" />
                            <div className="text-wrapper-2">WATCH TRAILER</div>
                        </button>
                    </div>
                    <img className="img" src="./assets/images/brbooi6qum0cikvmzxuociwtgbg-1.png" alt="Movie" />
                </div>

                <div className="top-navigation">
                    <img className="cinema-logo" src="./assets/images/cinema-logo-3x-1.png" alt="Cinema Logo" />
                    <div className="wraper">
                        <button className="label-wrapper">
                            <div className="label">LOG IN</div>
                        </button>
                        <button className="div-wrapper">
                            <div className="label-2">SIGN IN</div>
                        </button>
                    </div>
                    <div className="menu">
                        <div className="home">HOME</div>
                        <div className="text-wrapper-3">UPCOMING MOVIES</div>
                        <div className="text-wrapper-3">CONTACT US</div>
                    </div>
                </div>

                <div className="frame-3">
                    <div className="rectangle"></div>
                    <div className="rectangle-2"></div>
                    <div className="rectangle-2"></div>
                    <div className="rectangle-2"></div>
                    <div className="rectangle-2"></div>
                </div>
                
                <div className="frame-4">
                    <div className="rectangle"></div>
                    <div className="rectangle-2"></div>
                    <div className="rectangle-2"></div>
                    <div className="rectangle-2"></div>
                </div>
                
                <div className="overlap">
                    <div className="frame-5">
                        <div className="group-2">
                            <div className="rectangle-3"></div>
                            <div className="rectangle-4"></div>
                            <div className="rectangle-5"></div>
                        </div>
                        <p className="text-wrapper-4">CLICK ON ANY MOVIE POSTER TO CHECK SHOWTIMES AND PLACE A BOOKING</p>
                    </div>
                    <div className="text-wrapper-5">🍿</div>
                </div>

                <div className="frame-7 films">
                    {films.map((film) => {
                        const filmSlug = generateSlug(film.Title);
                        return (
                            <a key={film.ScheduledFilmId} href={`/cinema/show-time/${filmSlug}`}>
                                <img className="rectangle-6" src={film.GraphicUrl} alt={film.Title} style={{ borderRadius: '25px' }} />
                            </a>
                        );
                    })}
                </div>

                <footer className="footer">
                    <div className="westgate-cinema-2">WESTGATE CINEMA 2024</div>
                    <div className="follow-us">FOLLOW US</div>
                    <div className="download-westgate">DOWNLOAD WESTGATE APP</div>
                    <img className="group-3" src="./assets/images/group-244.png" alt="App Store" />
                    <img className="group-4" src="./assets/images/group.png" alt="Google Play" />
                    <div className="group-wrapper">
                        <div className="group-5">
                            <div className="overlap-group"><img className="vector" src="./assets/images/vector.svg" alt="Social Icon" /></div>
                            <div className="vector-wrapper"><img className="vector-2" src="./assets/images/vector-1.svg" alt="Social Icon" /></div>
                            <div className="img-wrapper"><img className="vector-3" src="./assets/images/vector-2.svg" alt="Social Icon" /></div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default CinemaHomepage;
 */

import React, { useState, useEffect } from 'react';
import './globals.css';
import './styleguide.css';
import './style.css';

const CinemaHomepage = () => {
    const [films, setFilms] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const fetchFilms = async () => {
            try {
                const response = await fetch('http://localhost/cinema/films.php'); // Your PHP endpoint
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                if (data && Array.isArray(data.value)) {
                    setFilms(data.value);
                } else {
                    console.error('Unexpected response format:', data);
                }
            } catch (error) {
                console.error('Error fetching films:', error);
            }
        };

        fetchFilms();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % films.length);
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    }, [films.length]);

    const generateSlug = (string) => {
        return string.toLowerCase()
            .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
            .replace(/^-|-$/g, '');      // Trim leading and trailing hyphens
    };

    return (
        <div className="desktop">
            <div className="div">
                {films.map((film, index) => {
                    const filmSlug = generateSlug(film.Title);
                    return (
                        <div key={film.ScheduledFilmId} className={`frame ${currentSlide === index ? 'active' : ''}`}>
                            <div className="group">
                                <div className="frame-2">
                                    <div className="a-quiet-place">{film.Title}</div>
                                    <p className="text-wrapper">
                                        {film.Synopsis}
                                    </p>
                                </div>
                                <a href={`/movies/${filmSlug}`} className="button">
                                    <div className="text-wrapper-2">BUY TICKETS</div>
                                </a>
                                <a href={film.TrailerUrl} target="_blank" rel="noopener noreferrer" className="button-2">
                                    <img className="play" src="./assets/images/play.svg" alt="Play" />
                                    <div className="text-wrapper-2">WATCH TRAILER</div>
                                </a>
                            </div>
                            <img className="img" src={film.GraphicUrl} alt={film.Title} />
                        </div>
                    );
                })}

                <div className="top-navigation">
                    <img className="cinema-logo" src="./assets/images/cinema-logo-3x-1.png" alt="Cinema Logo" />
                    <div className="wraper">
                        <button className="label-wrapper">
                            <div className="label">LOG IN</div>
                        </button>
                        <button className="div-wrapper">
                            <div className="label-2">SIGN IN</div>
                        </button>
                    </div>
                    <div className="menu">
                        <div className="home">HOME</div>
                        <div className="text-wrapper-3">UPCOMING MOVIES</div>
                        <div className="text-wrapper-3">CONTACT US</div>
                    </div>
                </div>

                <div className="frame-3">
                    <div className="rectangle"></div>
                    <div className="rectangle-2"></div>
                    <div className="rectangle-2"></div>
                    <div className="rectangle-2"></div>
                    <div className="rectangle-2"></div>
                </div>

                <div className="frame-4">
                    <div className="rectangle"></div>
                    <div className="rectangle-2"></div>
                    <div className="rectangle-2"></div>
                    <div className="rectangle-2"></div>
                </div>

                <div className="overlap">
                    <div className="frame-5">
                        <div className="group-2">
                            <div className="rectangle-3"></div>
                            <div className="rectangle-4"></div>
                            <div className="rectangle-5"></div>
                        </div>
                        <p className="text-wrapper-4">CLICK ON ANY MOVIE POSTER TO CHECK SHOWTIMES AND PLACE A BOOKING</p>
                    </div>
                    <div className="text-wrapper-5">🍿</div>
                </div>

                <div className="frame-7 films">
                    {films.map((film) => {
                        const filmSlug = generateSlug(film.Title);
                        return (
                            <a key={film.ScheduledFilmId} href={`/movies/${filmSlug}`}>
                                <img className="rectangle-6" src={film.GraphicUrl} alt={film.Title} style={{ borderRadius: '25px' }} />
                            </a>
                        );
                    })}
                </div>

                <footer className="footer">
                    <div className="westgate-cinema-2">WESTGATE CINEMA 2024</div>
                    <div className="follow-us">FOLLOW US</div>
                    <div className="download-westgate">DOWNLOAD WESTGATE APP</div>
                    <img className="group-3" src="./assets/images/group-244.png" alt="App Store" />
                    <img className="group-4" src="./assets/images/group.png" alt="Google Play" />
                    <div className="group-wrapper">
                        <div className="group-5">
                            <div className="overlap-group"><img className="vector" src="./assets/images/vector.svg" alt="Social Icon" /></div>
                            <div className="vector-wrapper"><img className="vector-2" src="./assets/images/vector-1.svg" alt="Social Icon" /></div>
                            <div className="img-wrapper"><img className="vector-3" src="./assets/images/vector-2.svg" alt="Social Icon" /></div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default CinemaHomepage;
